import {Injectable} from '@angular/core';

@Injectable()
export class AppConstants {
    static markingSchemes = [
        {'name': 'Percentage', 'value': 'PERCENTAGE'},
        {'name': 'CGPA', 'value': 'CGPA'}
    ];

    static boardUniversity = [
        {'name': 'CBSE', 'value': 'CBSE'},
        {'name': 'ICSE', 'value': 'ICSE'},
        {'name': 'IB', 'value': 'IB'},
        {'name': 'State Board', 'value': 'STATE_BOARD'}
    ];

    static statuses = [
        {
            'name': 'Pending',
            'value': 'PENDING'
        },
        {
            'name': 'Form Received',
            'value': 'FORM_RECEIVED'
        },
        {
            'name': 'Documents Required',
            'value': 'DOCUMENTS_REQUIRED'
        },
        {
            'name': 'Documents Uploaded',
            'value': 'DOCUMENTS_UPLOADED'
        },
        {
            'name': 'Documents Re Uploaded',
            'value': 'DOCUMENTS_RE_UPLOADED'
        },
        {
            'name': 'Sent',
            'value': 'SENT'
        }
    ];

    static MS_HEAD_CENTER_ID = 254;

    static GYAN_VIHAR_UNINVERSITY_ID = 3;
}
