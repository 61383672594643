import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StudentsRoutingModule} from './students-routing.module';
import {StudentsComponent} from './students.component';
import {AddStudentComponent} from './add-student/add-student.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbDropdownModule, NgbNavModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {DetailStudentComponent} from './detail-student/detail-student.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {SharedModule} from '../../shared/shared.module';
import { ReRegisterStudentsComponent } from './re-register-students/re-register-students.component';

@NgModule({
    declarations: [StudentsComponent, AddStudentComponent, DetailStudentComponent, ReRegisterStudentsComponent],
    imports: [
        CommonModule,
        StudentsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        NgbNavModule,
        NgbDropdownModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        SharedModule
    ],
    exports: [StudentsComponent, AddStudentComponent, DetailStudentComponent, ReRegisterStudentsComponent]
})

export class StudentsModule {
}
