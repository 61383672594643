import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileService} from '../../shared/services/file.service';
import {AuthService} from '../../shared/services/auth.service';
import {AlertService} from '../../shared/services/alert.service';
import {Location} from '@angular/common';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {DataService} from '../../shared/services/data.service';
import {StorageService} from '../../shared/services/storage.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {StateService} from '../../shared/services/state.service';
import {AdmissionFormService} from '../../shared/services/admission-form.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StudentService} from '../../shared/services/student.service';
import {StageService} from '../../shared/services/stage.service';
import {UniversitySettingService} from '../../shared/services/university-setting.service';
import {CourseService} from '../../shared/services/course.service';
import {StudentPersonalInfoService} from '../../shared/services/student-personal-info.service';
import {environment} from '../../../environments/environment';
import {ProgramService} from '../../shared/services/program.service';
import {Helper} from '../../shared/constants/helper';
import {PcbMappingService} from '../../shared/services/pcb-mapping.service';

@Component({
    selector: 'app-admission-form',
    templateUrl: './admission-form.component.html',
    styleUrls: ['./admission-form.component.scss']
})
export class AdmissionFormComponent implements OnInit {
    studentAdmissionForm: FormGroup;
    updatePasswordForm: FormGroup;
    updateCourseForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    croppedImage: any;
    croppedImageEvent: any;
    imageChangedEvent: any;
    user: any;
    photographImage: any;
    signatureImage: any;
    photographImageUploadArea: any = 'assets/images/image-backgrounds/200-200.png';
    signatureImageUploadArea: any = 'assets/images/image-backgrounds/300-100.png';
    data: any;
    id: any;
    states: any = [];
    studentDetailData: any;
    stages: any = [];
    studentSessionYears: any = [];
    courses: any = [];
    imageChangedProfileEvent: any;
    filePhotograph: any;
    fileSignature: any;
    disableAll: false;
    onFileChanged: any;
    fileTenthCertificate: any;
    fileTwelthCertificate: any;
    fileGraduateCertificate: any;
    filePostGraduateCertificate: any;
    fileOtherCertificate: any;
    fileGovtPhotoIdProof: any;
    admissionFormDetail: any;
    father_name: any;
    mother_name: any;
    studentNameHindi: any;
    // fatherNameHindi: any;
    // motherNameHindi: any;
    mappingYears: any = [];
    uploadPhotographModal: any = false;
    uploadSignatureModal: any = false;
    updateCourseModal: any = false;
    programs = [];
    studentPrograms = [];
    branches = [];
    studentCourses = [];
    studentBranches = [];

    constructor(public modalService: NgbModal,
                public fileService: FileService,
                private fb: FormBuilder,
                private authService: AuthService,
                private alertService: AlertService,
                private _location: Location,
                private _coreSidebarService: CoreSidebarService,
                private dataService: DataService,
                private stateService: StateService,
                private admissionFormService: AdmissionFormService,
                private activatedRoute: ActivatedRoute,
                private studentService: StudentService,
                private router: Router,
                private stageService: StageService,
                private universitySettingService: UniversitySettingService,
                private studentPersonalInfoService: StudentPersonalInfoService,
                private courseService: CourseService,
                private programService: ProgramService,
                private pcbMappingService: PcbMappingService) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.id = this.activatedRoute.snapshot.params['id'];
        this.refresh();
        this.getStates();
        this.getStages();
    }

    refresh() {
        this.studentAdmissionForm = this.fb.group({
            'application_number': [{'value': '', disabled: true}],
            'admission_type': [{'value': '', disabled: !(this.user && this.user['user_type'] === 'BUSINESS_ADMIN')}],
            'admission_session': [{'value': '', disabled: true}],
            'course_id': [{'value': '', disabled: true}],
            'email': [{'value': '', disabled: !(this.user && this.user['user_type'] === 'BUSINESS_ADMIN')}],
            'year': ['', Validators.required],
            'student_name': ['', Validators.required],
            // 'student_name_hindi': ['', Validators.required],
            'father_name': ['', Validators.required],
            // 'father_name_hindi': ['', Validators.required],
            'mother_name': ['', Validators.required],
            // 'mother_name_hindi': ['', Validators.required],
            'gender': ['', Validators.required],
            'date_of_birth': ['', Validators.required],
            'address': ['', Validators.required],
            'state_id': ['', Validators.required],
            'city': ['', Validators.required],
            'pin_code': ['', Validators.required],
            'contact': [{'value': '', disabled: !(this.user && this.user['user_type'] === 'BUSINESS_ADMIN')}],
            'govt_photo_id': ['', Validators.required],
            'nationality': [{'value': '', disabled: true}],
            'religion': ['', Validators.required],
            'category': ['', Validators.required],
            'employment_status': ['', Validators.required],
            'tenth_examination': [{'value': '', disabled: true}],
            'tenth_subject': [''],
            'tenth_passing_year': [''],
            'tenth_board': [''],
            'tenth_grade': [''],
            'twelth_examination': [{'value': '', disabled: true}],
            'twelth_subject': [''],
            'twelth_passing_year': [''],
            'twelth_board': [''],
            'twelth_grade': [''],
            'graduate_examination': [{'value': '', disabled: true}],
            'graduate_subject': [''],
            'graduate_passing_year': [''],
            'graduate_board': [''],
            'graduate_grade': [''],
            'post_graduate_examination': [{'value': '', disabled: true}],
            'post_graduate_subject': [''],
            'post_graduate_passing_year': [''],
            'post_graduate_board': [''],
            'post_graduate_grade': [''],
            'others_examination': [{'value': '', disabled: true}],
            'others_subject': [''],
            'others_passing_year': [''],
            'others_board': [''],
            'others_grade': [''],
        });
        this.updateCourseForm = this.fb.group({
            'program_id': ['', Validators.required],
            'course_id': ['', Validators.required],
            'branch_id': [''],
        });
        this.getStudentDetail();
    }

    getStudentDetail() {
        this.studentService.detailByCounsellor(this.id).subscribe(data => {
            if (data) {
                this.studentDetailData = data;
                if (!this.id || !this.studentDetailData) {
                    this.router.navigateByUrl('dashboard');
                } else {
                    //this.getPrograms();
                    this.getStudentSessionData(this.studentDetailData['student_detail']['university_id']);
                }
            }
        }, error => {
            this.router.navigateByUrl('students');
        });
    }

    patchDefault() {
        if (this.studentDetailData && this.studentDetailData['student_detail'] && this.studentDetailData['student_detail']['pcb_mapping_data'] && this.studentDetailData['student_detail']['pcb_mapping_data']['course_id']) {
            if (!this.studentDetailData['student_detail']['pcb_mapping_data']['branch_id']) {
                this.courses = [{
                    'id': this.studentDetailData['student_detail']['pcb_mapping_data']['course_id'],
                    'name': this.studentDetailData['student_detail']['pcb_mapping_data']['course']['name']
                }];
            } else {
                this.courses = [{
                    'id': this.studentDetailData['student_detail']['pcb_mapping_data']['course_id'],
                    'name': this.studentDetailData['student_detail']['pcb_mapping_data']['course']['name'] + ' - ' + this.studentDetailData['student_detail']['pcb_mapping_data']['branch']['name']
                }];
            }
        }
        let duration = (this.studentDetailData && this.studentDetailData['student_detail'] && this.studentDetailData['student_detail']['pcb_mapping'] && this.studentDetailData['student_detail']['pcb_mapping']['duration']) ? this.studentDetailData['student_detail']['pcb_mapping']['duration'] : '';
        this.mappingYears = [];
        if (duration) {
            let mappingYears = [];
            for (let i = 1; i <= parseInt(duration); i++) {
                let year = '';
                if (i === 1) {
                    year = '1st Year';
                } else if (i === 2) {
                    year = '2nd Year';
                } else if (i === 3) {
                    year = '3rd Year';
                } else {
                    year = i + 'th Year';
                }
                mappingYears.push({
                    'id': i,
                    'name': year
                });
            }
            this.mappingYears = mappingYears;
        }
        let stageId = (this.studentDetailData && this.studentDetailData['stage_id']) ? this.studentDetailData['stage_id'] : '';
        let yearStagesArr = [
            {
                'stage_id': 5,
                'year': 1
            },
            {
                'stage_id': 11,
                'year': 2
            },
            {
                'stage_id': 12,
                'year': 3
            },
            {
                'stage_id': 10,
                'year': 2
            },
        ];
        let defaultYear = 1;
        if (yearStagesArr && yearStagesArr.length > 0) {
            yearStagesArr.forEach(year => {
                if (year['stage_id'] === stageId) {
                    defaultYear = year['year'];
                }
            });
        }
        this.studentAdmissionForm.patchValue({
            'application_number': (this.studentDetailData && this.studentDetailData['username']) ? this.studentDetailData['username'] : '',
            'admission_type': (this.studentDetailData && this.studentDetailData['student_detail'] && this.studentDetailData['student_detail']['stage_id']) ? this.studentDetailData['student_detail']['stage_id'] : '',
            'admission_session': (this.studentDetailData && this.studentDetailData['student_detail'] && this.studentDetailData['student_detail']['session_id']) ? this.studentDetailData['student_detail']['session_id'] : '',
            'course_id': (this.studentDetailData && this.studentDetailData['student_detail'] && this.studentDetailData['student_detail']['pcb_mapping_data'] && this.studentDetailData['student_detail']['pcb_mapping_data']['course_id']) ? this.studentDetailData['student_detail']['pcb_mapping_data']['course_id'] : '',
            'year': defaultYear,
            'student_name': (this.studentDetailData && this.studentDetailData['name']) ? this.studentDetailData['name'] : '',
            'email': (this.studentDetailData && this.studentDetailData['email']) ? this.studentDetailData['email'] : '',
            'contact': (this.studentDetailData && this.studentDetailData['contact']) ? this.studentDetailData['contact'] : '',
            'govt_photo_id': (this.studentDetailData && this.studentDetailData['govt_photo_id']) ? this.studentDetailData['govt_photo_id'] : '',
            'nationality': 'INDIAN',
            'tenth_examination': '10th',
            'twelth_examination': '12th',
            'graduate_examination': 'UG',
            'post_graduate_examination': 'PG',
            'others_examination': 'Others',
        });

        this.getStudentPrograms();
        this.patchForm();
    }

    patchForm() {
        this.admissionFormService.detail(this.id).subscribe(data => {
            if (data) {
                this.admissionFormDetail = data;
                if ((this.admissionFormDetail['added_by'] === this.user['userId']) || (this.admissionFormDetail['added_by_business_head_center'] === this.user['userId']) || (this.admissionFormDetail['added_by_business_admin'] === this.user['userId'])) {
                    if (data && data['photograph']) {
                        this.photographImage = data['photograph'].url;
                    }
                    if (data && data['signature']) {
                        this.signatureImage = data['signature'].url;
                    }
                    if (data && data['tenth_certificate']) {
                        this.fileTenthCertificate = {'url': data['tenth_certificate'].url};
                    }
                    if (data && data['twelth_certificate']) {
                        this.fileTwelthCertificate = {'url': data['twelth_certificate'].url};
                    }
                    if (data && data['graduate_certificate']) {
                        this.fileGraduateCertificate = {'url': data['graduate_certificate'].url};
                    }
                    if (data && data['post_graduate_certificate']) {
                        this.filePostGraduateCertificate = {'url': data['post_graduate_certificate'].url};
                    }
                    if (data && data['others_certificate']) {
                        this.fileOtherCertificate = {'url': data['others_certificate'].url};
                    }
                    if (data && data['govt_photo_id_proof']) {
                        this.fileGovtPhotoIdProof = {'url': data['govt_photo_id_proof'].url};
                    }
                    // this.fatherNameHindi = (data && data['father_name_hindi']) ? data['father_name_hindi'] : '';
                    // this.motherNameHindi = (data && data['mother_name_hindi']) ? data['mother_name_hindi'] : '';
                    this.studentAdmissionForm.patchValue({
                        'year': (data && data['year']) ? data['year'] : '',
                        // 'student_name_hindi': (data && data['student_name_hindi']) ? data['student_name_hindi'] : '',
                        'father_name': (data && data['father_name']) ? data['father_name'] : '',
                        // 'father_name_hindi': (data && data['father_name_hindi']) ? data['father_name_hindi'] : '',
                        'mother_name': (data && data['mother_name']) ? data['mother_name'] : '',
                        // 'mother_name_hindi': (data && data['mother_name_hindi']) ? data['mother_name_hindi'] : '',
                        'gender': (data && data['gender']) ? data['gender'] : '',
                        'date_of_birth': (data && data['date_of_birth']) ? data['date_of_birth'] : '',
                        'address': (data && data['address']) ? data['address'] : '',
                        'state_id': (data && data['state_id']) ? data['state_id'] : '',
                        'city': (data && data['city']) ? data['city'] : '',
                        'pin_code': (data && data['pin_code']) ? data['pin_code'] : '',
                        'govt_photo_id': (data && data['govt_photo_id']) ? data['govt_photo_id'] : '',
                        'religion': (data && data['religion']) ? data['religion'] : '',
                        'category': (data && data['category']) ? data['category'] : '',
                        'employment_status': (data && data['employment_status']) ? data['employment_status'] : '',
                        'tenth_subject': (data && data['tenth_subject']) ? data['tenth_subject'] : '',
                        'tenth_passing_year': (data && data['tenth_passing_year']) ? data['tenth_passing_year'] : '',
                        'tenth_board': (data && data['tenth_board']) ? data['tenth_board'] : '',
                        'tenth_grade': (data && data['tenth_grade']) ? data['tenth_grade'] : '',
                        'twelth_subject': (data && data['twelth_subject']) ? data['twelth_subject'] : '',
                        'twelth_passing_year': (data && data['twelth_passing_year']) ? data['twelth_passing_year'] : '',
                        'twelth_board': (data && data['twelth_board']) ? data['twelth_board'] : '',
                        'twelth_grade': (data && data['twelth_grade']) ? data['twelth_grade'] : '',
                        'graduate_subject': (data && data['graduate_subject']) ? data['graduate_subject'] : '',
                        'graduate_passing_year': (data && data['graduate_passing_year']) ? data['graduate_passing_year'] : '',
                        'graduate_board': (data && data['graduate_board']) ? data['graduate_board'] : '',
                        'graduate_grade': (data && data['graduate_grade']) ? data['graduate_grade'] : '',
                        'post_graduate_subject': (data && data['post_graduate_subject']) ? data['post_graduate_subject'] : '',
                        'post_graduate_passing_year': (data && data['post_graduate_passing_year']) ? data['post_graduate_passing_year'] : '',
                        'post_graduate_board': (data && data['post_graduate_board']) ? data['post_graduate_board'] : '',
                        'post_graduate_grade': (data && data['post_graduate_grade']) ? data['post_graduate_grade'] : '',
                        'others_subject': (data && data['others_subject']) ? data['others_subject'] : '',
                        'others_passing_year': (data && data['others_passing_year']) ? data['others_passing_year'] : '',
                        'others_board': (data && data['others_board']) ? data['others_board'] : '',
                        'others_grade': (data && data['others_grade']) ? data['others_grade'] : '',
                    });
                    /*if (this.studentDetailData && this.studentDetailData['name'] && (!data || !data['student_name_hindi'])) {
                        this.getHindiName('STUDENT_ONINIT', this.studentDetailData['name']);
                    }*/
                } else {
                    this.router.navigateByUrl('students');
                }
            }
        });
    }

    getStudentPrograms() {
        let universityId = (this.studentDetailData && this.studentDetailData['student_detail'] && this.studentDetailData['student_detail']['university_id']) ? this.studentDetailData['student_detail']['university_id'] : '';
        this.programs = [];
        this.programService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentPrograms = data['data'];
            } else {
                this.studentPrograms = [];
            }
        });
    }

    getStudenProgramCourses(event) {
        this.studentCourses = [];
        this.studentBranches = [];
        this.pcbMappingService.getByLanding(event.target.value, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentCourses = Helper.uniqByKeepLast(data['data'], it => it.course_id);
            }
        });
    }

    getStudentCourseBranch(event) {
        if (event) {
            this.studentBranches = [];
            this.pcbMappingService.getBranchByLanding(event.target.value, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    const studentBranches = [];
                    data['data'].forEach(dt => {
                        if (dt && dt.branch_id) {
                            studentBranches.push(dt);
                        }
                    });
                    this.studentBranches = Helper.uniqByKeepLast(studentBranches, it => it.branch_id);
                }
            });
        } else {
            this.alertService.showErrors('Something went wrong, please select course again');
        }
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }

    getStages() {
        this.stageService.get({'page': -1}).subscribe(data => {
            this.stages = [];
            if (data && data['data'].length > 0) {
                let stages = [];
                data['data'].forEach(stage => {
                    if (parseInt(stage.id) === 5) {
                        stages.push(stage);
                    } else if (parseInt(stage.id) === 10) {
                        stages.push(stage);
                    } else if (parseInt(stage.id) === 11) {
                        stages.push(stage);
                    } else if (parseInt(stage.id) === 12) {
                        stages.push(stage);
                    }
                });
                this.stages = stages;
            }
        });
    }

    getStudentSessionData(universityId) {
        this.universitySettingService.getByAdmin(universityId, {}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentSessionYears = data['data'];
                //this.getCourses(universityId);
                this.patchDefault();
            }
        });
    }

    /*getCourses(universityId) {
        this.courses = [];
        this.courseService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.courses = data['data'];
                this.patchDefault();
            }
        });
    }*/

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.croppedImageEvent = event;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    onImageChange(e) {
        this.imageChangedEvent = e;
    }

    openUploadModel(content) {
        this.updateCourseModal = false;
        this.uploadSignatureModal = false;
        this.uploadPhotographModal = true;
        this.modalService.open(content);
    }

    openSignatureUploadModel(content) {
        this.uploadPhotographModal = false;
        this.updateCourseModal = false;
        this.uploadSignatureModal = true;
        this.modalService.open(content);
    }

    detail() {
        this.authService.self(parseInt(this.user['userId'])).subscribe(data => {
            if (data) {
                this.studentAdmissionForm.patchValue({
                    'profilename': (data && data.name) ? data.name : '',
                    'username': (data && data.username) ? data.username : '',
                    'email': (data && data.email) ? data.email : '',
                    'contact': (data && data.contact) ? data.contact : ''
                });
            }
        }, error => {
            this.back();
        });
    }

    back() {
        this._location.back();
    }

    // getHindiName(type, event) {
    //     if (type === 'FATHER' || type === 'MOTHER' || type === 'STUDENT') {
    //         if (event && event.target && event.target.value) {
    //             this.translateToHindi(type, event.target.value);
    //         } else {
    //             if (type === 'FATHER') {
    //                 this.studentAdmissionForm.patchValue({
    //                     'father_name_hindi': ''
    //                 });
    //             } else if (type === 'MOTHER') {
    //                 this.studentAdmissionForm.patchValue({
    //                     'mother_name_hindi': ''
    //                 });
    //             }/* else if (type === 'STUDENT') {
    //                 this.studentAdmissionForm.patchValue({
    //                     'student_name_hindi': ''
    //                 });
    //             }*/
    //         }
    //     } else if (type === 'STUDENT_ONINIT') {
    //         if (event) {
    //             this.translateToHindi(type, event);
    //         }/* else {
    //             this.studentAdmissionForm.patchValue({
    //                 'student_name_hindi': ''
    //             });
    //         }*/
    //     }
    // }

    /*translateToHindi(type, name) {
        this.admissionFormService.translate({'name': name}).subscribe(data => {
            if (type === 'FATHER') {
                this.studentAdmissionForm.patchValue({
                    'father_name_hindi': (data && data['name']) ? data['name'] : ''
                });
            } else if (type === 'MOTHER') {
                this.studentAdmissionForm.patchValue({
                    'mother_name_hindi': (data && data['name']) ? data['name'] : ''
                });
            } else if (type === 'STUDENT') {
                this.studentAdmissionForm.patchValue({
                    'student_name_hindi': (data && data['name']) ? data['name'] : ''
                });
            } else if (type === 'STUDENT_ONINIT') {
                this.studentAdmissionForm.patchValue({
                    'student_name_hindi': (data && data['name']) ? data['name'] : ''
                });
            }
        }, error => {
            this.alertService.showErrors('Error in translation, please try again');
        });
    }*/

    submit() {
        this.submitted = true;
        if (!this.studentAdmissionForm.valid) {
            this.alertService.showErrors('Error(s) in form');
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            return;
        }
        if (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().date_of_birth) {
            let dob = this.studentAdmissionForm.getRawValue().date_of_birth;
            let yearOfBirth = dob.split('-')[0];
            if (parseInt(yearOfBirth) > 2007 || parseInt(yearOfBirth) < 1960) {
                this.alertService.showErrors('Invalid Date Of Birth');
                return;
            }
        }
        if (!this.photographImage) {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.alertService.showErrors('Photograph can\'t be blank');
            return;
        }
        if (this.submitted) {
            const params = {
                'student_id': this.id,
                'application_number': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().application_number) ? this.studentAdmissionForm.getRawValue().application_number : '',
                'admission_type': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().admission_type) ? this.studentAdmissionForm.getRawValue().admission_type : '',
                'admission_session': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().admission_session) ? this.studentAdmissionForm.getRawValue().admission_session : '',
                'course_id': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().course_id) ? this.studentAdmissionForm.getRawValue().course_id : '',
                'year': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().year) ? this.studentAdmissionForm.getRawValue().year : '',
                'student_name': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().student_name) ? this.studentAdmissionForm.getRawValue().student_name : '',
                'student_name_hindi': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().student_name_hindi) ? this.studentAdmissionForm.getRawValue().student_name_hindi : '',
                'father_name': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().father_name) ? this.studentAdmissionForm.getRawValue().father_name : '',
                // 'father_name_hindi': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().father_name_hindi) ? this.studentAdmissionForm.getRawValue().father_name_hindi : '',
                'mother_name': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().mother_name) ? this.studentAdmissionForm.getRawValue().mother_name : '',
                // 'mother_name_hindi': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().mother_name_hindi) ? this.studentAdmissionForm.getRawValue().mother_name_hindi : '',
                'gender': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().gender) ? this.studentAdmissionForm.getRawValue().gender : '',
                'date_of_birth': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().date_of_birth) ? this.studentAdmissionForm.getRawValue().date_of_birth : '',
                'address': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().address) ? this.studentAdmissionForm.getRawValue().address : '',
                'state_id': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().state_id) ? this.studentAdmissionForm.getRawValue().state_id : '',
                'city': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().city) ? this.studentAdmissionForm.getRawValue().city : '',
                'pin_code': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().pin_code) ? this.studentAdmissionForm.getRawValue().pin_code : '',
                'contact': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().contact) ? this.studentAdmissionForm.getRawValue().contact : '',
                'govt_photo_id': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().govt_photo_id) ? this.studentAdmissionForm.getRawValue().govt_photo_id : '',
                'email': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().email) ? this.studentAdmissionForm.getRawValue().email : '',
                'nationality': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().nationality) ? this.studentAdmissionForm.getRawValue().nationality : '',
                'religion': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().religion) ? this.studentAdmissionForm.getRawValue().religion : '',
                'category': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().category) ? this.studentAdmissionForm.getRawValue().category : '',
                'employment_status': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().employment_status) ? this.studentAdmissionForm.getRawValue().employment_status : '',
                'tenth_examination': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().tenth_examination) ? this.studentAdmissionForm.getRawValue().tenth_examination : '',
                'tenth_subject': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().tenth_subject) ? this.studentAdmissionForm.getRawValue().tenth_subject : '',
                'tenth_passing_year': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().tenth_passing_year) ? this.studentAdmissionForm.getRawValue().tenth_passing_year : '',
                'tenth_board': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().tenth_board) ? this.studentAdmissionForm.getRawValue().tenth_board : '',
                'tenth_grade': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().tenth_grade) ? this.studentAdmissionForm.getRawValue().tenth_grade : '',
                'twelth_examination': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().twelth_examination) ? this.studentAdmissionForm.getRawValue().twelth_examination : '',
                'twelth_subject': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().twelth_subject) ? this.studentAdmissionForm.getRawValue().twelth_subject : '',
                'twelth_passing_year': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().twelth_passing_year) ? this.studentAdmissionForm.getRawValue().twelth_passing_year : '',
                'twelth_board': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().twelth_board) ? this.studentAdmissionForm.getRawValue().twelth_board : '',
                'twelth_grade': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().twelth_grade) ? this.studentAdmissionForm.getRawValue().twelth_grade : '',
                'graduate_examination': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().graduate_examination) ? this.studentAdmissionForm.getRawValue().graduate_examination : '',
                'graduate_subject': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().graduate_subject) ? this.studentAdmissionForm.getRawValue().graduate_subject : '',
                'graduate_passing_year': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().graduate_passing_year) ? this.studentAdmissionForm.getRawValue().graduate_passing_year : '',
                'graduate_board': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().graduate_board) ? this.studentAdmissionForm.getRawValue().graduate_board : '',
                'graduate_grade': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().graduate_grade) ? this.studentAdmissionForm.getRawValue().graduate_grade : '',
                'post_graduate_examination': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().post_graduate_examination) ? this.studentAdmissionForm.getRawValue().post_graduate_examination : '',
                'post_graduate_subject': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().post_graduate_subject) ? this.studentAdmissionForm.getRawValue().post_graduate_subject : '',
                'post_graduate_passing_year': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().post_graduate_passing_year) ? this.studentAdmissionForm.getRawValue().post_graduate_passing_year : '',
                'post_graduate_board': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().post_graduate_board) ? this.studentAdmissionForm.getRawValue().post_graduate_board : '',
                'post_graduate_grade': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().post_graduate_grade) ? this.studentAdmissionForm.getRawValue().post_graduate_grade : '',
                'others_examination': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().others_examination) ? this.studentAdmissionForm.getRawValue().others_examination : '',
                'others_subject': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().others_subject) ? this.studentAdmissionForm.getRawValue().others_subject : '',
                'others_passing_year': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().others_passing_year) ? this.studentAdmissionForm.getRawValue().others_passing_year : '',
                'others_board': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().others_board) ? this.studentAdmissionForm.getRawValue().others_board : '',
                'others_grade': (this.studentAdmissionForm.getRawValue() && this.studentAdmissionForm.getRawValue().others_grade) ? this.studentAdmissionForm.getRawValue().others_grade : '',
            };
            this.submitting = true;
            this.admissionFormService.create(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.router.navigateByUrl('students');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].application_number && error['error']['errors'].application_number.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].application_number[0]);
                        return;
                    }
                    if (error['error']['errors'].admission_type && error['error']['errors'].admission_type.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].admission_type[0]);
                        return;
                    }
                    if (error['error']['errors'].admission_session && error['error']['errors'].admission_session.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].admission_session[0]);
                        return;
                    }
                    if (error['error']['errors'].course_id && error['error']['errors'].course_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].course_id[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].year && error['error']['errors'].year.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].year[0]);
                        return;
                    }
                    if (error['error']['errors'].student_name && error['error']['errors'].student_name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].student_name[0]);
                        return;
                    }
                    if (error['error']['errors'].father_name && error['error']['errors'].father_name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].father_name[0]);
                        return;
                    }
                    if (error['error']['errors'].mother_name && error['error']['errors'].mother_name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].mother_name[0]);
                        return;
                    }
                    if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].gender[0]);
                        return;
                    }
                    if (error['error']['errors'].date_of_birth && error['error']['errors'].date_of_birth.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].date_of_birth[0]);
                        return;
                    }
                    if (error['error']['errors'].address && error['error']['errors'].address.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].address[0]);
                        return;
                    }
                    if (error['error']['errors'].state_id && error['error']['errors'].state_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].state_id[0]);
                        return;
                    }
                    if (error['error']['errors'].city && error['error']['errors'].city.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].city[0]);
                        return;
                    }
                    if (error['error']['errors'].pin_code && error['error']['errors'].pin_code.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].pin_code[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].govt_photo_id && error['error']['errors'].govt_photo_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].govt_photo_id[0]);
                        return;
                    }
                    if (error['error']['errors'].nationality && error['error']['errors'].nationality.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].nationality[0]);
                        return;
                    }
                    if (error['error']['errors'].religion && error['error']['errors'].religion.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].religion[0]);
                        return;
                    }
                    if (error['error']['errors'].category && error['error']['errors'].category.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].category[0]);
                        return;
                    }
                    if (error['error']['errors'].employment_status && error['error']['errors'].employment_status.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].employment_status[0]);
                        return;
                    }
                    if (error['error']['errors'].tenth_examination && error['error']['errors'].tenth_examination.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].tenth_examination[0]);
                        return;
                    }
                    if (error['error']['errors'].tenth_subject && error['error']['errors'].tenth_subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].tenth_subject[0]);
                        return;
                    }
                    if (error['error']['errors'].tenth_passing_year && error['error']['errors'].tenth_passing_year.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].tenth_passing_year[0]);
                        return;
                    }
                    if (error['error']['errors'].tenth_board && error['error']['errors'].tenth_board.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].tenth_board[0]);
                        return;
                    }
                    if (error['error']['errors'].tenth_grade && error['error']['errors'].tenth_grade.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].tenth_grade[0]);
                        return;
                    }
                    if (error['error']['errors'].twelth_examination && error['error']['errors'].twelth_examination.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].twelth_examination[0]);
                        return;
                    }
                    if (error['error']['errors'].twelth_subject && error['error']['errors'].twelth_subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].twelth_subject[0]);
                        return;
                    }
                    if (error['error']['errors'].twelth_passing_year && error['error']['errors'].twelth_passing_year.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].twelth_passing_year[0]);
                        return;
                    }
                    if (error['error']['errors'].twelth_board && error['error']['errors'].twelth_board.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].twelth_board[0]);
                        return;
                    }
                    if (error['error']['errors'].twelth_grade && error['error']['errors'].twelth_grade.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].twelth_grade[0]);
                        return;
                    }
                    if (error['error']['errors'].graduate_examination && error['error']['errors'].graduate_examination.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].graduate_examination[0]);
                        return;
                    }
                    if (error['error']['errors'].graduate_subject && error['error']['errors'].graduate_subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].graduate_subject[0]);
                        return;
                    }
                    if (error['error']['errors'].graduate_passing_year && error['error']['errors'].graduate_passing_year.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].graduate_passing_year[0]);
                        return;
                    }
                    if (error['error']['errors'].graduate_board && error['error']['errors'].graduate_board.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].graduate_board[0]);
                        return;
                    }
                    if (error['error']['errors'].graduate_grade && error['error']['errors'].graduate_grade.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].graduate_grade[0]);
                        return;
                    }
                    if (error['error']['errors'].post_graduate_examination && error['error']['errors'].post_graduate_examination.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].post_graduate_examination[0]);
                        return;
                    }
                    if (error['error']['errors'].post_graduate_subject && error['error']['errors'].post_graduate_subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].post_graduate_subject[0]);
                        return;
                    }
                    if (error['error']['errors'].post_graduate_passing_year && error['error']['errors'].post_graduate_passing_year.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].post_graduate_passing_year[0]);
                        return;
                    }
                    if (error['error']['errors'].post_graduate_board && error['error']['errors'].post_graduate_board.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].post_graduate_board[0]);
                        return;
                    }
                    if (error['error']['errors'].post_graduate_grade && error['error']['errors'].post_graduate_grade.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].post_graduate_grade[0]);
                        return;
                    }
                    if (error['error']['errors'].others_examination && error['error']['errors'].others_examination.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].others_examination[0]);
                        return;
                    }
                    if (error['error']['errors'].others_subject && error['error']['errors'].others_subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].others_subject[0]);
                        return;
                    }
                    if (error['error']['errors'].others_passing_year && error['error']['errors'].others_passing_year.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].others_passing_year[0]);
                        return;
                    }
                    if (error['error']['errors'].others_board && error['error']['errors'].others_board.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].others_board[0]);
                        return;
                    }
                    if (error['error']['errors'].others_grade && error['error']['errors'].others_grade.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].others_grade[0]);
                        return;
                    }
                }
            });
        }
    }

    savePhotograph() {
        const params = {
            'type': 'PROFILE_IMAGE',
            'user_id': this.id,
            'fileType': 'BASE64'
        };
        this.studentPersonalInfoService.fileUpload(params, this.croppedImage).subscribe(data => {
            if (data) {
                this.filePhotograph = {'url': data['data'].url};
                this.photographImage = data['data'].url;
                this.imageChangedProfileEvent = undefined;
                this.updatePhotograph(data['data'].id);
            }
            this.uploadPhotographModal = false;
        }, error => {
            this.uploadPhotographModal = false;
        });
    }

    saveSignature() {
        const params = {
            'type': 'STUDENT_SIGNATURE',
            'user_id': this.id,
            'fileType': 'BASE64'
        };
        this.studentPersonalInfoService.fileUpload(params, this.croppedImage).subscribe(data => {
            if (data) {
                this.fileSignature = {'url': data['data'].url};
                this.signatureImage = data['data'].url;
                this.imageChangedProfileEvent = undefined;
                this.updateSignature(data['data'].id);
            }
            this.uploadSignatureModal = false;
        }, error => {
            this.uploadSignatureModal = false;
        });
    }

    updatePhotograph(id) {
        this.admissionFormService.updatePhotograph(this.id, {'photograph': id}).subscribe(data => {
            this.submitting = false;
            this.submitted = false;
            this.uploadPhotographModal = false;
        }, error => {
            this.uploadPhotographModal = false;
        });
        this.modalService.dismissAll('');
        this.alertService.showSuccess({'title': 'success', 'message': 'Image successfully loaded'});
    }

    updateSignature(id) {
        this.admissionFormService.updateSignature(this.id, {'signature': id}).subscribe(data => {
            this.submitting = false;
            this.submitted = false;
            this.uploadSignatureModal = false;
        }, error => {
            this.uploadSignatureModal = false;
        });
        this.modalService.dismissAll('');
        this.alertService.showSuccess({'title': 'success', 'message': 'Image successfully loaded'});
    }

    onFileChange(e, type) {
        this.onFileChanged = e;
        this.studentPersonalInfoService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'TENTH_CERTIFICATE') {
                this.fileTenthCertificate = data['data'];
            } else if (type === 'TWELTH_CERTIFICATE') {
                this.fileTwelthCertificate = data['data'];
            } else if (type === 'GRADUATE_CERTIFICATE') {
                this.fileGraduateCertificate = data['data'];
            } else if (type === 'POST_GRADUATE_CERTIFICATE') {
                this.filePostGraduateCertificate = data['data'];
            } else if (type === 'OTHER_CERTIFICATE') {
                this.fileOtherCertificate = data['data'];
            } else if (type === 'GOVT_PHOTO_ID_PROOF') {
                this.fileGovtPhotoIdProof = data['data'];
            }
            this.updateCertificate(type, data['data']);
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    updateCertificate(type, data) {
        if (type === 'TENTH_CERTIFICATE') {
            this.admissionFormService.updateTenthCertificate(this.id, {'tenth_certificate': data.id}).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
            });
        } else if (type === 'TWELTH_CERTIFICATE') {
            this.admissionFormService.updateTwelthCertificate(this.id, {'twelth_certificate': data.id}).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
            });
        } else if (type === 'GRADUATE_CERTIFICATE') {
            this.admissionFormService.updateGraduateCertificate(this.id, {'graduate_certificate': data.id}).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
            });
        } else if (type === 'POST_GRADUATE_CERTIFICATE') {
            this.admissionFormService.updatePostGraduateCertificate(this.id, {'post_graduate_certificate': data.id}).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
            });
        } else if (type === 'OTHER_CERTIFICATE') {
            this.admissionFormService.updateOtherCertificate(this.id, {'others_certificate': data.id}).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
            });
        } else if (type === 'GOVT_PHOTO_ID_PROOF') {
            this.admissionFormService.updateGovtPhotoId(this.id, {'govt_photo_id_proof': data.id}).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    openUrl(url) {
        window.open(url, '_blank');
    }

    downloadForm() {
        let url = environment.appUrl + 'sgvu-admission-form/' + this.id;
        window.open(url, '_blank');
    }

    changeStudentYear(event) {
        if (event && event.target && event.target.value) {
            let stageId = event.target.value;
            let yearStagesArr = [
                {
                    'stage_id': 5,
                    'year': 1
                },
                {
                    'stage_id': 11,
                    'year': 2
                },
                {
                    'stage_id': 12,
                    'year': 3
                },
                {
                    'stage_id': 10,
                    'year': 2
                },
            ];
            let defaultYear = 1;
            if (yearStagesArr && yearStagesArr.length > 0) {
                yearStagesArr.forEach(year => {
                    if (year['stage_id'] === stageId) {
                        defaultYear = year['year'];
                    }
                });
            }
            this.studentAdmissionForm.patchValue({
                'year': defaultYear
            });
        }
    }

    editCourse(content) {
        this.uploadPhotographModal = false;
        this.updateCourseModal = true;
        this.modalService.open(content);
    }

    updateCourse() {
        let params = {
            'program_id': (this.updateCourseForm.value && this.updateCourseForm.value['program_id']) ? this.updateCourseForm.value['program_id'] : '',
            'course_id': (this.updateCourseForm.value && this.updateCourseForm.value['course_id']) ? this.updateCourseForm.value['course_id'] : '',
            'branch_id': (this.updateCourseForm.value && this.updateCourseForm.value['branch_id']) ? this.updateCourseForm.value['branch_id'] : '',
        };
        this.admissionFormService.updatePCBMapping(this.id, params).subscribe(data => {
            this.submitting = false;
            this.submitted = false;
            this.updateCourseModal = false;
            this.modalService.dismissAll('');
            this.updateCourseForm.patchValue({
                'program_id': '',
                'course_id': '',
                'branch_id': ''
            });
            window.location.reload();
        });
    }
}
