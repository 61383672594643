import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '../../shared/services/storage.service';
import {BusinessCenterService} from '../../shared/services/business-center.service';
import {AppConstants} from '../../shared/constants/app-constant';
import {StageService} from '../../shared/services/stage.service';
import {StudentService} from '../../shared/services/student.service';
import {SrMappingService} from '../../shared/services/sr-mapping.service';
import {StudentStageStatusService} from '../../shared/services/student-stage-status.service';
import {CourseService} from '../../shared/services/course.service';
import {CounsellorService} from '../../shared/services/counsellor.service';
import {AlertService} from '../../shared/services/alert.service';
import {AdminService} from '../../shared/services/admin.service';
import {HeadCounsellorService} from '../../shared/services/head-counsellor.service';
import {UniversityService} from '../../shared/services/university.service';
import {ProgramService} from '../../shared/services/program.service';
import {BranchService} from '../../shared/services/branch.service';
import {PcbMappingService} from '../../shared/services/pcb-mapping.service';
import {CourseCurriculumService} from '../../shared/services/course-curriculum.service';
import {UniversitySettingService} from '../../shared/services/university-setting.service';
import {BusinessHeadCenterService} from '../../shared/services/business-head-center.service';
import {BusinessAdminService} from '../../shared/services/business-admin.service';
import {Helper} from '../../shared/constants/helper';

@Component({
    selector: 'app-business-center-share',
    templateUrl: './business-center-share.component.html',
    styleUrls: ['./business-center-share.component.scss']
})
export class BusinessCenterShareComponent implements OnInit {

    id: any;
    tabs: any = [];
    addQuickLeadForm: FormGroup;
    reRegisterForm: FormGroup;
    studentSessionForm: FormGroup;
    stages: any = [];
    statuses: any = AppConstants.statuses;
    reasons: any = [];
    courses = [];
    admins: any = [];
    counsellors: any = [];
    searchForm: FormGroup;
    @ViewChild('navFilled') navFilled;
    active: any;
    studentSessionYears: any = [];
    businessCenterDetail: any;
    businessHeadCenterDetail: any;
    businessAdminDetail: any;
    selectedSession: any;
    pcbmapping: any = [];
    programs = [];
    studentPrograms = [];
    branches = [];
    showReRegistration: any = false;
    reRegistrationSession: any = [];
    showSearch = false;
    businessCentersShare = [];
    universities = [];
    data: any;
    updateBusinessCenterShareForm: FormGroup;
    assignUniversityForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    user: any;
    businessHeadCenterShareData: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    settings: any = [];

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private businessCenterService: BusinessCenterService,
                private stageService: StageService,
                private studentService: StudentService,
                private srMappingService: SrMappingService,
                public studentStageStatusService: StudentStageStatusService,
                public courseService: CourseService,
                public counsellorService: CounsellorService,
                public alertService: AlertService,
                private adminService: AdminService,
                private headCounsellorService: HeadCounsellorService,
                private universityService: UniversityService,
                private programService: ProgramService,
                private branchService: BranchService,
                private pcbMappingService: PcbMappingService,
                private courseCurriculumService: CourseCurriculumService,
                private universitySettingService: UniversitySettingService,
                private businessHeadCenterService: BusinessHeadCenterService,
                private businessAdminService: BusinessAdminService) {
        this.getStages();
    }

    ngOnInit(): void {
        this.user = StorageService.getItem('self');
        this.refresh();
    }

    refresh() {
        this.updateBusinessCenterShareForm = this.fb.group({
            'admission_percentage_actual': [''],
            're_registration_percentage_actual': [''],
            'admission_percentage_display': [''],
            're_registration_percentage_display': [''],
            'prospectus_fee': [''],
            'service_charge': [''],
            'examination_fee': ['']
        });
        this.assignUniversityForm = this.fb.group({
            'university_id': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'code': ['']
        });
        this.studentSessionForm = this.fb.group({
            'student_session_year': ['']
        });
        this.addQuickLeadForm = this.fb.group({
            'session_id': ['', Validators.required],
            'stage_id': ['', Validators.required],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'program_id': ['', Validators.required],
            'course_id': ['', Validators.required],
            'branch_id': [''],
            'student_year': ['']
        });
        this.reRegisterForm = this.fb.group({
            'student_year': ['', Validators.required],
            'session_id': ['', Validators.required]
        });
        this.studentSessionForm = this.fb.group({
            'university_id': [''],
            'student_session_year': ['']
        });
        this.getBusinessAdminDetail();
    }

    getBusinessAdminDetail() {
        this.businessAdminService.detailById(this.user['userId']).subscribe(data => {
            this.businessAdminDetail = data;
            this.getSettings();
        });
    }

    getSettings() {
        this.universitySettingService.getByAdmin(this.businessAdminDetail['university_id'], {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.settings = data['data'];
                this.patchSettingForm();
            } else {
                this.settings = [];
            }
        });
    }

    patchSettingForm() {
        if (this.settings && this.settings.length > 0) {
            let selectedSetting: any;
            this.settings.forEach(setting => {
                if (setting && setting['session_default'] && setting['session_default'] === 'ACTIVE') {
                    selectedSetting = setting;
                }
            });
            this.studentSessionForm.patchValue({
                'student_session_year': selectedSetting.id
            });
            this.get();
        }
    }

    getStages() {
        this.stages = [];
        this.stageService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.stages = data['data'];
            }
        });
    }

    getStudentSession() {
        if (this.businessCenterDetail && this.businessCenterDetail['business_admin_detail'] && this.businessCenterDetail['business_admin_detail'].university_id) {
            this.getStudentSessionData(this.businessCenterDetail['business_admin_detail'].university_id);
        } else if (this.businessHeadCenterDetail && this.businessHeadCenterDetail['business_admin_detail'] && this.businessHeadCenterDetail['business_admin_detail'].university_id) {
            this.getStudentSessionData(this.businessHeadCenterDetail['business_admin_detail'].university_id);
        } else if (this.businessAdminDetail && this.businessAdminDetail['university_id']) {
            this.getStudentSessionData(this.businessAdminDetail['university_id']);
        }
    }

    getStudentSessionData(universityId) {
        this.universitySettingService.getByAdmin(universityId, {}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentSessionYears = data['data'];
                if (this.studentSessionYears && this.studentSessionYears.length > 0) {
                    let sessionYear = {};
                    this.studentSessionYears.forEach(session => {
                        if (session && session['session_default'] === 'SELECTED') {
                            sessionYear = session;
                        }
                    });
                    this.patchFormSession(universityId, sessionYear);
                }
            }
        });
    }

    getUniversityCourses(universityId) {
        if (universityId) {
            this.courseService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.courses = data['data'];
                } else {
                    this.courses = [];
                }
            });
        } else {
            this.alertService.showErrors('Please choose university');
        }
    }

    getProgram(universityId) {
        this.programs = [];
        this.programService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.programs = data['data'];
            } else {
                this.programs = [];
            }
        });
    }

    getStudentPrograms(universityId) {
        this.programs = [];
        this.programService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentPrograms = data['data'];
            } else {
                this.studentPrograms = [];
            }
        });
    }

    reRegistrationStatus() {
        if (this.studentSessionYears && this.studentSessionYears.length > 0) {
            this.showReRegistration = !(this.studentSessionYears && this.studentSessionYears[0] && this.studentSessionYears[0]['session_default'] && this.studentSessionYears[0]['session_default'] === 'ACTIVE');
            if (this.showReRegistration) {
                let reRegistrationSession = [];
                this.studentSessionYears.forEach(session => {
                    if (session && session['re_registration_status'] === 'ACTIVE') {
                        reRegistrationSession.push(session);
                    }
                });
                this.reRegistrationSession = reRegistrationSession;
            }
        }
    }

    patchFormSession(universityId, data) {
        this.get(data.id);
        this.selectedSession = data;
        this.studentSessionForm.patchValue({
            'student_session_year': (data && data.id) ? data.id : ''
        });
        this.addQuickLeadForm.patchValue({
            'session_id': (data && data.id) ? data.id : ''
        });

        this.getUniversityCourses(universityId);
        this.getProgram(universityId);
        this.getStudentPrograms(universityId);
        this.reRegistrationStatus();
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        params.setting_id = (this.studentSessionForm && this.studentSessionForm.value) ? this.studentSessionForm.value.student_session_year : '';
        this.submitting = true;
        this.businessCentersShare = [];
        this.businessCenterService.getSharePercentage(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.businessCentersShare = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.businessCentersShare = [];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'code': (this.searchForm.value && this.searchForm.value.code) ? this.searchForm.value.code : ''
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'code': (this.searchForm && this.searchForm.value && this.searchForm.value.code) ? this.searchForm.value.code : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
    }

    updatePercentageModal(content, data) {
        this.businessHeadCenterShareData = data;
        this.updateBusinessCenterShareForm.patchValue({
            'admission_percentage_actual': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.admission_percentage_actual) ? this.businessHeadCenterShareData.admission_percentage_actual : '',
            're_registration_percentage_actual': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.re_registration_percentage_actual) ? this.businessHeadCenterShareData.re_registration_percentage_actual : '',
            'admission_percentage_display': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.admission_percentage_display) ? this.businessHeadCenterShareData.admission_percentage_display : '',
            're_registration_percentage_display': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.re_registration_percentage_display) ? this.businessHeadCenterShareData.re_registration_percentage_display : '',
            'prospectus_fee': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.prospectus_fee) ? this.businessHeadCenterShareData.prospectus_fee : '',
            'service_charge': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.service_charge) ? this.businessHeadCenterShareData.service_charge : '',
            'examination_fee': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.examination_fee) ? this.businessHeadCenterShareData.examination_fee : '',
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    create() {
        this.submitted = true;
        if (!this.updateBusinessCenterShareForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'center_id': (this.businessHeadCenterShareData && this.businessHeadCenterShareData['business_center']) ? this.businessHeadCenterShareData['business_center']['user_id'] : '',
                'admission_percentage_actual': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.admission_percentage_actual) ? this.updateBusinessCenterShareForm.value.admission_percentage_actual : '',
                're_registration_percentage_actual': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.re_registration_percentage_actual) ? this.updateBusinessCenterShareForm.value.re_registration_percentage_actual : '',
                'admission_percentage_display': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.admission_percentage_display) ? this.updateBusinessCenterShareForm.value.admission_percentage_display : '',
                're_registration_percentage_display': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.re_registration_percentage_display) ? this.updateBusinessCenterShareForm.value.re_registration_percentage_display : '',
                'prospectus_fee': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.prospectus_fee) ? this.updateBusinessCenterShareForm.value.prospectus_fee : '',
                'service_charge': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.service_charge) ? this.updateBusinessCenterShareForm.value.service_charge : '',
                'examination_fee': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.examination_fee) ? this.updateBusinessCenterShareForm.value.examination_fee : '',
                'setting_id': (this.businessCentersShare && this.businessCentersShare['university_setting'] && this.businessCentersShare['university_setting'].id) ? this.businessCentersShare['university_setting'].id : '',
            };
            this.submitting = true;
            this.businessCenterService.createSharePercentage(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.updateBusinessCenterShareForm.patchValue({
                    'admission_percentage_actual': '',
                    're_registration_percentage_actual': '',
                    'admission_percentage_display': '',
                    're_registration_percentage_display': '',
                    'prospectus_fee': '',
                    'service_charge': '',
                    'examination_fee': '',
                });
                this.modalService.dismissAll('');
                this.get({page: 1, total: null, perpage: 15, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.updateBusinessCenterShareForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'center_id': (this.businessHeadCenterShareData && this.businessHeadCenterShareData['business_center']) ? this.businessHeadCenterShareData['business_center']['user_id'] : '',
                'admission_percentage_actual': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.admission_percentage_actual) ? this.updateBusinessCenterShareForm.value.admission_percentage_actual : '',
                're_registration_percentage_actual': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.re_registration_percentage_actual) ? this.updateBusinessCenterShareForm.value.re_registration_percentage_actual : '',
                'admission_percentage_display': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.admission_percentage_display) ? this.updateBusinessCenterShareForm.value.admission_percentage_display : '',
                're_registration_percentage_display': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.re_registration_percentage_display) ? this.updateBusinessCenterShareForm.value.re_registration_percentage_display : '',
                'prospectus_fee': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.prospectus_fee !== '') ? this.updateBusinessCenterShareForm.value.prospectus_fee : '',
                'service_charge': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.service_charge !== '') ? this.updateBusinessCenterShareForm.value.service_charge : '',
                'examination_fee': (this.updateBusinessCenterShareForm.value && this.updateBusinessCenterShareForm.value.examination_fee !== '') ? this.updateBusinessCenterShareForm.value.examination_fee : ''
            };
            this.submitting = true;
            this.businessCenterService.updateSharePercentage(this.businessHeadCenterShareData['university_setting']['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.updateBusinessCenterShareForm.patchValue({
                    'admission_percentage_actual': '',
                    're_registration_percentage_actual': '',
                    'admission_percentage_display': '',
                    're_registration_percentage_display': '',
                    'prospectus_fee': '',
                    'service_charge': '',
                    'examination_fee': ''
                });
                this.modalService.dismissAll('');
                this.get({page: 1, total: null, perpage: 15, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    changeSettings(event) {
        if (event && event.target && event.target.value && this.settings && this.settings.length > 0) {
            let selectedSetting = {};
            this.studentSessionYears.forEach(session => {
                if (parseInt(event.target.value) === session.id) {
                    selectedSetting = session;
                }
            });
            this.get();
        }
    }

    downloadExcel() {
        this.businessCenterService.downloadExcel({}).subscribe(data => {
            if (data && data['url']) {
                window.open(data['url'], '_blank');
            }
        });
    }
}
