<div class="content-wrapper">
    <ng-template #content>
        <div id="modal">
            <div class="modal-header font-weight-bold">
                <h4 class="modal-title font-weight-bold text-capitalize" *ngIf="selectedStudentData">Update Status
                    - {{selectedStudentData?.name.toLowerCase()}}</h4>
                <h4 class="modal-title font-weight-bold text-capitalize" *ngIf="selectedStudentDocumentErrorData">
                    Document Error
                    - {{selectedStudentDocumentErrorData?.name.toLowerCase()}}</h4>
                <h4 class="modal-title font-weight-bold text-capitalize" *ngIf="selectedProIdData">Update
                    Sid | Password
                    - {{selectedProIdData?.name.toLowerCase()}}
                </h4>
                <h4 class="modal-title font-weight-bold text-capitalize" *ngIf="selectedEnrollmentData">Update
                    Enrollment
                    - {{selectedEnrollmentData?.name.toLowerCase()}}
                </h4>
                <h4 class="modal-title font-weight-bold text-capitalize" *ngIf="selectedRollNoData">Update
                    Roll No.
                    - {{selectedRollNoData?.name.toLowerCase()}}
                </h4>
                <h4 class="modal-title font-weight-bold" *ngIf="quickLeadAdd">Add Student</h4>
                <h4 class="modal-title font-weight-bold" *ngIf="bulkUploadAdd">Student Bulk Upload</h4>
                <h4 class="modal-title font-weight-bold" *ngIf="bulkUploadProId">Student Bulk Sid | Password Upload</h4>
                <h4 class="modal-title font-weight-bold text-capitalize" *ngIf="reRegisterStudent">Re - Register
                    : {{reRegisterStudentSelect?.name.toLowerCase()}}</h4>
                <h4 class="modal-title font-weight-bold" *ngIf="assignStudentData">
                    Assign Student to
                    <span *ngIf="user && user['user_type'] === 'SUPER_ADMIN'"> Admin</span>
                    <span *ngIf="user && user['user_type'] === 'ADMIN'"> Head Counsellor</span>
                    <span *ngIf="user && user['user_type'] === 'HEAD_COUNSELLOR'"> Counsellor</span>
                </h4>
                <h4 class="modal-title font-weight-bold text-capitalize" *ngIf="transactionData">Transaction Details
                    - {{transactionData?.name.toLowerCase()}}</h4>
                <h4 class="modal-title font-weight-bold text-capitalize" *ngIf="openDeleteModal">Delete Student</h4>
                <n4 class="modal-title font-weight-bold text-capitalize"
                    *ngIf="openStudentPhotographDownloadModal || openStudentPhotographDownloadModalProgress || downloadZipUrl">
                    Download
                    Student Photographs
                </n4>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="modalService.dismissAll('')"
                >
                    ×
                </button>
            </div>
            <div class="modal-body">
                <div class="row p-2" *ngIf="openDeleteModal">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        Are you sure, you really want to delete Student - {{selectedstudentDeleteData?.name}} ?
                    </div>
                </div>
                <div class="row p-1" *ngIf="selectedStudentDocumentErrorData">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h6 *ngIf="selectedStudentDocumentErrorData && selectedStudentDocumentErrorData['govt_photo_id_proof_error'] && selectedStudentDocumentErrorData['govt_photo_id_proof_error'] === 'TRUE'">
                            <i class="fas fa-arrow-alt-circle-right mr-8px"></i>Please re-upload Govt Photo Id Proof
                            document (only in png, jpg, jpeg or pdf format).
                        </h6>
                        <h6 *ngIf="selectedStudentDocumentErrorData && selectedStudentDocumentErrorData['tenth_certificate_error'] && selectedStudentDocumentErrorData['tenth_certificate_error'] === 'TRUE'">
                            <i class="fas fa-arrow-alt-circle-right mr-8px"></i>Please re-upload 10th document (only in
                            png, jpg, jpeg or pdf format).
                        </h6>
                        <h6 *ngIf="selectedStudentDocumentErrorData && selectedStudentDocumentErrorData['twelth_certificate_error'] && selectedStudentDocumentErrorData['twelth_certificate_error'] === 'TRUE'">
                            <i class="fas fa-arrow-alt-circle-right mr-8px"></i>Please re-upload 12th document (only in
                            png, jpg, jpeg or pdf format).
                        </h6>
                        <h6 *ngIf="selectedStudentDocumentErrorData && selectedStudentDocumentErrorData['graduate_certificate_error'] && selectedStudentDocumentErrorData['graduate_certificate_error'] === 'TRUE'">
                            <i class="fas fa-arrow-alt-circle-right mr-8px"></i>Please re-upload UG document (only in
                            png, jpg, jpeg or pdf format).
                        </h6>
                    </div>
                </div>
                <div class="row" *ngIf="selectedStudentData">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form class="form mt-2 shadow-none" novalidate [formGroup]="updateStatusForm">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="status">Status</label>
                                            <select class="form-control" id="status" formControlName="status"
                                                    (change)="checkForRemark($event.target.value)">
                                                <option value="">Select Status</option>
                                                <option [value]="status.value"
                                                        *ngFor="let status of statuses">{{status?.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && updateStatusForm.get('status').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="remarkExists">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="remarks">Remark</label>
                                            <textarea class="form-control resize-none" [rows]="5" id="remarks"
                                                      formControlName="remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row" *ngIf="selectedProIdData">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form class="form mt-2 shadow-none" novalidate [formGroup]="updateProIdForm">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="pro_id">Sid | Password</label>
                                            <input id="pro_id" type="text" class="form-control" placeholder="Sid | Password"
                                                   formControlName="pro_id"/>
                                            <small *ngIf="submitted && updateProIdForm.get('pro_id').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row" *ngIf="selectedEnrollmentData">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form class="form mt-2 shadow-none" novalidate [formGroup]="updateEnrollmentForm">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="enrollment_id">Enrollment Id</label>
                                            <input id="enrollment_id" type="text" class="form-control"
                                                   placeholder="Enrollment Id"
                                                   formControlName="enrollment_id"/>
                                            <small *ngIf="submitted && updateEnrollmentForm.get('enrollment_id').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row" *ngIf="selectedRollNoData">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form class="form mt-2 shadow-none" novalidate [formGroup]="updateRollNoForm">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="roll_no">Roll No.</label>
                                            <input id="roll_no" type="text" class="form-control" placeholder="Roll No."
                                                   formControlName="roll_no"/>
                                            <small *ngIf="submitted && updateRollNoForm.get('roll_no').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row" *ngIf="quickLeadAdd">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card-body mt-1">
                            <form class="form" novalidate [formGroup]="addQuickLeadForm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <select class="form-control" formControlName="session_id">
                                                <option class="text-center" [value]="studentSessionYear.id"
                                                        *ngFor=" let studentSessionYear of studentSessionYears">{{studentSessionYear?.session_name}}
                                                    - {{studentSessionYear?.session_year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <select class="form-control" formControlName="stage_id">
                                                <option value="">Select Stage</option>
                                                <option [value]="tab.id"
                                                        *ngFor=" let tab of stageTabs">{{tab?.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <input type="text" class="form-control" placeholder="Name"
                                                   formControlName="name"/>
                                            <small *ngIf="submitted && addQuickLeadForm.get('name').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <input type="email" class="form-control" placeholder="Email"
                                                   formControlName="email"/>
                                            <small *ngIf="submitted && addQuickLeadForm.get('email').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                            <small *ngIf="submitted && addQuickLeadForm.controls['email'].errors && addQuickLeadForm.controls['email'].errors.pattern"
                                                   class="text-danger">Invalid email format</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <input type="number" class="form-control" placeholder="Contact"
                                                   formControlName="contact"/>
                                            <small *ngIf="submitted && addQuickLeadForm.get('contact').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                            <small *ngIf="submitted && addQuickLeadForm.controls['contact'].errors && addQuickLeadForm.controls['contact'].errors.pattern"
                                                   class="text-danger">Invalid contact format</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <select class="form-control" formControlName="program_id"
                                                    (change)="getStudenProgramCourses($event)">
                                                <option value="">Choose Program</option>
                                                <option [value]="studentProgram?.id"
                                                        *ngFor="let studentProgram of studentPrograms">{{studentProgram?.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && addQuickLeadForm.get('program_id').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <select class="form-control" formControlName="course_id"
                                                    (change)="getStudentCourseBranch($event)">
                                                <option value="">Choose Course</option>
                                                <option [value]="studentCourse?.course_id"
                                                        *ngFor="let studentCourse of studentCourses">{{studentCourse?.course?.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && addQuickLeadForm.get('course_id').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="studentBranches && studentBranches.length > 0">
                                        <div class="form-group">
                                            <select class="form-control" formControlName="branch_id"
                                                    (change)="getStudentYear($event)">
                                                <option value="">Choose Branch</option>
                                                <option [value]="studentBranch?.branch?.id"
                                                        *ngFor="let studentBranch of studentBranches">{{studentBranch?.branch?.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="showStudentYear">
                                        <div class="form-group mb-2">
                                            <select class="form-control" formControlName="student_year">
                                                <option value="">Select Year</option>
                                                <option *ngFor="let studentYear of studentYears"
                                                        [value]="studentYear">{{studentYear}}</option>
                                            </select>
                                            <small *ngIf="submitted && addQuickLeadForm.get('student_year').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="assignStudentData">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card-body">
                            <div class="row mt-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center shadow-complete">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">#</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Username</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Phone</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <ng-container
                                                *ngIf="(admins && admins.length > 0) || (headCounsellors && headCounsellors.length > 0) || (counsellors && counsellors.length > 0); else noAssignDataToShowWeb">
                                            <tbody *ngIf="admins && admins.length > 0 && user && user['user_type'] && user['user_type'] === 'SUPER_ADMIN'">
                                            <tr *ngFor="let admin of admins; let i = index">
                                                <td class="text-center">
                                                    <i class="far fa-check-circle cursor-pointer"
                                                       (click)="chooseAssignable(admin, 'ADMIN', i, true)"
                                                       *ngIf="admin && !admin['assignable']"></i>
                                                    <i class="far fa-check-circle cursor-pointer text-success"
                                                       (click)="chooseAssignable(admin, 'ADMIN', i, false)"
                                                       *ngIf="admin && admin['assignable']"></i>
                                                </td>
                                                <td class="text-center">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .
                                                </td>
                                                <td class="text-center">{{admin?.name}}</td>
                                                <td class="text-center">{{admin?.username}}</td>
                                                <td class="text-center">{{admin?.email}}</td>
                                                <td class="text-center">{{admin?.contact}}</td>
                                            </tr>
                                            </tbody>
                                            <tbody *ngIf="headCounsellors && headCounsellors.length > 0 && user && user['user_type'] && user['user_type'] === 'ADMIN'">
                                            <tr *ngFor="let headCounsellor of headCounsellors; let i = index">
                                                <td class="text-center">
                                                    <i class="far fa-check-circle cursor-pointer"
                                                       (click)="chooseAssignable(headCounsellor, 'HEAD_COUNSELLOR', i, true)"
                                                       *ngIf="headCounsellor && !headCounsellor['assignable']"></i>
                                                    <i class="far fa-check-circle cursor-pointer text-success"
                                                       (click)="chooseAssignable(headCounsellor, 'HEAD_COUNSELLOR', i, false)"
                                                       *ngIf="headCounsellor && headCounsellor['assignable']"></i>
                                                </td>
                                                <td class="text-center">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .
                                                </td>
                                                <td class="text-center">{{headCounsellor?.user?.name}}</td>
                                                <td class="text-center">{{headCounsellor?.user?.username}}</td>
                                                <td class="text-center">{{headCounsellor?.user?.email}}</td>
                                                <td class="text-center">{{headCounsellor?.user?.contact}}</td>
                                            </tr>
                                            </tbody>
                                            <tbody *ngIf="counsellors && counsellors.length > 0 && user && user['user_type'] && user['user_type'] === 'HEAD_COUNSELLOR'">
                                            <tr *ngFor="let counsellor of counsellors; let i = index">
                                                <td class="text-center">
                                                    <i class="far fa-check-circle cursor-pointer"
                                                       (click)="chooseAssignable(counsellor, 'COUNSELLOR', i, true)"
                                                       *ngIf="counsellor && !counsellor['assignable']"></i>
                                                    <i class="far fa-check-circle cursor-pointer text-success"
                                                       (click)="chooseAssignable(counsellor, 'COUNSELLOR', i, false)"
                                                       *ngIf="counsellor && counsellor['assignable']"></i>
                                                </td>
                                                <td class="text-center">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .
                                                </td>
                                                <td class="text-center">{{counsellor['counsellor'].name}}</td>
                                                <td class="text-center">{{counsellor['counsellor'].username}}</td>
                                                <td class="text-center">{{counsellor['counsellor'].email}}</td>
                                                <td class="text-center">{{counsellor['counsellor'].contact}}</td>
                                            </tr>
                                            </tbody>
                                        </ng-container>
                                        <ng-template #noAssignDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="6">No data to show</td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                            <div class="row pagination-table">
                                <ngb-pagination class="pagination-primary" [collectionSize]="pagination.total"
                                                [(page)]="pagination.page" [boundaryLinks]="true"
                                                [pageSize]="pagination.perpage" [maxSize]="5"
                                                (pageChange)="onAssignPageChange($event)">
                                    <ng-template ngbPaginationPrevious>
                                        <span [data-feather]="'chevron-left'" [size]="18"
                                              [class]="'font-weight-bolder'">
                                        </span>
                                    </ng-template>
                                    <ng-template ngbPaginationNext>
                                        <span [data-feather]="'chevron-right'" [size]="18"
                                              [class]="'font-weight-bolder'">
                                        </span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row mt--5px mb--5px">
                                <div class="col-12">
                                    <button class="btn btn-success pull-right" (click)="assignToStudent()">Assign
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="bulkUploadProId">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card-body text-center" *ngIf="showFetchingDataLoader">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <i class="fas fa-spinner fa-pulse p-5 text-primary fa-3x"></i>
                                </div>
                                <div class="col-12 text-center text-primary">
                                    Please wait, updating students record ...
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center" *ngIf="showUpdatingDataLoader">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <i class="fas fa-spinner fa-pulse p-5 text-primary fa-3x"></i>
                                </div>
                                <div class="col-12 text-center text-primary">
                                    Please wait, fetching students record ...
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center"
                             *ngIf="!bulkProIdData || bulkProIdData.length === 0 && !showFetchingDataLoader">
                            <i class="fas fa-file-excel fa-5x p-100px text-success cursor-pointer"
                               (click)="fileUploadBulk.click()"></i>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onFileChange($event, 'STUDENT_BULK_PRO_ID_UPLOAD')" #fileUploadBulk>
                            <h6>Upload New Excel (supported format xls, xlsx)</h6>
                        </div>
                        <div class="card-body mt-1 text-center overflow-x-unset overflow-y-scroll max-height-700px"
                             *ngIf="bulkProIdData && bulkProIdData.length > 0 && !showFetchingDataLoader">
                            <table class="table table-list shadow-complete">
                                <thead>
                                <tr>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Application Id</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Sid | Password</h6>
                                    </th>
                                </tr>
                                </thead>
                                <tbody *ngIf="bulkProIdData && bulkProIdData.length > 0">
                                <tr *ngFor="let bulkStudent of bulkProIdData; let i = index">
                                    <td>{{i + 1}}.</td>
                                    <td class="text-center">
                                        <i class="fas fa-trash text-danger cursor-pointer"
                                           (click)="removeProId(i)"></i>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="text"
                                               [ngClass]="{'mt-30px': bulkStudent && bulkStudent['errors'] && (bulkStudent['errors'].application_no_message || bulkStudent['errors'].application_no_duplicate_message)}"
                                               [(ngModel)]="bulkStudent.application_no"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].application_no_message">{{bulkStudent['errors'].application_no_message}}</span>
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].application_no_duplicate_message">{{bulkStudent['errors'].application_no_duplicate_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="text"
                                               [ngClass]="{'mt-30px': bulkStudent && bulkStudent['errors'] && (bulkStudent['errors'].pro_id_message || bulkStudent['errors'].pro_id_duplicate_message)}"
                                               [(ngModel)]="bulkStudent.pro_id"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].pro_id_message">{{bulkStudent['errors'].pro_id_message}}</span>
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].pro_id_duplicate_message">{{bulkStudent['errors'].pro_id_duplicate_message}}</span>
                                        </small>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="bulkUploadAdd">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                         *ngIf="bulkStudentData.length > 0 && this.universities.length > 1">
                        <div class="row">
                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12"></div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <form class="form" novalidate [formGroup]="bulkUniversityForm">
                                    <select class="form-control" formControlName="university_id">
                                        <option value="">Select University</option>
                                        <option [value]="university.university.id"
                                                *ngFor="let university of universities">{{university?.university?.name}}</option>
                                    </select>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card-body text-center" *ngIf="showFetchingDataLoader">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <i class="fas fa-spinner fa-pulse p-5 text-primary fa-3x"></i>
                                </div>
                                <div class="col-12 text-center text-primary">
                                    Please wait, updating students record ...
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center" *ngIf="showUpdatingDataLoader">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <i class="fas fa-spinner fa-pulse p-5 text-primary fa-3x"></i>
                                </div>
                                <div class="col-12 text-center text-primary">
                                    Please wait, fetching students record ...
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center"
                             *ngIf="!bulkStudentData || bulkStudentData.length === 0 && !showFetchingDataLoader">
                            <i class="fas fa-file-excel fa-5x p-100px text-success cursor-pointer"
                               (click)="fileUploadBulk.click()"></i>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onFileChange($event, 'STUDENT_BULK_UPLOAD')" #fileUploadBulk>
                            <h6>Upload New Excel (supported format xls, xlsx)</h6>
                        </div>
                        <div class="card-body mt-1 text-center overflow-x-unset overflow-y-scroll max-height-700px"
                             *ngIf="bulkStudentData && bulkStudentData.length > 0 && !showFetchingDataLoader">
                            <table class="table table-list shadow-complete">
                                <thead>
                                <tr>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                    </th>
                                </tr>
                                </thead>
                                <tbody *ngIf="bulkStudentData && bulkStudentData.length > 0">
                                <tr *ngFor="let bulkStudent of bulkStudentData; let i = index">
                                    <td>{{i + 1}}.</td>
                                    <td class="text-center">
                                        <i class="fas fa-trash text-danger cursor-pointer"
                                           (click)="removeStudent(i)"></i>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="text"
                                               [ngClass]="{'mt-30px': bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].name_message}"
                                               [(ngModel)]="bulkStudent.name"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].name_message">{{bulkStudent['errors'].name_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="text"
                                               [ngClass]="{'mt-30px': bulkStudent && bulkStudent['errors'] && (bulkStudent['errors'].email_message || bulkStudent['errors'].email_duplicate_message)}"
                                               [(ngModel)]="bulkStudent.email"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].email_message">{{bulkStudent['errors'].email_message}}</span>
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].email_duplicate_message">{{bulkStudent['errors'].email_duplicate_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="number"
                                               [ngClass]="{'mt-30px': bulkStudent && bulkStudent['errors'] && (bulkStudent['errors'].contact_message || bulkStudent['errors'].contact_duplicate_message)}"
                                               [(ngModel)]="bulkStudent.contact"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].contact_message">{{bulkStudent['errors'].contact_message}}</span>
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].contact_duplicate_message">{{bulkStudent['errors'].contact_duplicate_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <select class="form-control mt-1"
                                                [ngClass]="{'mt-30px': bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].course_message}"
                                                [(ngModel)]="bulkStudent.course_id">
                                            <option value="">Select Course</option>
                                            <option [value]="course.id"
                                                    *ngFor="let course of courses">{{course?.name}}</option>
                                        </select>
                                        <small class="text-danger">
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].course_message">{{bulkStudent['errors'].course_message}}</span>
                                        </small>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="transactionData">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                         *ngIf="transactionData && transactionData['transactions'] && transactionData['transactions'].length > 0">
                        <table class="table table-list">
                            <thead>
                            <tr>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Transaction No.</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Amount (in Rs.)</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Fee Type</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Date Time</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                </th>
                            </tr>
                            </thead>
                            <tbody *ngIf="transactionData && transactionData['transactions'] && transactionData['transactions'].length > 0; else noDataToShowWeb">
                            <ng-container *ngFor="let transaction of transactionData['transactions']; let i = index">
                                <tr *ngIf="transaction['rmk'] !== 'IN_PROGRESS'">
                                    <td class="text-center">{{i + 1}}.</td>
                                    <td class="text-center">
                                        <span class="cursor-pointer text-primary"
                                              *ngIf="transaction['transaction_no'] && transaction['rmk'] === 'SUCCESS'"
                                              (click)="goToTransactionReceipt(transaction['transaction_no'])">{{transaction?.transaction_no}}</span>
                                        <span *ngIf="transaction['transaction_no'] && transaction['rmk'] !== 'SUCCESS'">{{transaction?.transaction_no}}</span>
                                    </td>
                                    <td class="text-center">{{transaction?.amount}}</td>
                                    <td class="text-center">
                                        <span *ngIf="transaction['fee_type'] === 'REGISTRATION_FEE'">Registration Fee</span>
                                        <span *ngIf="transaction['fee_type'] === 'COURSE_FEE'">Course Fee</span>
                                        <span *ngIf="transaction['fee_type'] === 'TOTAL_FEE'">Total Fee</span>
                                    </td>
                                    <td class="text-center">{{transaction?.tet}}</td>
                                    <td class="text-center text-capitalize">
                                        <span *ngIf="transaction['rmk'] === 'SUCCESS'">Success</span>
                                        <span *ngIf="transaction['rmk'] === 'FAILURE'">Failure</span>
                                        <span *ngIf="transaction['rmk'] === 'PENDING'">Pending</span>
                                        <span *ngIf="transaction['rmk'] === 'ABORT'">Abort</span>
                                    </td>
                                </tr>
                            </ng-container>
                            </tbody>
                            <ng-template #noDataToShowWeb>
                                <tbody>
                                <tr>
                                    <td colspan="6" class="text-center">No data to show</td>
                                </tr>
                                </tbody>
                            </ng-template>
                        </table>
                    </div>
                </div>
                <div class="row" *ngIf="reRegisterStudentSelect">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row m-1">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="reRegisterForm">
                                    <div class="row mt-1">
                                        <div class="col-9">
                                            <h5>Student Current Year :</h5>
                                        </div>
                                        <div class="col-3 text-right">
                                            <h5>{{reRegisterStudentSelect?.student_year}}</h5>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="col-12">
                                            <div class="form-group mb-2">
                                                <select class="form-control" formControlName="student_year">
                                                    <option value="">Select Re - Registration Year</option>
                                                    <option [value]="year"
                                                            *ngFor="let year of studentReRegisterYears">{{year}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <select class="form-control" formControlName="session_id">
                                                    <option value="">Select Session</option>
                                                    <option class="text-center" [value]="session.id"
                                                            *ngFor="let session of reRegistrationSession">{{session?.session_year}}
                                                        - {{session?.session_name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="openStudentPhotographDownloadModal">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row m-1">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="studentPhotographDownloadForm">
                                    <div class="row mt-1">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="from">From</label>
                                                <input class="form-control" formControlName="from" type="date"
                                                       id="from"/>
                                                <small *ngIf="submitted && studentPhotographDownloadForm.get('from').hasError('required')"
                                                       class="text-danger">
                                                    Field is required.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="from">To</label>
                                                <input class="form-control" formControlName="to" type="date" id="to"/>
                                                <small *ngIf="submitted && studentPhotographDownloadForm.get('to').hasError('required')"
                                                       class="text-danger">
                                                    Field is required.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="openStudentPhotographDownloadModalProgress && !downloadZipUrl">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <div class="row p-5">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <i class="fas fa-spinner fa-pulse fa-5x text-primary"></i>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <small class="mt-1 text-primary">Please wait, we are preparing downlodable students
                                    photograph's zip ...</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row"
                     *ngIf="!openStudentPhotographDownloadModalProgress && downloadZipUrl && !openStudentPhotographDownloadModal">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <div class="row p-5">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <i class="fas fa-images color-warning f_20 cursor-pointer fa-5x"></i>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                <button class="btn btn-success" (click)="downloadStudentPhotographZip()">
                                    <i class="fas fa-file-archive mr-1"></i>Download Zip
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger pull-right mt-1 mb-1" *ngIf="openDeleteModal" (click)="deleteStudent()"
                        [disabled]="submitting">
                    <i class="fas fa-trash mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Delete
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="updateStatus()"
                        *ngIf="selectedStudentData" [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="updateProId()" *ngIf="selectedProIdData"
                        [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="updateEnrollment()"
                        *ngIf="selectedEnrollmentData" [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="updateRollNo()" *ngIf="selectedRollNoData"
                        [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="submitQuickLead()" *ngIf="quickLeadAdd"
                        [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
                <button class="btn btn-danger pull-left mt-1 mb-1" (click)="newUpload()"
                        *ngIf="bulkUploadAdd && bulkStudentData && bulkStudentData.length > 0">
                    New Upload
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="submitBulkUpload()" *ngIf="bulkUploadAdd"
                        [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="submitBulkProId()" *ngIf="bulkUploadProId"
                        [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="submitReRegister()"
                        *ngIf="reRegisterStudentSelect"
                        [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="downloadPhotograph()"
                        *ngIf="openStudentPhotographDownloadModal"
                        [disabled]="submitting">
                    <i class="fas fa-download mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Download
                </button>
                <button class="btn btn-danger pull-right" (click)="modalService.dismissAll('')"
                        *ngIf="selectedStudentDocumentErrorData">
                    Close
                </button>
            </div>
        </div>
    </ng-template>
    <div class="content-body">
        <section id="students">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <h2 class="mt-5px">Re - Register Students</h2>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <button type="button" class="btn btn-icon btn-secondary pull-right ml-6px" rippleEffect
                            title="Bulk Mail" *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-envelope color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-secondary pull-right ml-6px" rippleEffect
                            title="Bulk WhatsApp" *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fab fa-whatsapp color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-secondary pull-right ml-6px" rippleEffect
                            title="Bulk SMS" *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-sms color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Bulk Upload" (click)="openBulkUploadModal(content)"
                            *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-cloud-upload-alt color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Bulk Upload Sid | Password" (click)="openProIdBulkUploadModal(content)"
                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-cloud-upload-alt color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Download Sample" *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1"
                            (click)="downloadSample()">
                        <i class="fas fa-cloud-download-alt color-white f_20 cursor-pointer"></i>
                    </button>
                    <!--<button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Add Student" (click)="openQuickLeadModal(content)"
                            *ngIf="['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-plus-circle color-white f_20 cursor-pointer"></i>
                    </button>-->
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Students" (click)="goTo('students')"
                            *ngIf="['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-user-graduate color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Assign Lead" (click)="openAssignmentModal(content)"
                            *ngIf="studentChecked && user && ['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-user-tag color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-secondary pull-right ml-6px" rippleEffect
                            title="Assign Lead"
                            *ngIf="!studentChecked && user && ['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-user-tag color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Download Excel" (click)="downloadExcel('DISPLAY')"
                            *ngIf="user && ['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-file-excel color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Download Display Excel" (click)="downloadExcel('DISPLAY')"
                            *ngIf="user && ['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-file-excel color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-danger pull-right ml-6px" rippleEffect
                            title="Download Actual Excel" (click)="downloadExcel('ACTUAL')"
                            *ngIf="user && ['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-file-excel color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-success pull-right ml-6px" rippleEffect
                            title="Download Flat Excel" (click)="downloadExcel('FLAT')"
                            *ngIf="user && ['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-file-excel color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-warning pull-right ml-6px" rippleEffect
                            title="Download Student Photograph" (click)="downloadStudentPhotographModal(content)"
                            *ngIf="user && ['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-images color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-success pull-right ml-6px" rippleEffect
                            title="Process Students" (click)="goToProcessStudents()"
                            *ngIf="user && ['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-university color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Accounting" (click)="goToAccounting()"
                            *ngIf="(user && ['BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1) || (user && ['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && msHeadCenter)">
                        <i class="fas fa-calculator color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Accounting" (click)="goToReports()"
                            *ngIf="(user && ['BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1) || (user && ['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && msHeadCenter)">
                        <i class="fas fa-chart-pie color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Search" (click)="showSearch()"
                            *ngIf="user && ['SUPER_ADMIN', 'UNIVERSITY', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-search color-white f_20 cursor-pointer"></i>
                    </button>
                </div>
            </div>
            <div class="row mb-1 mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <form class="row" novalidate [formGroup]="studentSessionForm">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <select class="form-control" formControlName="university_id"
                                    (change)="changeStudentUniversity($event)"
                                    *ngIf="['SUPER_ADMIN'].indexOf(user['user_type']) !== -1">
                                <option class="text-center" [value]="university.id"
                                        *ngFor=" let university of universities">{{university?.name}}</option>
                            </select>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <select class="form-control" formControlName="student_session_year"
                                    (change)="changeStudentSession($event)">
                                <option class="text-center" [value]="studentSessionYear.id"
                                        *ngFor=" let studentSessionYear of studentSessionYears">{{studentSessionYear?.session_name}}
                                    - {{studentSessionYear?.session_year}}</option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card" *ngIf="openSearch">
                <div class="card-body">
                    <form class="row text-center" novalidate [formGroup]="searchForm">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Name" formControlName="name"/>
                            <small>Name</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Application Number"
                                   formControlName="application_number"/>
                            <small>Application Id</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Email" formControlName="email"/>
                            <small>Email</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Phone" formControlName="contact"/>
                            <small>Phone</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Sid | Password" formControlName="pro_id"/>
                            <small>Sid | Password</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Enrollment Id"
                                   formControlName="enrollment_id"/>
                            <small>Enrollment Id</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Roll No" formControlName="roll_no"/>
                            <small>Roll No.</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <select class="form-control" formControlName="course_id">
                                <option value="">Select Course</option>
                                <option [value]="course.id" *ngFor="let course of courses">{{course?.name}}</option>
                            </select>
                            <small>Course</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['BUSINESS_ADMIN', 'SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="added_by_business_head_center"
                                    (change)="getBusinessCenter($event)">
                                <option value="">Select Head Center</option>
                                <option [value]="headCenter.id"
                                        *ngFor="let headCenter of headCenters">{{headCenter?.name}}</option>
                            </select>
                            <small>Business Head Center</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['BUSINESS_ADMIN', 'SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="added_by">
                                <option value="">Select Center</option>
                                <option [value]="businessCenter['business_center_detail']['user_id']"
                                        *ngFor="let businessCenter of businessCenters">{{businessCenter?.business_center_detail?.code}}
                                    - {{businessCenter?.name}}</option>
                            </select>
                            <small>Business Center</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="added_by">
                                <option value="">Select Center</option>
                                <option [value]="businessCenter['business_center_detail']['user_id']"
                                        *ngFor="let businessCenter of businessCenters">{{businessCenter?.business_center_detail?.code}}
                                    - {{businessCenter?.name}}</option>
                            </select>
                            <small>Business Center</small>
                        </div>
                        <!--<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <select class="form-control" formControlName="universityId"
                                    (change)="getUniversityPrograms($event)"
                                    *ngIf="user && ['SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1">
                                <option value="">University</option>
                                <option [value]="university.id"
                                        *ngFor="let university of searchUniversities; let i = index">{{university?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="universityId"
                                    (change)="getUniversityPrograms($event)"
                                    *ngIf="user && ['HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">University</option>
                                <option [value]="university['university'].id"
                                        *ngFor="let university of headCounsellorUniversities; let i = index">{{university['university']?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="universityId"
                                    (change)="getUniversityPrograms($event)"
                                    *ngIf="user && ['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">University</option>
                                <option [value]="university['university'].id"
                                        *ngFor="let university of counsellorUniversities; let i = index">{{university['university']?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="universityId"
                                    (change)="getUniversityPrograms($event)"
                                    *ngIf="user && ['UNIVERSITY'].indexOf(user['user_type']) !== -1">
                                <option value="">University</option>
                                <option [value]="user['userId']">{{user?.name}}</option>
                            </select>
                            <small>University</small>
                        </div>-->

                        <!--<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <select class="form-control" formControlName="programId"
                                    (change)="getProgramCourses($event)">
                                <option value="">Programs</option>
                                <option [value]="program.id"
                                        *ngFor="let program of filterPrograms">{{program?.name}}</option>
                            </select>
                            <small>Program</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <select class="form-control" formControlName="courseId"
                                    (change)="getCourseBranches($event)">
                                <option value="">Course</option>
                                <option [value]="course.id"
                                        *ngFor="let course of filterCourses">{{course?.name}}</option>
                            </select>
                            <small>Course</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <select class="form-control" formControlName="branchId">
                                <option value="">Branch</option>
                                <option [value]="branch['branch'].id"
                                        *ngFor="let branch of filterBranches">{{branch?.branch?.nam}}</option>
                            </select>
                            <small>Branch</small>
                        </div>-->

                        <!--<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['SUPER_ADMIN', 'ADMIN',  'HEAD_COUNSELLOR', 'COUNSELLOR'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="adminId"
                                    (change)="getHeadCounsellorsForSearch($event)"
                                    *ngIf="user && ['SUPER_ADMIN'].indexOf(user['user_type']) !== -1">
                                <option value="">Admin</option>
                                <option [value]="admin.id" *ngFor="let admin of searchAdmins">{{admin?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="adminId"
                                    (change)="getHeadCounsellorsForSearch($event)"
                                    *ngIf="user && ['ADMIN'].indexOf(user['user_type']) !== -1">
                                <option value="">Admin</option>
                                <option [value]="user.userId">{{user?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="adminId"
                                    *ngIf="user && ['HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">Admin</option>
                                <option [value]="headCounsellorDetailAdmin.id">{{headCounsellorDetailAdmin?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="adminId"
                                    *ngIf="user && ['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">Admin</option>
                                <option [value]="counsellorAdmin.id">{{counsellorAdmin?.name}}</option>
                            </select>
                            <small>Admin</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['SUPER_ADMIN', 'ADMIN',  'HEAD_COUNSELLOR', 'COUNSELLOR'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="headCounsellorId"
                                    (change)="getCounsellorsForSearch($event)"
                                    *ngIf="user && ['SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1">
                                <option value="">Head Counsellor</option>
                                <option [value]="headCounsellor['head_counsellor'].id"
                                        *ngFor="let headCounsellor of searchHeadCounsellors">{{headCounsellor['head_counsellor']?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="headCounsellorId"
                                    (change)="getCounsellorsForSearch($event)"
                                    *ngIf="user && ['HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">Head Counsellor</option>
                                <option [value]="user.userId">{{user?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="headCounsellorId"
                                    (change)="getCounsellorsForSearch($event)"
                                    *ngIf="user && ['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">Head Counsellor</option>
                                <option [value]="counsellorHeadCounsellor.id">{{counsellorHeadCounsellor?.name}}</option>
                            </select>
                            <small>Head Counsellor</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['SUPER_ADMIN', 'ADMIN',  'HEAD_COUNSELLOR', 'COUNSELLOR'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="counsellorId">
                                <option value="">Counsellor</option>
                                <option [value]="counsellor['counsellor'].id"
                                        *ngFor="let counsellor of searchCounsellors">{{counsellor['counsellor']?.name}}</option>
                            </select>
                            <small>Counsellor</small>
                        </div>-->
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <button class="btn btn-primary btn-block" (click)="search()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="card-body">
                        <div class="row overflow-x-scroll overflow-table">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <table class="table table-list text-center shadow-complete">
                                    <thead>
                                    <tr>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Current Year</h6>
                                        </th>
                                        <th class="text-center"
                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Head Center</h6>
                                        </th>
                                        <th class="text-center"
                                            *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Center</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Application Id</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Sid | Password</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Enrollment Id</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Roll No.</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Status Remark</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Process Status</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Photograph</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Signature</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Father's Name</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Mother's Name</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Phone</h6>
                                        </th>
                                        <th class="text-center"
                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Admission Actual (%)</h6>
                                        </th>
                                        <th class="text-center"
                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Re-Regsitration Actual
                                                (%)</h6>
                                        </th>
                                        <th class="text-center"
                                            *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Admission <span
                                                    *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">Display</span>
                                                (%)</h6>
                                        </th>
                                        <th class="text-center"
                                            *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Re-Regsitration <span
                                                    *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">Display</span>
                                                (%)</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Prospectus Fee
                                                (&#8377;)</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Registration Fee (&#8377;)</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Course Fee (&#8377;)</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Service Fee
                                                (&#8377;)</h6>
                                        </th>
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Examination Fee
                                                (&#8377;)</h6>
                                        </th>
                                        <!--<th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Re-Registration Fee (&#8377;)</h6>
                                        </th>-->
                                        <th class="text-center">
                                            <h6 class="mt-1 mb-1 font-weight-bold">Total Fee (&#8377;)</h6>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody *ngIf="tabs[0] && tabs[0]['data'] && tabs[0]['data'].length > 0; else noDataToShowWeb">
                                    <tr *ngFor="let data of tabs[0]['data']; let i = index"
                                        [ngClass]="{'background-light-red': (data && (!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === ''))}">
                                        <td class="text-center">
                                            <h6 class="mt-1">{{(studentPagination.page - 1) * (studentPagination.perpage ? studentPagination.perpage : 0) + i + 1}}
                                                .</h6>
                                        </td>
                                        <td class="text-center" style="min-width: 300px">
                                            <i class="fas fa-registered f_20 cursor-pointer mt-1 mr-8px"
                                               [ngClass]="{'text-primary': (data &&
                                                           ((data['added_by'] === user['userId'] && (['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1)) || ((data['added_by'] !== user['userId'] &&
                                                           (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1))))),
                                                           'text-danger': (data && data['added_by'] === user['userId'] && (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1))}"
                                               title="Re - Register Student"
                                               (click)="openReRegister(content, data)"
                                               *ngIf="showReRegistration && ['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && (this.studentSessionForm.value.student_session_year == 1 || this.studentSessionForm.value.student_session_year == 2)"></i>
                                            <i aria-hidden="true"
                                               class="fab fa-artstation f_20 cursor-pointer font-weight-light mt-1"
                                               *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1"
                                               [ngClass]="{'text-primary': (data &&
                                                           ((data['added_by'] === user['userId'] && (['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1)) || ((data['added_by'] !== user['userId'] &&
                                                           (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1))))),
                                                           'text-danger': (data && data['added_by'] === user['userId'] && (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1))}"
                                               (click)="navigateToUrl('admission-form/' + data.id)"></i>
                                            <i aria-hidden="true"
                                               class="fas fa-user-cog f_20 cursor-pointer mt-1 ml-1"
                                               [ngClass]="{'text-primary': (data &&
                                                           ((data['added_by'] === user['userId'] && (['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1)) || ((data['added_by'] !== user['userId'] &&
                                                           (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1))))),
                                                           'text-danger': (data && data['added_by'] === user['userId'] && (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1))}"
                                               title="user Details" (click)="detail(data)"></i>
                                            <i aria-hidden="true"
                                               class="fas fa-print f_20 text-secondary cursor-pointer mt-1 ml-1"
                                               title="Print Form"
                                               *ngIf="(['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && (!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === ''))"></i>
                                            <i aria-hidden="true"
                                               class="fas fa-print f_20 text-primary cursor-pointer mt-1 ml-1"
                                               title="Print Form" (click)="goToPrintForm(data)"
                                               *ngIf="(['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === ''))"></i>
                                            <i aria-hidden="true"
                                               class="fas fa-download f_20 text-primary cursor-pointer mt-1 ml-1"
                                               title="Download Documents"
                                               (click)="studentDocumentDownload(data)"
                                               *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '') && !data['student_document_download_loader'])"></i>
                                            <i aria-hidden="true"
                                               class="fas fa-spinner fa-pulse f_20 text-primary cursor-pointer mt-1 ml-1"
                                               title="Document Downloading"
                                               *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '') && data['student_document_download_loader'])"></i>
                                            <i aria-hidden="true"
                                               class="fas fa-exclamation-circle f_20 text-warning cursor-pointer mt-1 ml-1"
                                               title="Document Error"
                                               (click)="showStudentDocumentError(content, data)"
                                               *ngIf="(!(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '') &&
                                                           ((data['govt_photo_id_proof_error'] && data['govt_photo_id_proof_error'] === 'TRUE') || (data['tenth_certificate_error'] && data['tenth_certificate_error'] === 'TRUE') || (data['twelth_certificate_error'] && data['twelth_certificate_error'] === 'TRUE') || (data['graduate_certificate_error'] && data['graduate_certificate_error'] === 'TRUE')))">
                                            </i>
                                            <i aria-hidden="true"
                                               class="fas fa-trash f_20 text-danger cursor-pointer mt-1 ml-1"
                                               title="Delete User" (click)="delete(content, data)"
                                               *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1)"></i>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1">
                                                {{data?.re_registration_year}}
                                            </h6>
                                        </td>
                                        <td class="text-center"
                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1">
                                                {{data?.head_counsellor?.name}}
                                            </h6>
                                        </td>
                                        <td class="text-center"
                                            *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1">
                                                {{data?.business_center?.business_center_detail?.code}}
                                            </h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1">{{data?.username}}</h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1 cursor-pointer"
                                                *ngIf="data && data['status_id'] && data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.pro_id}}</h6>
                                            <h6 class="mt-1 cursor-pointer"
                                                *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.pro_id}}</h6>
                                            <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['pro_id'] !== '-' && data && data['status_id'] !== 10"
                                                (click)="changeProModal(content, data)">{{data?.pro_id}}</h6>
                                            <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['pro_id'] === '-' && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')"
                                                (click)="changeProModal(content, data)">
                                                <i class="fas fa-plus-circle mr-1"></i>Add Sid | Password
                                            </h6>
                                            <h6 class="mt-1 cursor-pointer"
                                                *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                -
                                            </h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1 cursor-pointer"
                                                *ngIf="data && data['status_id'] && data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.enrollment_id}}</h6>
                                            <h6 class="mt-1 cursor-pointer"
                                                *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.enrollment_id}}</h6>
                                            <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['enrollment_id'] !== '-' && data && data['status_id'] !== 10"
                                                (click)="changeEnrollmentModal(content, data)">{{data?.enrollment_id}}</h6>
                                            <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['enrollment_id'] === '-' && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')"
                                                (click)="changeEnrollmentModal(content, data)">
                                                <i class="fas fa-plus-circle mr-1"></i>Add Enrollment
                                            </h6>
                                            <h6 class="mt-1 cursor-pointer"
                                                *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                -
                                            </h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1 cursor-pointer"
                                                *ngIf="data && data['status_id'] && data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.roll_no}}</h6>
                                            <h6 class="mt-1 cursor-pointer"
                                                *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.roll_no}}</h6>
                                            <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['roll_no'] !== '-' && data && data['status_id'] !== 10"
                                                (click)="changeRollNoModal(content, data)">{{data?.roll_no}}</h6>
                                            <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['roll_no'] === '-' && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')"
                                                (click)="changeRollNoModal(content, data)">
                                                <i class="fas fa-plus-circle mr-1"></i>Add Roll No.
                                            </h6>
                                            <h6 class="mt-1 cursor-pointer"
                                                *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                -
                                            </h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                *ngIf="data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                {{(data && data['status'] && data['status'] === 'pending') ? 'Pending' : ''}}
                                                {{(data && data['status'] && data['status'] === 'form_received') ? 'Form Received' : ''}}
                                                {{(data && data['status'] && data['status'] === 'sent') ? 'Sent' : ''}}
                                                {{(data && data['status'] && data['status'] === 're_registration_confirmed') ? 'Re - Registration Confrimed' : ''}}
                                                {{(data && data['status'] && data['status'] === 'DOCUMENTS REQUIRED') ? 'Documents Required' : ''}}
                                                {{(data && data['status'] && data['status'] === 'DOCUMENTS RE UPLOADED') ? 'Documents Re-Uploaded' : ''}}
                                                {{(data && data['status'] && data['status'] === 'DOCUMENTS UPLOADED') ? 'Documents Uploaded' : ''}}
                                            </h6>
                                            <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 &&
                                                data['status_id'] !== 10 &&
                                                !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')"
                                            >
                                                {{(data && data['status'] && data['status'] === 'pending') ? 'Pending' : ''}}
                                                {{(data && data['status'] && data['status'] === 'form_received') ? 'Form Received' : ''}}
                                                {{(data && data['status'] && data['status'] === 'sent') ? 'Sent' : ''}}
                                                {{(data && data['status'] && data['status'] === 're_registration_confirmed') ? 'Re - Registration Confrimed' : ''}}
                                                {{(data && data['status'] && data['status'] === 'DOCUMENTS REQUIRED') ? 'Documents Required' : ''}}
                                                {{(data && data['status'] && data['status'] === 'DOCUMENTS RE UPLOADED') ? 'Documents Re-Uploaded' : ''}}
                                                {{(data && data['status'] && data['status'] === 'DOCUMENTS UPLOADED') ? 'Documents Uploaded' : ''}}
                                            </h6>
                                            <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['status_id'] !== 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')"
                                                (click)="changeStatusModal(content, data)">
                                                {{(data && data['status'] && data['status'] === 'pending') ? 'Pending' : ''}}
                                                {{(data && data['status'] && data['status'] === 'form_received') ? 'Form Received' : ''}}
                                                {{(data && data['status'] && data['status'] === 'sent') ? 'Sent' : ''}}
                                                {{(data && data['status'] && data['status'] === 're_registration_confirmed') ? 'Re - Registration Confrimed' : ''}}
                                                {{(data && data['status'] && data['status'] === 'DOCUMENTS REQUIRED') ? 'Documents Required' : ''}}
                                                {{(data && data['status'] && data['status'] === 'DOCUMENTS RE UPLOADED') ? 'Documents Re-Uploaded' : ''}}
                                                {{(data && data['status'] && data['status'] === 'DOCUMENTS UPLOADED') ? 'Documents Uploaded' : ''}}
                                            </h6>
                                            <h6 class="mt-1 cursor-pointer text-capitalize"
                                                *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                Incomplete
                                            </h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1"
                                                *ngIf="data && data['remarks']">{{data['remarks']}}</h6>
                                            <h6 class="mt-1" *ngIf="!data || !data['remarks']">-</h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                *ngIf="data && data['process_status']">
                                                {{data['process_status']}}
                                            </h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1">{{data?.name}}</h6>
                                        </td>
                                        <td class="text-center">
                                            <img [src]="data['photograph'].url" class="width-40px"
                                                 *ngIf="data && data['photograph'] && data['photograph'].url"/>
                                            <h6 *ngIf="!data || !data['photograph'] || !data['photograph'].url">
                                                -</h6>
                                        </td>
                                        <td class="text-center">
                                            <img [src]="data['signature'].url" class="width-80px"
                                                 *ngIf="data && data['signature'] && data['signature'].url"/>
                                            <h6 *ngIf="!data || !data['signature'] || !data['photograph'].url">
                                                -</h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1">{{data?.father_name}}</h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1">{{data?.mother_name}}</h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1"
                                                *ngIf="data && !data.course && data['register_course']">{{data?.register_course}}</h6>
                                            <h6 class="mt-1"
                                                *ngIf="data && data.course && data.branch">{{data?.course}}
                                                ({{data?.branch}})</h6>
                                            <h6 class="mt-1"
                                                *ngIf="data && data.course && !data.branch">{{data?.course}}</h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1">{{data?.email}}</h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1">{{data?.phone}}</h6>
                                        </td>
                                        <td class="text-center"
                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1"
                                                *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_actual">
                                                {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_actual) ? data['business_center']['business_center_detail'].admission_percentage_actual : ''}}
                                            </h6>
                                            <h6 class="mt-1"
                                                *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].admission_percentage_actual">
                                                -</h6>
                                        </td>
                                        <td class="text-center"
                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1"
                                                *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_actual">
                                                {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_actual) ? data['business_center']['business_center_detail'].re_registration_percentage_display : ''}}
                                            </h6>
                                            <h6 class="mt-1"
                                                *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].re_registration_percentage_actual">
                                                -
                                            </h6>
                                        </td>
                                        <td class="text-center"
                                            *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1"
                                                *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_display">
                                                {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_display) ? data['business_center']['business_center_detail'].admission_percentage_display : ''}}
                                            </h6>
                                            <h6 class="mt-1"
                                                *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].admission_percentage_display">
                                                -</h6>
                                        </td>
                                        <td class="text-center"
                                            *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                            <h6 class="mt-1"
                                                *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_display">
                                                {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_display) ? data['business_center']['business_center_detail'].re_registration_percentage_display : ''}}
                                            </h6>
                                            <h6 class="mt-1"
                                                *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].re_registration_percentage_display">
                                                -
                                            </h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1"
                                                *ngIf="data && data['prospectus_fee'] && data['prospectus_fee'] != '-'">
                                                {{data['prospectus_fee'] | IndianNumber}}
                                            </h6>
                                            <h6 class="mt-1"
                                                *ngIf="!data || !data['prospectus_fee'] || data['prospectus_fee'] === '-'">
                                                -
                                            </h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1"
                                                *ngIf="data && data['pcb_mapping'] && data['pcb_mapping']['registration_amount']">
                                                {{data?.pcb_mapping?.registration_amount | IndianNumber}}
                                            </h6>
                                            <h6 class="mt-1"
                                                *ngIf="!data || !data['pcb_mapping'] || !data['pcb_mapping']['registration_amount']">
                                                -</h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1" *ngIf="data && data['chargeableCourseFee']">
                                                {{data?.chargeableCourseFee | IndianNumber}}
                                            </h6>
                                            <h6 class="mt-1" *ngIf="!data || !data['chargeableCourseFee']">
                                                -
                                            </h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1"
                                                *ngIf="data && data['service_charge'] && data['service_charge'] != '-'">
                                                {{data['service_charge'] | IndianNumber}}
                                            </h6>
                                            <h6 class="mt-1"
                                                *ngIf="!data || !data['service_charge'] || data['service_charge'] === '-'">
                                                -
                                            </h6>
                                        </td>
                                        <td class="text-center">
                                            <h6 class="mt-1"
                                                *ngIf="data && data['service_charge'] && data['examination_fee'] != '-'">
                                                {{data['examination_fee'] | IndianNumber}}
                                            </h6>
                                            <h6 class="mt-1"
                                                *ngIf="!data || !data['examination_fee'] || data['examination_fee'] === '-'">
                                                -
                                            </h6>
                                        </td>
                                        <!--<td class="text-center">
                                            <h6 class="mt-1"
                                                *ngIf="data && data['pcb_mapping'] && data['pcb_mapping']['re_registration_amount']">
                                                {{data?.pcb_mapping?.re_registration_amount | IndianNumber}}
                                            </h6>
                                            <h6 class="mt-1"
                                                *ngIf="!data || !data['pcb_mapping'] || !data['pcb_mapping']['re_registration_amount']">
                                                -
                                            </h6>
                                        </td>-->
                                        <td class="text-center">
                                            <h6 class="mt-1" *ngIf="data && data['total']">
                                                {{data?.total | IndianNumber}}
                                            </h6>
                                            <h6 class="mt-1" *ngIf="!data || !data['total']">-
                                            </h6>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <ng-template #noDataToShowWeb>
                                        <tbody>
                                        <tr>
                                            <td colspan="13" class="text-center"
                                                *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                <h6>No data to show</h6>
                                            </td>
                                            <td colspan="9" class="text-center"
                                                *ngIf="['SUPER_ADMIN', 'UNIVERSITY'].indexOf(user['user_type']) !== -1">
                                                <h6>No data to show</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </ng-template>
                                </table>
                            </div>
                        </div>
                        <div class="row pagination-table mt-2">
                            <ngb-pagination class="pagination-primary"
                                            [collectionSize]="studentPagination.total"
                                            [(page)]="studentPagination.page" [boundaryLinks]="true"
                                            [pageSize]="studentPagination.perpage" [maxSize]="5"
                                            (pageChange)="onStudentPageChange($event)">
                                <ng-template ngbPaginationPrevious
                                ><span [data-feather]="'chevron-left'" [size]="18"
                                       [class]="'font-weight-bolder'"></span>
                                </ng-template>
                                <ng-template ngbPaginationNext
                                ><span [data-feather]="'chevron-right'" [size]="18"
                                       [class]="'font-weight-bolder'"></span
                                ></ng-template>
                            </ngb-pagination>
                        </div>
                    </div>
                    <!--<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill" [(activeId)]="active">
                        <li ngbNavItem [ngbNavItem]="i" *ngFor="let tab of tabs; let i = index">
                            <a ngbNavLink (click)="getTabData(tab, i)">{{tab?.name}}</a>
                            <ng-template ngbNavContent>
                                <div class="card-body">
                                    <div class="row overflow-x-scroll overflow-table">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <table class="table table-list text-center shadow-complete">
                                                <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Head Center</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Center</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Application Id</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Sid | Password</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Enrollment Id</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Roll No.</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Status Remark</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Process Status</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Photograph</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Signature</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Father's Name</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Mother's Name</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Phone</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Admission Actual (%)</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Re-Regsitration Actual
                                                            (%)</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Admission <span
                                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">Display</span>
                                                            (%)</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Re-Regsitration <span
                                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">Display</span>
                                                            (%)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Prospectus Fee
                                                            (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Registration Fee (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Course Fee (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Service Fee
                                                            (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Examination Fee
                                                            (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Re-Registration Fee (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Total Fee (&#8377;)</h6>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody *ngIf="tab && tab['data'] && tab['data'].length > 0; else noDataToShowWeb">
                                                <tr *ngFor="let data of tab['data']; let i = index"
                                                    [ngClass]="{'background-light-red': (data && (!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === ''))}">
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{(studentPagination.page - 1) * (studentPagination.perpage ? studentPagination.perpage : 0) + i + 1}}
                                                            .</h6>
                                                    </td>
                                                    <td class="text-center" style="min-width: 300px">
                                                        <i class="fas fa-registered f_20 cursor-pointer mt-1 mr-8px"
                                                           [ngClass]="{'text-primary': (data &&
                                                           ((data['added_by'] === user['userId'] && (['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1)) || ((data['added_by'] !== user['userId'] &&
                                                           (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1))))),
                                                           'text-danger': (data && data['added_by'] === user['userId'] && (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1))}"
                                                           title="Re - Register Student"
                                                           (click)="openReRegister(content, data)"
                                                           *ngIf="showReRegistration && ['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && (this.studentSessionForm.value.student_session_year == 1 || this.studentSessionForm.value.student_session_year == 2)"></i>
                                                        <i aria-hidden="true"
                                                           class="fab fa-artstation f_20 cursor-pointer font-weight-light mt-1"
                                                           *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1"
                                                           [ngClass]="{'text-primary': (data &&
                                                           ((data['added_by'] === user['userId'] && (['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1)) || ((data['added_by'] !== user['userId'] &&
                                                           (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1))))),
                                                           'text-danger': (data && data['added_by'] === user['userId'] && (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1))}"
                                                           (click)="navigateToUrl('admission-form/' + data.id)"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-user-cog f_20 cursor-pointer mt-1 ml-1"
                                                           [ngClass]="{'text-primary': (data &&
                                                           ((data['added_by'] === user['userId'] && (['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1)) || ((data['added_by'] !== user['userId'] &&
                                                           (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1))))),
                                                           'text-danger': (data && data['added_by'] === user['userId'] && (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1))}"
                                                           title="user Details" (click)="detail(data)"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-print f_20 text-secondary cursor-pointer mt-1 ml-1"
                                                           title="Print Form"
                                                           *ngIf="(['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && (!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === ''))"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-print f_20 text-primary cursor-pointer mt-1 ml-1"
                                                           title="Print Form" (click)="goToPrintForm(data)"
                                                           *ngIf="(['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === ''))"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-download f_20 text-primary cursor-pointer mt-1 ml-1"
                                                           title="Download Documents"
                                                           (click)="studentDocumentDownload(data)"
                                                           *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '') && !data['student_document_download_loader'])"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-spinner fa-pulse f_20 text-primary cursor-pointer mt-1 ml-1"
                                                           title="Document Downloading"
                                                           *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '') && data['student_document_download_loader'])"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-exclamation-circle f_20 text-warning cursor-pointer mt-1 ml-1"
                                                           title="Document Error"
                                                           (click)="showStudentDocumentError(content, data)"
                                                           *ngIf="(!(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '') &&
                                                           ((data['govt_photo_id_proof_error'] && data['govt_photo_id_proof_error'] === 'TRUE') || (data['tenth_certificate_error'] && data['tenth_certificate_error'] === 'TRUE') || (data['twelth_certificate_error'] && data['twelth_certificate_error'] === 'TRUE') || (data['graduate_certificate_error'] && data['graduate_certificate_error'] === 'TRUE')))">
                                                        </i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-trash f_20 text-danger cursor-pointer mt-1 ml-1"
                                                           title="Delete User" (click)="delete(content, data)"
                                                           *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1)"></i>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1">
                                                            {{data?.head_counsellor?.name}}
                                                        </h6>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1">
                                                            {{data?.business_center?.business_center_detail?.code}}
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.username}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="data && data['status_id'] && data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.pro_id}}</h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.pro_id}}</h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['pro_id'] !== '-' && data && data['status_id'] !== 10"
                                                            (click)="changeProModal(content, data)">{{data?.pro_id}}</h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['pro_id'] === '-' && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')"
                                                            (click)="changeProModal(content, data)">
                                                            <i class="fas fa-plus-circle mr-1"></i>Add Sid | Password
                                                        </h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="data && data['status_id'] && data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.enrollment_id}}</h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.enrollment_id}}</h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['enrollment_id'] !== '-' && data && data['status_id'] !== 10"
                                                            (click)="changeEnrollmentModal(content, data)">{{data?.enrollment_id}}</h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['enrollment_id'] === '-' && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')"
                                                            (click)="changeEnrollmentModal(content, data)">
                                                            <i class="fas fa-plus-circle mr-1"></i>Add Enrollment
                                                        </h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="data && data['status_id'] && data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.roll_no}}</h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.roll_no}}</h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['roll_no'] !== '-' && data && data['status_id'] !== 10"
                                                            (click)="changeRollNoModal(content, data)">{{data?.roll_no}}</h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['roll_no'] === '-' && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')"
                                                            (click)="changeRollNoModal(content, data)">
                                                            <i class="fas fa-plus-circle mr-1"></i>Add Roll No.
                                                        </h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                            *ngIf="data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            {{(data && data['status'] && data['status'] === 'pending') ? 'Pending' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'form_received') ? 'Form Received' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'sent') ? 'Sent' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS REQUIRED') ? 'Documents Required' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS RE UPLOADED') ? 'Documents Re-Uploaded' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS UPLOADED') ? 'Documents Uploaded' : ''}}
                                                        </h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                            *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data['stage_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            {{(data && data['status'] && data['status'] === 'pending') ? 'Pending' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'form_received') ? 'Form Received' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'sent') ? 'Sent' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS REQUIRED') ? 'Documents Required' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS RE UPLOADED') ? 'Documents Re-Uploaded' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS UPLOADED') ? 'Documents Uploaded' : ''}}
                                                        </h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['status_id'] !== 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')"
                                                            (click)="changeStatusModal(content, data)">
                                                            {{(data && data['status'] && data['status'] === 'pending') ? 'Pending' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'form_received') ? 'Form Received' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'sent') ? 'Sent' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS REQUIRED') ? 'Documents Required' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS RE UPLOADED') ? 'Documents Re-Uploaded' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS UPLOADED') ? 'Documents Uploaded' : ''}}
                                                        </h6>
                                                        <h6 class="mt-1 cursor-pointer text-capitalize"
                                                            *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            Incomplete
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['remarks']">{{data['remarks']}}</h6>
                                                        <h6 class="mt-1" *ngIf="!data || !data['remarks']">-</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                            *ngIf="data && data['process_status']">
                                                            {{data['process_status']}}
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.name}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <img [src]="data['photograph'].url" class="width-40px"
                                                             *ngIf="data && data['photograph'] && data['photograph'].url"/>
                                                        <h6 *ngIf="!data || !data['photograph'] || !data['photograph'].url">
                                                            -</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <img [src]="data['signature'].url" class="width-80px"
                                                             *ngIf="data && data['signature'] && data['signature'].url"/>
                                                        <h6 *ngIf="!data || !data['signature'] || !data['photograph'].url">
                                                            -</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.father_name}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.mother_name}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && !data.course && data['register_course']">{{data?.register_course}}</h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data.course && data.branch">{{data?.course}}
                                                            ({{data?.branch}})</h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data.course && !data.branch">{{data?.course}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.email}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.phone}}</h6>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_actual">
                                                            {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_actual) ? data['business_center']['business_center_detail'].admission_percentage_actual : ''}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].admission_percentage_actual">
                                                            -</h6>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_actual">
                                                            {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_actual) ? data['business_center']['business_center_detail'].re_registration_percentage_display : ''}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].re_registration_percentage_actual">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_display">
                                                            {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_display) ? data['business_center']['business_center_detail'].admission_percentage_display : ''}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].admission_percentage_display">
                                                            -</h6>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_display">
                                                            {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_display) ? data['business_center']['business_center_detail'].re_registration_percentage_display : ''}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].re_registration_percentage_display">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['prospectus_fee'] && data['prospectus_fee'] != '-'">
                                                            {{data['prospectus_fee'] | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['prospectus_fee'] || data['prospectus_fee'] === '-'">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['pcb_mapping'] && data['pcb_mapping']['registration_amount']">
                                                            {{data?.pcb_mapping?.registration_amount | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['pcb_mapping'] || !data['pcb_mapping']['registration_amount']">
                                                            -</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1" *ngIf="data && data['chargeableCourseFee']">
                                                            {{data?.chargeableCourseFee | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1" *ngIf="!data || !data['chargeableCourseFee']">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['service_charge'] && data['service_charge'] != '-'">
                                                            {{data['service_charge'] | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['service_charge'] || data['service_charge'] === '-'">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['service_charge'] && data['examination_fee'] != '-'">
                                                            {{data['examination_fee'] | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['examination_fee'] || data['examination_fee'] === '-'">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['pcb_mapping'] && data['pcb_mapping']['re_registration_amount']">
                                                            {{data?.pcb_mapping?.re_registration_amount | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['pcb_mapping'] || !data['pcb_mapping']['re_registration_amount']">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1" *ngIf="data && data['total']">
                                                            {{data?.total | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1" *ngIf="!data || !data['total']">-
                                                        </h6>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                <ng-template #noDataToShowWeb>
                                                    <tbody>
                                                    <tr>
                                                        <td colspan="13" class="text-center"
                                                            *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                            <h6>No data to show</h6>
                                                        </td>
                                                        <td colspan="9" class="text-center"
                                                            *ngIf="['SUPER_ADMIN', 'UNIVERSITY'].indexOf(user['user_type']) !== -1">
                                                            <h6>No data to show</h6>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </ng-template>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row pagination-table mt-2">
                                        <ngb-pagination class="pagination-primary"
                                                        [collectionSize]="studentPagination.total"
                                                        [(page)]="studentPagination.page" [boundaryLinks]="true"
                                                        [pageSize]="studentPagination.perpage" [maxSize]="5"
                                                        (pageChange)="onStudentPageChange($event)">
                                            <ng-template ngbPaginationPrevious
                                            ><span [data-feather]="'chevron-left'" [size]="18"
                                                   [class]="'font-weight-bolder'"></span>
                                            </ng-template>
                                            <ng-template ngbPaginationNext
                                            ><span [data-feather]="'chevron-right'" [size]="18"
                                                   [class]="'font-weight-bolder'"></span
                                            ></ng-template>
                                        </ngb-pagination>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="navFilled" class="mt-2"></div>-->
                </div>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="detail-student"
            overlayClass="modal-backdrop">
        <app-detail-student [data]="studentDetail" (valueSubmitted)="getData($event)"></app-detail-student>
    </core-sidebar>
</div>
