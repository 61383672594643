<div class="content-wrapper">
    <div class="content-body">
        <section id="counsellors">
            <ng-template #content let-modal>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold">Update Session Percentage</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body" ngbAutoFocus>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="updateSessionPercentageForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="admission_percentage_actual">Admission Actual
                                                        (%)</label>
                                                    <input class="form-control" id="admission_percentage_actual"
                                                           formControlName="admission_percentage_actual" type="number">
                                                    <small *ngIf="submitted && updateSessionPercentageForm.get('admission_percentage_actual').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="re_registration_percentage_actual">Re - Registration
                                                        Actual (%)</label>
                                                    <input class="form-control" id="re_registration_percentage_actual"
                                                           formControlName="re_registration_percentage_actual"
                                                           type="number">
                                                    <small *ngIf="submitted && updateSessionPercentageForm.get('re_registration_percentage_actual').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="admission_percentage_display">Admission Display
                                                        (%)</label>
                                                    <input class="form-control" id="admission_percentage_display"
                                                           formControlName="admission_percentage_display" type="number">
                                                    <small *ngIf="submitted && updateSessionPercentageForm.get('admission_percentage_display').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="re_registration_percentage_display">Re - Registration
                                                        Display (%)</label>
                                                    <input class="form-control" id="re_registration_percentage_display"
                                                           formControlName="re_registration_percentage_display"
                                                           type="number">
                                                    <small *ngIf="submitted && updateSessionPercentageForm.get('re_registration_percentage_display').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="prospectus_fee">Prospectus Fee</label>
                                                    <input class="form-control" id="prospectus_fee"
                                                           formControlName="prospectus_fee"
                                                           type="number">
                                                    <!--<small *ngIf="submitted && updateSessionPercentageForm.get('prospectus_fee').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>-->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="service_charge">Service Charge</label>
                                                    <input class="form-control" id="service_charge"
                                                           formControlName="service_charge"
                                                           type="number">
                                                    <!--<small *ngIf="submitted && updateSessionPercentageForm.get('service_charge').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>-->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="examination_fee">Examination Fee</label>
                                                    <input class="form-control" id="examination_fee"
                                                           formControlName="examination_fee"
                                                           type="number">
                                                    <!--<small *ngIf="submitted && updateSessionPercentageForm.get('examination_fee').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="update()"
                                *ngIf="universitySession && universitySession['admission_percentage_actual']">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                        </button>
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="create()"
                                *ngIf="universitySession && !universitySession['admission_percentage_actual']">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Submit
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 class="mt-5px"> B - Center Share Percentage - {{businessCenter?.name}}
                        : {{university?.name}}</h2>
                    <app-content-header [contentHeader]="contentHeader"
                                        *ngIf="user && (user['user_type'] === 'SUPER_ADMIN' || user['user_type'] === 'ADMIN' || user['user_type'] === 'BUSINESS_ADMIN')"></app-content-header>
                </div>
                <!--<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <button class="btn btn-primary pull-right color-white users-btn mr-2"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white users-btn mr-2 cursor-pointer"
                            (click)="add()" *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Business Head Center
                    </button>
                </div>-->
            </div>
            <!--<div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb&#45;&#45;8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="name" placeholder="Name">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="username"
                                           placeholder="Username">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="email" placeholder="Email">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="number" formControlName="contact"
                                           placeholder="Contact">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>-->
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Session
                                                Name</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Session
                                                Year</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Student
                                                Application Id Prefix</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                Re-Registration Status</h6>
                                            </th>
                                            <th class="text-center"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Admission Actual (%)</h6>
                                            </th>
                                            <th class="text-center"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Re-Registration Actual (%)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Admission <span
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">Display</span>
                                                    (%)
                                                </h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Re-Registration
                                                    <span *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">Display</span>
                                                    (%)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Prospectus Fee</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Service Charge</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">EXamination Fee</h6>
                                            </th>
                                            <th class="text-center"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1"><h6
                                                    class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="business_university_settings && business_university_settings.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let university_setting of business_university_settings; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{university_setting?.session_name}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{university_setting?.session_year}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{university_setting?.student_application_id_prefix}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{university_setting?.re_registration_status}}</h6>
                                            </td>
                                            <td class="text-center"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                <h6 class="mt-1"
                                                    *ngIf="university_setting && university_setting['admission_percentage_actual']">{{university_setting?.admission_percentage_actual}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!university_setting || !university_setting['admission_percentage_actual']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                <h6 class="mt-1"
                                                    *ngIf="university_setting && university_setting['re_registration_percentage_actual']">{{university_setting?.re_registration_percentage_actual}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!university_setting || !university_setting['re_registration_percentage_actual'] || !university_setting['re_registration_percentage_actual']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="university_setting && university_setting['admission_percentage_display'] && university_setting['admission_percentage_display']">{{university_setting?.admission_percentage_display}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!university_setting || !university_setting['admission_percentage_display'] || !university_setting['admission_percentage_display']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="university_setting && university_setting['business_center_share_percentage'] && university_setting['re_registration_percentage_display']">{{university_setting?.re_registration_percentage_display}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!university_setting || !university_setting['business_center_share_percentage'] || !university_setting['re_registration_percentage_display']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="university_setting && university_setting['prospectus_fee'] && university_setting['prospectus_fee']">{{university_setting?.prospectus_fee}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!university_setting || !university_setting['prospectus_fee'] || !university_setting['prospectus_fee']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="university_setting && university_setting['service_charge'] && university_setting['service_charge']">{{university_setting?.service_charge}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!university_setting || !university_setting['service_charge'] || !university_setting['service_charge']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="university_setting && university_setting['examination_fee'] && university_setting['examination_fee']">{{university_setting?.examination_fee}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!university_setting || !university_setting['examination_fee'] || !university_setting['examination_fee']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center"
                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                <h6 class="mt-1 text-info cursor-pointer"
                                                    (click)="updatePercentageModal(content, university_setting)">
                                                    Update</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="8" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3 d-block d-md-none"
                 *ngIf="business_university_settings && business_university_settings.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let university_setting of business_university_settings; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-12">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{university_setting?.session_name}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Session Year - {{university_setting?.session_year}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Student Application Id Prefix
                                    - {{university_setting?.student_application_id_prefix}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Student Application Id Prefix
                                    - {{university_setting?.student_application_id_prefix}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Re-Registration Status - {{university_setting?.re_registration_status}}
                                </div>
                            </div>
                            <div class="row p-1" *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Admission Actual (%)
                                    - {{university_setting?.admission_percentage_actual}}
                                </div>
                            </div>
                            <div class="row p-1" *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Re-Rerigistration Actual (%)
                                    - {{university_setting?.re_registration_percentage_actual}}
                                </div>
                            </div>
                            <div class="row p-1" *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Admission <span
                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">Display</span> (%)
                                    - {{university_setting?.admission_percentage_display}}
                                </div>
                            </div>
                            <div class="row p-1" *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Re-Registration <span *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">Display</span>
                                    (%)
                                    - {{university_setting?.re_registration_percentage_display}}
                                </div>
                            </div>
                            <div class="row p-1" *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Prospectus Fee - {{university_setting?.prospectus_fee}}
                                </div>
                            </div>
                            <div class="row p-1" *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Service Charge - {{university_setting?.service_charge}}
                                </div>
                            </div>
                            <div class="row p-1" *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Examination Fee - {{university_setting?.examination_fee}}
                                </div>
                            </div>
                            <div class="row p-1" *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    <h6 class="mt-1 text-info cursor-pointer"
                                        (click)="updatePercentageModal(content, university_setting)">
                                        Update</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>
