import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReceiptService} from '../../../shared/services/receipt.service';
import {AlertService} from '../../../shared/services/alert.service';
import {StorageService} from '../../../shared/services/storage.service';
import {BusinessHeadCenterService} from '../../../shared/services/business-head-center.service';
import {UniversitySettingService} from '../../../shared/services/university-setting.service';
import {AppConstants} from '../../../shared/constants/app-constant';
import {BusinessCenterService} from '../../../shared/services/business-center.service';

@Component({
    selector: 'app-receipts',
    templateUrl: './receipts.component.html',
    styleUrls: ['./receipts.component.scss']
})
export class ReceiptsComponent implements OnInit {
    showSearch = false;
    receipts = [];
    id: any;
    data: any;
    addReceiptForm: FormGroup;
    addReceiptStatusForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    banks = [];
    addReceiptModal: any = false;
    receiptModalStatus: any = false;
    transactionType: any;
    bankType: any;
    fileReceiptId: any;
    onFileChanged: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };
    disableAll: any = false;
    user: any;
    receiptData: any;
    accounting: any;
    studentSessionForm: FormGroup;
    businessHeadCenterDetail: any;
    settings: any = [];
    businessCenterDetail: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private receiptService: ReceiptService,
                private alertService: AlertService,
                private businessHeadCenterService: BusinessHeadCenterService,
                private businessCenterService: BusinessCenterService,
                private universitySettingService: UniversitySettingService) {
    }

    ngOnInit(): void {
        if (this.user && this.user['user_type'] && (this.user['user_type'] !== 'ACCOUNTING' && this.user['user_type'] !== 'BUSINESS_HEAD_CENTER' && this.user['user_type'] !== 'BUSINESS_CENTER')) {
            this.router.navigateByUrl('dashboard');
        }
        this.user = StorageService.getItem('self');
        this.accounting = StorageService.getItem('accounting');
        this.studentSessionForm = this.fb.group({
            'student_session_year': ['']
        });
        if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenterDetail();
        } else if (this.user && this.user['user_type'] && this.user['user_type'] === 'ACCOUNTING') {
            this.getSettings();
        } else if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_CENTER') {
            this.getBusinessCenterDetail();
        }
        this.refresh();
    }

    refresh() {
        this.addReceiptForm = this.fb.group({
            'type': ['', Validators.required],
            'transaction_id': [''],
            'amount': ['', Validators.required],
            'bank': [''],
            'other_bank': [''],
            'date': ['', Validators.required],
            'receipt_id': [''],
            'remarks': ['']
        });
        this.addReceiptStatusForm = this.fb.group({
            'status': [''],
            'admin_remarks': ['']
        });
        this.searchForm = this.fb.group({
            'transaction_id': [''],
            'type': [''],
            'date': [''],
            'status': [''],
            'amount': [''],
            'name': [''],
            'code': [''],
        });
    }

    getBusinessHeadCenterDetail() {
        this.businessHeadCenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessHeadCenterDetail = data;
            this.getSettings();
        });
    }

    getBusinessCenterDetail() {
        if (this.user && this.user['user_type'] === 'BUSINESS_CENTER') {
            this.businessCenterService.detailById(this.user['userId']).subscribe(data => {
                this.businessCenterDetail = data;
                if (this.businessCenterDetail && this.businessCenterDetail['head_center_id'] === AppConstants.MS_HEAD_CENTER_ID) {
                    //this.creditsProcessable = (this.businessCenterDetail && this.businessCenterDetail['amount']) ? this.businessCenterDetail['amount'] : 0;
                    this.getSettings();
                }
            });
        } else if (this.user && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.businessHeadCenterService.detailById(this.user['userId']).subscribe(data => {
                this.businessHeadCenterDetail = data;
                //this.creditsProcessable = (this.businessHeadCenterDetail && this.businessHeadCenterDetail['amount']) ? this.businessHeadCenterDetail['amount'] : 0;
            });
        }
    }

    getSettings() {
        let universityId = (this.user && this.user['user_type'] && this.user['user_type'] === 'ACCOUNTING') ? AppConstants.GYAN_VIHAR_UNINVERSITY_ID : (this.user['user_type'] === 'BUSINESS_CENTER' ? this.businessCenterDetail['business_admin_detail']['university_id'] : (this.businessHeadCenterDetail['business_admin_detail']['university_id']));
        this.universitySettingService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.settings = data['data'];
                this.patchSettingForm();
            } else {
                this.settings = [];
            }
        });
    }

    patchSettingForm() {
        if (this.settings && this.settings.length > 0) {
            let selectedSetting: any;
            this.settings.forEach(setting => {
                if (setting && setting['session_default'] && setting['session_default'] === 'ACTIVE') {
                    selectedSetting = setting;
                }
            });
            this.studentSessionForm.patchValue({
                'student_session_year': selectedSetting.id
            });
            if (this.user && this.user['user_type'] && this.user['user_type'] !== 'ACCOUNTING') {
                if (!this.accounting) {
                    this.router.navigateByUrl('accounting');
                } else {
                    this.getBanks();
                }
            } else {
                this.getBanks();
            }
        }
    }

    add(content): void {
        this.addReceiptModal = true;
        this.modalService.open(content);
    }

    getBanks() {
        this.receiptService.getBanks({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.banks = data['data'];
                this.get();
            } else {
                this.receipts = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
        this.get();
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        params.setting_id = (this.studentSessionForm && this.studentSessionForm.value) ? this.studentSessionForm.value.student_session_year : '';
        this.submitting = true;
        this.receipts = [];
        this.receiptService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.receipts = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.receipts = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'transaction_id': (this.searchForm.value && this.searchForm.value.transaction_id) ? this.searchForm.value.transaction_id : '',
            'type': (this.searchForm.value && this.searchForm.value.type) ? this.searchForm.value.type : '',
            'date': (this.searchForm.value && this.searchForm.value.date) ? this.searchForm.value.date : '',
            'status': (this.searchForm.value && this.searchForm.value.status) ? this.searchForm.value.status : '',
            'amount': (this.searchForm.value && this.searchForm.value.amount) ? this.searchForm.value.amount : '',
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'code': (this.searchForm.value && this.searchForm.value.code) ? this.searchForm.value.code : '',
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'transaction_id': (this.searchForm.value && this.searchForm.value.transaction_id) ? this.searchForm.value.transaction_id : '',
            'type': (this.searchForm.value && this.searchForm.value.type) ? this.searchForm.value.type : '',
            'date': (this.searchForm.value && this.searchForm.value.date) ? this.searchForm.value.date : '',
            'status': (this.searchForm.value && this.searchForm.value.status) ? this.searchForm.value.status : '',
            'amount': (this.searchForm.value && this.searchForm.value.amount) ? this.searchForm.value.amount : '',
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'code': (this.searchForm.value && this.searchForm.value.code) ? this.searchForm.value.code : '',
        };
        this.get(params);
    }

    addReceipt() {
        this.submitted = true;
        if (!this.addReceiptForm.valid) {
            return;
        }
        let errorCount = 0;
        if (this.addReceiptForm && this.addReceiptForm.value && this.transactionType !== 'CASH') {
            if (!this.addReceiptForm.value['transaction_id'] || this.addReceiptForm.value['transaction_id'] === '') {
                this.alertService.showErrors('Transaction id can\'t be blank');
                errorCount++;
                return;
            }

            if (!this.addReceiptForm.value['bank'] || this.addReceiptForm.value['bank'] === '') {
                this.alertService.showErrors('Bank can\'t be blank');
                errorCount++;
                return;
            }

            if (this.addReceiptForm.value['bank'] && this.bankType === 'OTHER_BANK' && (this.addReceiptForm.value['other_bank'] === '' || !this.addReceiptForm.value['other_bank'])) {
                this.alertService.showErrors('Other bank can\'t be blank');
                errorCount++;
                return;
            }

            if (!this.addReceiptForm.value['receipt_id'] || this.addReceiptForm.value['receipt_id'] === '') {
                this.alertService.showErrors('Transaction receipt can\'t be blank');
                errorCount++;
                return;
            }
        }
        if (this.submitted && !errorCount) {
            let params = {
                'setting_id': (this.studentSessionForm && this.studentSessionForm.value && this.studentSessionForm.value.student_session_year) ? this.studentSessionForm.value.student_session_year : '',
                'type': (this.addReceiptForm && this.addReceiptForm.value) ? this.addReceiptForm.value['type'] : '',
                'transaction_id': (this.addReceiptForm && this.addReceiptForm.value && this.transactionType !== 'CASH') ? this.addReceiptForm.value['transaction_id'] : '',
                'date': (this.addReceiptForm && this.addReceiptForm.value) ? this.addReceiptForm.value['date'] : '',
                'amount': (this.addReceiptForm && this.addReceiptForm.value) ? this.addReceiptForm.value['amount'] : '',
                'bank': (this.addReceiptForm && this.addReceiptForm.value && this.addReceiptForm.value['bank'] !== 'OTHER_BANK') ? this.addReceiptForm.value['bank'] : ((this.addReceiptForm && this.addReceiptForm.value && this.addReceiptForm.value['other_bank']) ? this.addReceiptForm.value['other_bank'] : ''),
                'receipt_id': (this.addReceiptForm && this.addReceiptForm.value && this.transactionType !== 'CASH') ? this.addReceiptForm.value['receipt_id'] : '',
                'remarks': (this.addReceiptForm && this.addReceiptForm.value) ? this.addReceiptForm.value['remarks'] : '',
            };
            this.receiptService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.get();
                    this.addReceiptForm.patchValue({
                        'type': '',
                        'transaction_id': '',
                        'date': '',
                        'amount': '',
                        'bank': '',
                        'receipt_id': '',
                        'remarks': ''
                    });
                    this.modalService.dismissAll('');
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    chooseType(event) {
        this.bankType = undefined;
        this.transactionType = event.target.value;
        if (this.transactionType === 'CASH') {
            this.addReceiptForm.patchValue({
                'transaction_id': '',
                'bank': '',
                'other_bank': '',
                'receipt_id': '',
            });
            this.onFileChanged = undefined;
            this.fileReceiptId = undefined;
        }
    }

    checkBank(event) {
        this.bankType = event.target.value;
    }

    onFileChange(e, type) {
        this.onFileChanged = e;
        this.receiptService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'TRANSACTION_RECEIPT') {
                this.fileReceiptId = data['data'];
                this.addReceiptForm.patchValue({
                    'receipt_id': this.fileReceiptId.id
                });
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    openReceiptId() {
        window.open(this.fileReceiptId['url'], '_blank');
    }

    viewReceipt(url) {
        window.open(url, '_blank');
    }

    openReceiptModal(content, data) {
        this.addReceiptModal = false;
        this.receiptModalStatus = true;
        this.modalService.open(content, {'size': 'sm'});
        this.receiptData = data;
    }

    receiptStatus() {
        this.submitted = true;
        if (!this.addReceiptStatusForm.valid) {
            return;
        }
        if (this.submitted) {
            let params = {
                'status': (this.addReceiptStatusForm && this.addReceiptStatusForm.value) ? this.addReceiptStatusForm.value['status'] : '',
                'center_id': (this.receiptData && this.receiptData['user_id']) ? this.receiptData['user_id'] : '',
                'admin_remarks': (this.addReceiptStatusForm && this.addReceiptStatusForm.value) ? this.addReceiptStatusForm.value['admin_remarks'] : '',
            };
            this.receiptService.updateStatus(this.receiptData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.addReceiptStatusForm.patchValue({
                    'status': '',
                    'admin_remarks': ''
                });
                this.get();
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    goToAccounting() {
        this.router.navigateByUrl('accounting');
    }

    changeSettings(event) {
        if (event && event.target && event.target.value && this.settings && this.settings.length > 0) {
            let selectedSetting = {};
            this.settings.forEach(session => {
                if (parseInt(event.target.value) === session.id) {
                    selectedSetting = session;
                }
            });
            this.searchForm.patchValue({
                'transaction_id': '',
                'type': '',
                'date': '',
                'status': '',
                'amount': '',
                'name': '',
                'code': '',
            });
            this.getBanks();
        }
    }
}
