<div class="content-wrapper">
    <div class="content-body">
        <section id="admins">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="addReceiptModal">Add Receipt</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="receiptModalStatus">Receipt Status -
                            &#8377; {{receiptData?.amount}}</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row" *ngIf="addReceiptModal">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="addReceiptForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="type">Type</label>
                                                    <select class="form-control" formControlName="type" id="type"
                                                            (change)="chooseType($event)">
                                                        <option value="">Select Type</option>
                                                        <option value="NEFT">NEFT</option>
                                                        <option value="RTGS">RTGS</option>
                                                        <option value="DD">DD</option>
                                                        <option value="IMPS">IMPS</option>
                                                        <option value="UPI">UPI</option>
                                                        <option value="CHEQUE">CHEQUE</option>
                                                        <option value="CASH">CASH</option>
                                                    </select>
                                                    <small *ngIf="submitted && addReceiptForm.get('type').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="transactionType !== 'CASH'">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="transaction_id">Transaction Id</label>
                                                    <input class="form-control" placeholder="Transaction Id"
                                                           formControlName="transaction_id" id="transaction_id"/>
                                                    <small *ngIf="submitted && addReceiptForm.get('transaction_id').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="date">Date</label>
                                                    <input class="form-control" placeholder="Date"
                                                           formControlName="date" id="date" type="date"/>
                                                    <small *ngIf="submitted && addReceiptForm.get('date').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="amount">Amount</label>
                                                    <input class="form-control" placeholder="Amount"
                                                           formControlName="amount" id="amount" type="number"/>
                                                    <small *ngIf="submitted && addReceiptForm.get('amount').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="transactionType !== 'CASH'">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="bank">Bank</label>
                                                    <select class="form-control" formControlName="bank" id="bank"
                                                            (change)="checkBank($event)">
                                                        <option value="">Select Bank</option>
                                                        <option [value]="bank"
                                                                *ngFor="let bank of banks">{{bank}}</option>
                                                        <option value="OTHER_BANK">Other Bank</option>
                                                    </select>
                                                    <small *ngIf="submitted && addReceiptForm.get('bank').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row"
                                             *ngIf="transactionType !== 'CASH' && bankType === 'OTHER_BANK'">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="other_bank">Other Bank Name</label>
                                                    <input class="form-control" formControlName="other_bank"
                                                           id="other_bank"/>
                                                    <small *ngIf="submitted && addReceiptForm.get('other_bank').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row"
                                             *ngIf="transactionType !== 'CASH'">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="other_bank">Upload Transaction Receipt</label>
                                                    <br>
                                                    <button class="btn btn-primary color-white"
                                                            (click)="fileUploadReceipt.click()" *ngIf="!disableAll">
                                                        <i class="fas fa-cloud-upload-alt mr-1"></i>Upload
                                                    </button>
                                                    <button class="btn btn-secondary color-white" *ngIf="disableAll">
                                                        <i class="fas fa-cloud-upload-alt mr-1"></i>Upload
                                                    </button>
                                                    <span class="text-info cursor-pointer ml-1"
                                                          (click)="openReceiptId()"
                                                          *ngIf="fileReceiptId">View</span>
                                                    <input id="receipt_id" formControlName="receipt_id"
                                                           class="form-control-file display-hidden" type="text">
                                                    <input class="form-control-file display-hidden" type="file"
                                                           (change)="onFileChange($event, 'TRANSACTION_RECEIPT')"
                                                           #fileUploadReceipt>
                                                    <small *ngIf="submitted && addReceiptForm.get('receipt_id').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="remark">Remark</label>
                                                    <textarea class="form-control resize-none" placeholder="Remark"
                                                              formControlName="remarks" id="remark"></textarea>
                                                    <small *ngIf="submitted && addReceiptForm.get('remarks').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row" *ngIf="receiptModalStatus">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="addReceiptStatusForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="type">Status</label>
                                                    <select class="form-control" formControlName="status" id="status">
                                                        <option value="">Select Type</option>
                                                        <option value="PENDING">PENDING</option>
                                                        <option value="ACCEPTED">ACCEPTED</option>
                                                        <option value="REJECTED">REJECTED</option>
                                                    </select>
                                                    <small *ngIf="submitted && addReceiptStatusForm.get('status').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="type">Remarks</label>
                                                    <textarea class="form-control resize-none" formControlName="admin_remarks" id="admin_remarks"></textarea>
                                                    <small *ngIf="submitted && addReceiptStatusForm.get('admin_remarks').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success color-white pull-right mt-1 mb-1" [disabled]="submitting"
                                (click)="addReceipt()" *ngIf="addReceiptModal">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Submit
                        </button>
                        <button class="btn btn-success color-white pull-right mt-1 mb-1" [disabled]="submitting"
                                (click)="receiptStatus()" *ngIf="receiptModalStatus">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Submit
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Receipts</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn cursor-pointer"
                            (click)="goToAccounting()"
                            *ngIf="user && ['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                        <i aria-hidden="true" class="fa fa-calculator mr-8px"></i>&nbsp;Accounting
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add(content)"
                            *ngIf="user && (user['user_type'] === 'BUSINESS_HEAD_CENTER' || user['user_type'] === 'BUSINESS_CENTER')">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Receipt
                    </button>
                </div>
            </div>
            <div class="row mt-17px">
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <form class="row" novalidate [formGroup]="studentSessionForm">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <select class="form-control" formControlName="student_session_year"
                                    (change)="changeSettings($event)">
                                <option class="text-center" [value]="setting.id"
                                        *ngFor=" let setting of settings">{{setting?.session_name}}
                                    - {{setting?.session_year}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="transaction_id"
                                           placeholder="Transaction Id">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="type">
                                        <option value="">Select Type</option>
                                        <option value="NEFT">NEFT</option>
                                        <option value="RTGS">RTGS</option>
                                        <option value="DD">DD</option>
                                        <option value="IMPS">IMPS</option>
                                        <option value="UPI">UPI</option>
                                        <option value="CHEQUE">CHEQUE</option>
                                        <option value="CASH">CASH</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="date" formControlName="date" placeholder="Date">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="number" formControlName="amount"
                                           placeholder="Amount">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="status">
                                        <option value="">Select Status</option>
                                        <option value="ACCEPTED">ACCEPTED</option>
                                        <option value="REJECTED">REJECTED</option>
                                        <option value="PENDING">PENDING</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="name"
                                           placeholder="Center Name">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="code"
                                           placeholder="Center Code">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Transaction
                                                Id</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Session</h6>
                                            </th>
                                            <th class="text-center min-width-200px max-width-200px"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center min-width-200px max-width-200px"><h6 class="mt-1 mb-1 font-weight-bold">Code</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Type</h6>
                                            </th>
                                            <th class="text-center min-width-200px max-width-200px"><h6 class="mt-1 mb-1 font-weight-bold">Date</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Amount</h6>
                                            </th>
                                            <th class="text-center min-width-200px max-width-200px"><h6 class="mt-1 mb-1 font-weight-bold">Bank</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Receipt</h6>
                                            </th>
                                            <th class="text-center min-width-200px max-width-200px"><h6 class="mt-1 mb-1 font-weight-bold">Remark</h6>
                                            </th>
                                            <th class="text-center min-width-200px max-width-200px"><h6 class="mt-1 mb-1 font-weight-bold">Admin Remarks</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="receipts && receipts.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let receipt of receipts; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="receipt && receipt['status'] && user && user['user_type'] !== 'ACCOUNTING'">
                                                    {{receipt?.status}}
                                                </h6>
                                                <h6 class="mt-1 text-info cursor-pointer text-capitalize"
                                                    *ngIf="receipt && receipt['status'] && user && user['user_type'] === 'ACCOUNTING' && receipt['status'] === 'PENDING'"
                                                    (click)="openReceiptModal(content, receipt)">
                                                    {{receipt?.status.toLowerCase()}}
                                                </h6>
                                                <h6 class="mt-1 text-info cursor-pointer text-capitalize"
                                                    *ngIf="receipt && receipt['status'] && user && user['user_type'] === 'ACCOUNTING' && receipt['status'] !== 'PENDING'"
                                                    (click)="openReceiptModal(content, receipt)">
                                                    {{receipt?.status.toLowerCase()}}
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="receipt && receipt['transaction_id']">{{receipt?.transaction_id}}</h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['transaction_id']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="receipt && receipt['setting_id']"
                                                    style="max-width: 200px; min-width: 200px">
                                                    {{receipt?.setting?.session_name}}
                                                    - {{receipt?.setting?.session_year}}
                                                </h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['setting_id']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="receipt && receipt['name']">{{receipt?.name}}</h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['name']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="receipt && receipt['code']">{{receipt?.code}}</h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['code']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="receipt && receipt['type']">{{receipt?.type}}</h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['type']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="receipt && receipt['date']">{{receipt?.date}}</h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['date']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="receipt && receipt['amount']">
                                                    &#8377; {{receipt?.amount}}</h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['amount']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="receipt && receipt['bank']">{{receipt?.bank}}</h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['bank']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1 text-primary cursor-pointer"
                                                    *ngIf="receipt && receipt['receipt'] && receipt['receipt']['url']"
                                                    (click)="viewReceipt(receipt['receipt']['url'])">
                                                    View
                                                </h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['bank']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="receipt && receipt['remark']">{{receipt?.remark}}</h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['remark']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="receipt && receipt['admin_remarks']">{{receipt?.admin_remarks}}</h6>
                                                <h6 class="mt-1" *ngIf="!receipt || !receipt['admin_remarks']">-</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="13" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none" *ngIf="receipts && receipts.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let receipt of receipts; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-12">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . &#8377; {{receipt?.amount}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Transaction Id - {{receipt?.transaction_id}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Name - {{receipt?.name}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Code - {{receipt?.code}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Type - {{receipt?.type}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Date - {{receipt?.date}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Bank - {{receipt?.bank}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Remark - {{receipt?.remark}}
                                </div>
                                <div class="">
                                    Receipt - <span class="text-primary cursor-pointer"
                                                    *ngIf="receipt && receipt['receipt'] && receipt['receipt']['url']"
                                                    (click)="viewReceipt(receipt['receipt']['url'])">View</span>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Status -
                                    <span class="mt-1 text-info cursor-pointer"
                                          *ngIf="receipt && receipt['status'] && user && user['user_type'] === 'ACCOUNTING' && receipt['status'] === 'PENDING'"
                                          (click)="openReceiptModal(content, receipt)">
                                        {{receipt?.status.toLowerCase()}}
                                    </span>
                                    <span class="mt-1 text-info cursor-pointer"
                                          *ngIf="receipt && receipt['status'] && user && user['user_type'] === 'ACCOUNTING' && receipt['status'] !== 'PENDING'"
                                          (click)="openReceiptModal(content, receipt)">
                                        {{receipt?.status.toLowerCase()}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination class="pagination-primary" [collectionSize]="pagination.total"
                                [(page)]="pagination.page" [boundaryLinks]="true"
                                [pageSize]="pagination.perpage" [maxSize]="5"
                                (pageChange)="onPageChange($event)">
                    <ng-template ngbPaginationPrevious>
                        <span [data-feather]="'chevron-left'" [size]="18"
                              [class]="'font-weight-bolder'">
                        </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <span [data-feather]="'chevron-right'" [size]="18"
                              [class]="'font-weight-bolder'">
                        </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>
