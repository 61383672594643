<section id="admission-form">
    <ng-template #content let-modal>
        <div class="landing-modal">
            <div class="modal-header">
                <h5 class="font-weight-400" *ngIf="uploadPhotographModal">
                    <i class="fab fa-pagelines cursor-pointer mr-2"></i>
                    <span>Update Student Photograph</span>
                </h5>
                <h5 class="font-weight-400" *ngIf="uploadSignatureModal">
                    <i class="fab fa-pagelines cursor-pointer mr-2"></i>
                    <span>Update Student Signature</span>
                </h5>
                <h5 class="font-weight-400" *ngIf="updateCourseModal">
                    <i class="fab fa-pagelines cursor-pointer mr-2"></i>
                    <span>Update Course</span>
                </h5>
                <i class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"
                   (click)="this.modalService.dismissAll('')"></i>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="uploadPhotographModal">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row" *ngIf="imageChangedEvent">
                            <div class="text-center col-md-12">
                                <image-cropper
                                        [imageChangedEvent]="imageChangedEvent"
                                        [maintainAspectRatio]="true"
                                        [aspectRatio]="20 / 20"
                                        [resizeToWidth]="200"
                                        format="png, jpeg, jpg"
                                        (imageCropped)="imageCropped($event)"
                                        (imageLoaded)="imageLoaded()"
                                        (cropperReady)="cropperReady()"
                                        (loadImageFailed)="loadImageFailed()">
                                </image-cropper>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="text-center mt-2 mb-2">
                                    <img [src]="photographImageUploadArea" class="img-fluid width-85-per" alt="Slide 1"
                                         (click)="imageProfileUpload.click()" *ngIf="!imageChangedEvent"/>
                                    <input class="form-control-file display-hidden" type="file"
                                           (change)="onImageChange($event)" #imageProfileUpload>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                <button class="btn btn-primary btn-raised mt-1 mb-1 pull-right" type="button"
                                        *ngIf="imageChangedEvent" (click)="savePhotograph()">
                                    <i class="fa fa-save mr-1"></i> Save
                                </button>
                                <button class="btn btn-danger btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                        (click)="imageProfileUpload.click()">New Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="uploadSignatureModal">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row" *ngIf="imageChangedEvent">
                            <div class="text-center col-md-12">
                                <image-cropper
                                        [imageChangedEvent]="imageChangedEvent"
                                        [maintainAspectRatio]="true"
                                        [aspectRatio]="300 / 100"
                                        [resizeToWidth]="100"
                                        format="png, jpeg, jpg"
                                        (imageCropped)="imageCropped($event)"
                                        (imageLoaded)="imageLoaded()"
                                        (cropperReady)="cropperReady()"
                                        (loadImageFailed)="loadImageFailed()">
                                </image-cropper>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="text-center mt-2 mb-1">
                                    <img [src]="signatureImageUploadArea" class="img-fluid width-85-per"
                                         alt="Signature Image"
                                         (click)="imageSignatureUpload.click()" *ngIf="!imageChangedEvent"/>
                                    <input class="form-control-file display-hidden" type="file"
                                           (change)="onImageChange($event)" #imageSignatureUpload>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                <button class="btn btn-primary btn-raised mt-1 mb-1 pull-right" type="button"
                                        *ngIf="imageChangedEvent" (click)="saveSignature()">
                                    <i class="fa fa-save mr-1"></i> Save
                                </button>
                                <button class="btn btn-danger btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                        (click)="imageSignatureUpload.click()">New Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ml-1 mr-1" *ngIf="updateCourseModal">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form class="form" novalidate [formGroup]="updateCourseForm">
                            <div class="row mt-1">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="program_id">Program</label>
                                        <select id="program_id" class="form-control" formControlName="program_id"
                                                (change)="getStudenProgramCourses($event)">
                                            <option value="">Choose Program</option>
                                            <option [value]="studentProgram?.id"
                                                    *ngFor="let studentProgram of studentPrograms">{{studentProgram?.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && updateCourseForm.get('program_id').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-12">
                                    <div class="form-group mb-2">
                                        <select class="form-control" formControlName="course_id"
                                                (change)="getStudentCourseBranch($event)">
                                            <option value="">Choose Course</option>
                                            <option [value]="studentCourse?.course_id"
                                                    *ngFor="let studentCourse of studentCourses">{{studentCourse?.course?.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && updateCourseForm.get('course_id').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-12" *ngIf="studentBranches && studentBranches.length > 0">
                                    <div class="form-group">
                                        <select class="form-control" formControlName="branch_id">
                                            <option value="">Choose Branch</option>
                                            <option [value]="studentBranch?.branch?.id"
                                                    *ngFor="let studentBranch of studentBranches">{{studentBranch?.branch?.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                        (click)="updateCourse()" *ngIf="updateCourseModal">
                    Update
                </button>
            </div>
        </div>
    </ng-template>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header shadow-bottom-light">
                                    <h2 class="card-title font-weight-bold">Online Admission Form </h2>
                                    <i class="fas fa-print text-primary pull-right f_20 cursor-pointer"
                                       title="Print Form"
                                       (click)="downloadForm()"
                                       *ngIf="admissionFormDetail && admissionFormDetail['father_name'] && admissionFormDetail['mother_name'] && admissionFormDetail['student_name'] && admissionFormDetail['photograph']"></i>
                                </div>
                                <div class="card-body">
                                    <div class="row mt-1">
                                        <div class="col-12 text-center">
                                            <i class="fas fa-user-circle font-large-5 font-weight cursor-pointer"
                                               (click)="openUploadModel(content)"
                                               title="Click here to upload photograph"
                                               *ngIf="!photographImage && user['user_type'] !== 'SUPER_ADMIN'"></i>
                                            <img [src]="photographImage" class="cursor-pointer profile-image shadow-5px"
                                                 *ngIf="photographImage && user['user_type'] !== 'SUPER_ADMIN' && ['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER', 'BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1"
                                                 (click)="openUploadModel(content)" style="max-width: 200px"/>
                                            <img src="./assets/images/image-backgrounds/1600-1600.jpg"
                                                 class="cursor-pointer profile-image shadow-5px"
                                                 *ngIf="user['user_type'] === 'SUPER_ADMIN'"/>
                                        </div>
                                        <div class="col-12 text-center">
                                            <h6 class="mt-1">Student Photograph</h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <form class="form" novalidate
                                                              [formGroup]="studentAdmissionForm">
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="application-number">Application
                                                                            Number</label>
                                                                        <input type="text" id="application-number"
                                                                               class="form-control"
                                                                               formControlName="application_number">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('application_number').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">Admission
                                                                            Session</label>
                                                                        <select class="form-control"
                                                                                id="admission-session"
                                                                                formControlName="admission_session">
                                                                            <option value="">Select Admission Session
                                                                            </option>
                                                                            <option [value]="studentSessionYear.id"
                                                                                    *ngFor="let studentSessionYear of studentSessionYears">{{studentSessionYear?.session_name}}
                                                                                - {{studentSessionYear?.session_year}}</option>
                                                                        </select>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('admission_session').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">Admission Type</label>
                                                                        <select class="form-control" id="admission-type"
                                                                                formControlName="admission_type"
                                                                                (change)="changeStudentYear($event)">
                                                                            <option value="">Select Admission Type
                                                                            </option>
                                                                            <option [value]="stage.id"
                                                                                    *ngFor="let stage of stages">{{stage?.name}}</option>
                                                                        </select>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('admission_type').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">Course (Applying For)
                                                                            <span class="text-info f_13 cursor-pointer"
                                                                                  (click)="editCourse(content)">Edit</span></label>
                                                                        <select class="form-control" id="course"
                                                                                formControlName="course_id">
                                                                            <option value="">Select Course</option>
                                                                            <option [value]="course.id"
                                                                                    *ngFor="let course of courses">{{course?.name}}</option>
                                                                        </select>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('course_id').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">Year</label>
                                                                        <select class="form-control" id="year"
                                                                                formControlName="year">
                                                                            <option value="">Select Year</option>
                                                                            <option [value]="mappingYear.id"
                                                                                    *ngFor="let mappingYear of mappingYears">{{mappingYear?.name}}</option>
                                                                            <!--<option value="1">1st Year</option>
                                                                            <option value="2">1Ind Year</option>
                                                                            <option value="3">1IIrd Year</option>
                                                                            <option value="4">1Vth Year</option>-->
                                                                        </select>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('year').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="student-name">Student Name</label>
                                                                        <input type="text" id="student-name"
                                                                               class="form-control"
                                                                               formControlName="student_name">
                                                                        <span *ngIf="studentNameHindi" class="ml-5px">
                                                                            {{studentNameHindi}}
                                                                        </span>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('student_name').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <!--<div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="student-name-hindi">Student Name (in
                                                                            Hindi)</label>
                                                                        <input type="text" id="student-name-hindi"
                                                                               class="form-control"
                                                                               formControlName="student_name_hindi">
                                                                        <small>Change the value of this field only in
                                                                            case of correction</small><span
                                                                            class="text-danger">*</span>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('student_name_hindi').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>-->
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="father-name">Father's Name</label>
                                                                        <input type="text" id="father-name"
                                                                               class="form-control"
                                                                               formControlName="father_name">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('father_name').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <!--<div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="father-name-hindi">Father's Name (in
                                                                            Hindi)</label>
                                                                        <input type="text" id="father-name-hindi"
                                                                               class="form-control"
                                                                               formControlName="father_name_hindi">
                                                                        <small>Change the value of this field only in
                                                                            case of correction</small><span
                                                                            class="text-danger">*</span>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('father_name_hindi').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>-->
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="mother-name">Mother's Name</label>
                                                                        <input type="text" id="mother-name"
                                                                               class="form-control"
                                                                               formControlName="mother_name">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('mother_name').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <!--<div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="mother-name-hindi">Mother's Name (in
                                                                            Hindi)</label>
                                                                        <input type="text" id="mother-name-hindi"
                                                                               class="form-control"
                                                                               formControlName="mother_name_hindi">
                                                                        <small>Change the value of this field only in
                                                                            case of correction</small><span
                                                                            class="text-danger">*</span>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('mother_name_hindi').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>-->
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">Gender</label>
                                                                        <select class="form-control" id="gender"
                                                                                formControlName="gender">
                                                                            <option value="">Select Gender</option>
                                                                            <option value="MALE">Male</option>
                                                                            <option value="FEMALE">Female</option>
                                                                            <option value="OTHER">Other</option>
                                                                        </select>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('gender').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="date_of_birth">Date Of Birth</label>
                                                                        <input type="date" id="date_of_birth"
                                                                               class="form-control"
                                                                               formControlName="date_of_birth">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('date_of_birth').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="address">Parmanent Address</label>
                                                                        <textarea id="address"
                                                                                  class="form-control resize-none"
                                                                                  formControlName="address"></textarea>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('address').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">State</label>
                                                                        <select class="form-control" id="state"
                                                                                formControlName="state_id">
                                                                            <option value="">Select state</option>
                                                                            <option [value]="state.id"
                                                                                    *ngFor="let state of states">{{state?.name}}</option>
                                                                        </select>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('state_id').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">City / Town</label>
                                                                        <input class="form-control" id="city"
                                                                               formControlName="city"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('city').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="pincode">Pin Code</label>
                                                                        <input type="number" id="pincode"
                                                                               class="form-control"
                                                                               formControlName="pin_code">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('pin_code').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="contact">Contact</label>
                                                                        <input type="number" id="contact"
                                                                               class="form-control"
                                                                               formControlName="contact">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('contact').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="email">Email</label>
                                                                        <input type="text" id="email"
                                                                               class="form-control"
                                                                               formControlName="email">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('email').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="govt_photo_id">Govt. Id No.
                                                                            (eg. 265385644663)</label>
                                                                        <input type="text" id="govt_photo_id"
                                                                               class="form-control"
                                                                               formControlName="govt_photo_id">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('govt_photo_id').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                        <small *ngIf="submitted && studentAdmissionForm && studentAdmissionForm.controls && studentAdmissionForm.controls['govt_photo_id'] && studentAdmissionForm.controls['govt_photo_id'].errors && studentAdmissionForm.controls['govt_photo_id'].errors.pattern"
                                                                               class="text-danger">Invalid Aadhar card
                                                                            number.</small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group"
                                                                         *ngIf="['BUSINESS_CENTER', 'BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                                        <label>Upload Govt. Photo Id</label>
                                                                    </div>
                                                                    <div class="form-group"
                                                                         *ngIf="['BUSINESS_HEAD_CENTER', 'SUPERADMIN'].indexOf(user['user_type']) !== -1">
                                                                        <label>Uploaded Govt. Photo Id</label>
                                                                    </div>
                                                                    <button class="btn btn-primary color-white mt--15px"
                                                                            (click)="fileUploadGovtIdProof.click()"
                                                                            *ngIf="!disableAll && ['BUSINESS_CENTER', 'BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                                    </button>
                                                                    <input class="form-control-file display-hidden"
                                                                           type="file"
                                                                           (change)="onFileChange($event, 'GOVT_PHOTO_ID_PROOF')"
                                                                           #fileUploadGovtIdProof>
                                                                    <button class="btn btn-primary color-white ml-1 mt--15px"
                                                                            *ngIf="fileGovtPhotoIdProof && fileGovtPhotoIdProof['url']"
                                                                            (click)="openUrl(fileGovtPhotoIdProof['url'])">
                                                                        <i class="fas fa-eye"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">Nationality</label>
                                                                        <select class="form-control" id="nationality"
                                                                                formControlName="nationality">
                                                                            <option value="">Select Nationality</option>
                                                                            <option value="INDIAN">Indian</option>
                                                                        </select>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('nationality').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">Religion</label>
                                                                        <select class="form-control" id="religion"
                                                                                formControlName="religion">
                                                                            <option value="">Select Religion</option>
                                                                            <option value="HINDUISM">Hinduism</option>
                                                                            <option value="SIKHISM">Sikhism</option>
                                                                            <option value="ISLAM">Islam</option>
                                                                            <option value="CHRISTIANITY">Christianity
                                                                            </option>
                                                                            <option value="OTHERS">Others</option>
                                                                        </select>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('religion').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">Category</label>
                                                                        <select class="form-control" id="category"
                                                                                formControlName="category">
                                                                            <option value="">Select Category</option>
                                                                            <option value="GENERAL">General</option>
                                                                            <option value="OBC">OBC</option>
                                                                            <option value="SC">SC</option>
                                                                            <option value="ST">ST</option>
                                                                            <option value="OTHERS">Others</option>
                                                                        </select>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('category').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label class="form-label">Employment
                                                                            Status</label>
                                                                        <select class="form-control"
                                                                                id="employment-status"
                                                                                formControlName="employment_status">
                                                                            <option value="">Select Employment Status
                                                                            </option>
                                                                            <option value="PRIVATE_JOB">Private Job
                                                                            </option>
                                                                            <option value="GOVERNMENT_JOB">Government
                                                                                Job
                                                                            </option>
                                                                            <option value="STUDENT">Student</option>
                                                                            <option value="UN_EMPLOYED">Un-Employed
                                                                            </option>
                                                                        </select>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('employment_status').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-header shadow-bottom-light">
                                                                <h2 class="card-title font-weight-bold ml--20px">Details
                                                                    Of
                                                                    Educational
                                                                    Qualifications (from matriculation onwards):</h2>
                                                            </div>
                                                            <table class="table shadow-complete-light">
                                                                <thead>
                                                                <th class="text-center">Examination</th>
                                                                <th class="text-center">Subject</th>
                                                                <th class="text-center">Year of Passing</th>
                                                                <th class="text-center">University / Board</th>
                                                                <th class="text-center">Division / Grade</th>
                                                                <th class="text-center">Upload Documents</th>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="tenth_examination"
                                                                               placeholder="10th"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('tenth_examination').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="tenth_subject">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('tenth_subject').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" class="form-control"
                                                                               formControlName="tenth_passing_year">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('tenth_passing_year').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="tenth_board">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('tenth_board').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="tenth_grade">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('tenth_grade').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <button class="btn btn-primary color-white"
                                                                                (click)="fileUploadTenthCertificate.click()"
                                                                                *ngIf="!disableAll && ['BUSINESS_CENTER', 'BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                                            <i class="fas fa-cloud-upload-alt"></i>
                                                                        </button>
                                                                        <input class="form-control-file display-hidden"
                                                                               type="file"
                                                                               (change)="onFileChange($event, 'TENTH_CERTIFICATE')"
                                                                               #fileUploadTenthCertificate>
                                                                        <button class="btn btn-primary color-white ml-1"
                                                                                *ngIf="fileTenthCertificate && fileTenthCertificate['url']"
                                                                                (click)="openUrl(fileTenthCertificate['url'])">
                                                                            <i class="fas fa-eye"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="twelth_examination"
                                                                               placeholder="12th"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('twelth_examination').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="twelth_subject"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('twelth_subject').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" class="form-control"
                                                                               formControlName="twelth_passing_year">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('twelth_passing_year').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="twelth_board"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('twelth_board').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="twelth_grade"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('twelth_grade').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <button class="btn btn-primary color-white"
                                                                                (click)="fileUploadTwelthCertificate.click()"
                                                                                *ngIf="!disableAll && ['BUSINESS_CENTER', 'BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                                            <i class="fas fa-cloud-upload-alt"></i>
                                                                        </button>
                                                                        <input class="form-control-file display-hidden"
                                                                               type="file"
                                                                               (change)="onFileChange($event, 'TWELTH_CERTIFICATE')"
                                                                               #fileUploadTwelthCertificate>
                                                                        <button class="btn btn-primary color-white ml-1"
                                                                                *ngIf="fileTwelthCertificate && fileTwelthCertificate['url']"
                                                                                (click)="openUrl(fileTwelthCertificate['url'])">
                                                                            <i class="fas fa-eye"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="text"
                                                                               class="form-control"
                                                                               formControlName="graduate_examination"
                                                                               placeholder="UG"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('graduate_examination').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="graduate_subject"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('graduate_subject').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" class="form-control"
                                                                               formControlName="graduate_passing_year"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('graduate_passing_year').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="graduate_board"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('graduate_board').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="graduate_grade"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('graduate_grade').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <button class="btn btn-primary color-white"
                                                                                (click)="fileUploadGraduateCertificate.click()"
                                                                                *ngIf="!disableAll && ['BUSINESS_CENTER', 'BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                                            <i class="fas fa-cloud-upload-alt"></i>
                                                                        </button>
                                                                        <input class="form-control-file display-hidden"
                                                                               type="file"
                                                                               (change)="onFileChange($event, 'GRADUATE_CERTIFICATE')"
                                                                               #fileUploadGraduateCertificate>
                                                                        <button class="btn btn-primary color-white ml-1"
                                                                                *ngIf="fileGraduateCertificate && fileGraduateCertificate['url']"
                                                                                (click)="openUrl(fileGraduateCertificate['url'])">
                                                                            <i class="fas fa-eye"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="text"
                                                                               class="form-control"
                                                                               formControlName="post_graduate_examination"
                                                                               placeholder="PG">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('post_graduate_examination').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="post_graduate_subject">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('post_graduate_subject').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" class="form-control"
                                                                               formControlName="post_graduate_passing_year"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('post_graduate_passing_year').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="post_graduate_board"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('post_graduate_board').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="post_graduate_grade"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('post_graduate_grade').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <button class="btn btn-primary color-white"
                                                                                (click)="fileUploadPostGraduateCertificate.click()"
                                                                                *ngIf="!disableAll && ['BUSINESS_CENTER', 'BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                                            <i class="fas fa-cloud-upload-alt"></i>
                                                                        </button>
                                                                        <input class="form-control-file display-hidden"
                                                                               type="file"
                                                                               (change)="onFileChange($event, 'POST_GRADUATE_CERTIFICATE')"
                                                                               #fileUploadPostGraduateCertificate>
                                                                        <button class="btn btn-primary color-white ml-1"
                                                                                *ngIf="filePostGraduateCertificate && filePostGraduateCertificate['url']"
                                                                                (click)="openUrl(filePostGraduateCertificate['url'])">
                                                                            <i class="fas fa-eye"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="text"
                                                                               class="form-control"
                                                                               formControlName="others_examination"
                                                                               placeholder="Others"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('others_examination').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="others_subject"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('others_subject').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" class="form-control"
                                                                               formControlName="others_passing_year">
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('others_passing_year').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="others_board"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('others_board').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                               formControlName="others_grade"/>
                                                                        <small *ngIf="submitted && studentAdmissionForm.get('others_grade').hasError('required')"
                                                                               class="text-danger">
                                                                            Field is required.
                                                                        </small>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <button class="btn btn-primary color-white"
                                                                                (click)="fileUploadOtherCertificate.click()"
                                                                                *ngIf="!disableAll && ['BUSINESS_CENTER', 'BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                                            <i class="fas fa-cloud-upload-alt"></i>
                                                                        </button>
                                                                        <button class="btn btn-primary color-white ml-1"
                                                                                *ngIf="fileOtherCertificate && fileOtherCertificate['url']"
                                                                                (click)="openUrl(fileOtherCertificate['url'])">
                                                                            <i class="fas fa-eye"></i>
                                                                        </button>
                                                                        <input class="form-control-file display-hidden"
                                                                               type="file"
                                                                               (change)="onFileChange($event, 'OTHER_CERTIFICATE')"
                                                                               #fileUploadOtherCertificate>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <div class="row mt-3">
                                                                <div class="col-12 text-center">
                                                                    <i class="fas fa-signature font-large-5 font-weight cursor-pointer signature-icon"
                                                                       (click)="openSignatureUploadModel(content)"
                                                                       title="Click here to upload signature"
                                                                       *ngIf="!signatureImage && user['user_type'] !== 'SUPER_ADMIN'"></i>
                                                                    <img [src]="signatureImage"
                                                                         class="cursor-pointer profile-image shadow-5px"
                                                                         *ngIf="signatureImage && user['user_type'] !== 'SUPER_ADMIN' && ['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER', 'BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1"
                                                                         (click)="openSignatureUploadModel(content)" style="max-width: 200px"/>
                                                                    <img src="./assets/images/image-backgrounds/1600-1600.jpg"
                                                                         class="cursor-pointer profile-image shadow-5px"
                                                                         *ngIf="user['user_type'] === 'SUPER_ADMIN'"/>
                                                                </div>
                                                                <div class="col-12 text-center">
                                                                    <h6 class="mt-1">Student's Signature</h6>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer width-100-per"
                                     *ngIf="admissionFormDetail && admissionFormDetail['processing_status'] !== 'PROCESSED'">
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <button class="btn btn-success pull-right" (click)="submit()"
                                                    [disabled]="submitting"
                                                    *ngIf="(['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && (!admissionFormDetail || (!admissionFormDetail['father_name'] && !admissionFormDetail['mother_name'])))">
                                                <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
                                                <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Submit
                                            </button>
                                            <button class="btn btn-success pull-right" (click)="submit()"
                                                    [disabled]="submitting"
                                                    *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1)">
                                                <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
                                                <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Submit
                                            </button>
                                            <button class="btn btn-success pull-right" [disabled]="true"
                                                    *ngIf="(['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && (admissionFormDetail && (admissionFormDetail['father_name'] || admissionFormDetail['mother_name'])))">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
