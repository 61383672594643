import {Component, OnInit, ViewChild} from '@angular/core';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {StageService} from '../../shared/services/stage.service';
import {StudentService} from '../../shared/services/student.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SrMappingService} from '../../shared/services/sr-mapping.service';
import {AlertService} from '../../shared/services/alert.service';
import {Helper} from '../../shared/constants/helper';
import {StudentStageStatusService} from '../../shared/services/student-stage-status.service';
import {StorageService} from '../../shared/services/storage.service';
import {CourseService} from '../../shared/services/course.service';
import {Router} from '@angular/router';
import {CounsellorService} from '../../shared/services/counsellor.service';
import {AdminService} from '../../shared/services/admin.service';
import {HeadCounsellorService} from '../../shared/services/head-counsellor.service';
import {UniversityService} from '../../shared/services/university.service';
import {PcbMappingService} from '../../shared/services/pcb-mapping.service';
import {ProgramService} from '../../shared/services/program.service';
import {UniversitySettingService} from '../../shared/services/university-setting.service';
import {BusinessCenterService} from '../../shared/services/business-center.service';
import {BusinessHeadCenterService} from '../../shared/services/business-head-center.service';
import {BusinessAdminService} from '../../shared/services/business-admin.service';
import {BranchService} from '../../shared/services/branch.service';
import {CourseCurriculumService} from '../../shared/services/course-curriculum.service';
import {AppConstants} from '../../shared/constants/app-constant';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-students',
    templateUrl: './students.component.html',
    styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {
    id: any;
    data: any;
    tabs: any = [];
    studentDetail: any;
    selectedStudentData: any;
    selectedProIdData: any;
    selectedEnrollmentData: any;
    selectedRollNoData: any;
    selectedStudentDocumentErrorData: any;
    updateStatusForm: FormGroup;
    updateEnrollmentForm: FormGroup;
    updateProIdForm: FormGroup;
    updateRollNoForm: FormGroup;
    addQuickLeadForm: FormGroup;
    reRegisterForm: FormGroup;
    studentPhotographDownloadForm: FormGroup;
    bulkUniversityForm: FormGroup;
    studentSessionForm: FormGroup;
    stages: any = [];
    statuses: any = AppConstants.statuses;
    reasons: any = [];
    submitted: any = false;
    submitting: any = false;
    quickLeadAdd: any = false;
    reRegisterStudent: any = false;
    reRegisterStudentSelect: any;
    bulkUploadAdd: any = false;
    bulkUploadProId: any = false;
    courses = [];
    universities = [];
    user: any;
    counsellorDetail: any;
    assignStudentData: any = false;
    admins: any = [];
    headCounsellors: any = [];
    counsellors: any = [];
    selectAssignable: any;
    studentChecked: any = false;
    onFileChanged: any;
    bulkStudentData: any = [];
    bulkProIdData: any = [];
    showFetchingDataLoader: any = false;
    showUpdatingDataLoader: any = false;
    transactionData: any;
    studentPagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };
    pagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };
    openSearch: any = false;
    searchUniversities = [];
    searchAdmins = [];
    searchHeadCounsellors = [];
    searchCounsellors = [];
    searchForm: FormGroup;
    filterPrograms: any = [];
    filterCourses: any = [];
    filterBranches: any = [];
    @ViewChild('navFilled') navFilled;
    active: any;
    activeTab: any = 0;
    headCounsellorDetailAdmin: any;
    headCounsellorUniversities: any = [];
    counsellorUniversities: any = [];
    counsellorHeadCounsellor: any = [];
    counsellorAdmin: any = [];
    studentSessionYears: any = [];
    businessCenterDetail: any;
    businessHeadCenterDetail: any;
    businessAdminDetail: any;
    selectedSession: any;
    pcbmapping: any = [];
    programs = [];
    studentPrograms = [];
    branches = [];
    studentCourses: any = [];
    studentBranches: any = [];
    stageTabs: any = [];
    showStudentYear = false;
    studentYears: any = [];
    studentReRegisterYears: any = [];
    showReRegistration: any = false;
    reRegistrationSession: any = [];
    remarkExists: any = false;
    openDeleteModal: any = false;
    selectedstudentDeleteData: any;
    openStudentPhotographDownloadModal: any = false;
    openStudentPhotographDownloadModalProgress: any = false;
    downloadZipUrl: any;
    headCenters: any = [];
    businessCenters: any = [];
    msHeadCenter: any = false;


    constructor(private _coreSidebarService: CoreSidebarService,
                private stageService: StageService,
                private studentService: StudentService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private srMappingService: SrMappingService,
                public studentStageStatusService: StudentStageStatusService,
                public courseService: CourseService,
                public counsellorService: CounsellorService,
                public alertService: AlertService,
                private router: Router,
                private adminService: AdminService,
                private headCounsellorService: HeadCounsellorService,
                private universityService: UniversityService,
                private programService: ProgramService,
                private branchService: BranchService,
                private pcbMappingService: PcbMappingService,
                private courseCurriculumService: CourseCurriculumService,
                private universitySettingService: UniversitySettingService,
                private businessCenterService: BusinessCenterService,
                private businessHeadCenterService: BusinessHeadCenterService,
                private businessAdminService: BusinessAdminService) {
        this.getStages();
    }

    ngOnInit(): void {
        this.user = StorageService.getItem('self');
        /*if (this.user && this.user['user_type'] && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ADMIN' || this.user['user_type'] === 'HEAD_COUNSELLOR')) {
            this.tabs = [
                {
                    'id': 'All',
                    'name': 'All',
                    'data': []
                },
                {
                    'id': 'Unassigned',
                    'name': 'Unassigned',
                    'data': []
                }
            ];
        }*/
        this.refresh();
    }

    refresh() {
        this.studentSessionForm = this.fb.group({
            'university_id': [''],
            'student_session_year': ['']
        });
        this.updateStatusForm = this.fb.group({
            'status': ['', Validators.required],
            'remarks': ['']
        });
        this.updateProIdForm = this.fb.group({
            'pro_id': ['', Validators.required]
        });
        this.updateEnrollmentForm = this.fb.group({
            'enrollment_id': ['', Validators.required]
        });
        this.updateRollNoForm = this.fb.group({
            'roll_no': ['', Validators.required]
        });
        this.addQuickLeadForm = this.fb.group({
            'session_id': ['', Validators.required],
            'stage_id': ['', Validators.required],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'program_id': ['', Validators.required],
            'course_id': ['', Validators.required],
            'branch_id': [''],
            'student_year': ['']
        });
        this.reRegisterForm = this.fb.group({
            'student_year': ['', Validators.required],
            'session_id': ['', Validators.required]
        });
        this.studentPhotographDownloadForm = this.fb.group({
            'from': ['', Validators.required],
            'to': ['', Validators.required]
        });
        this.getCourses();
        if (this.user && this.user['user_type'] !== 'BUSINESS_CENTER' && this.user['user_type'] !== 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenters();
        }
        /*this.getCourses();
        this.getBranch();*/
        this.bulkUniversityForm = this.fb.group({
            'university_id': ['', Validators.required]
        });
        if (this.user && this.user['user_type'] === 'BUSINESS_ADMIN') {
            this.searchForm = this.fb.group({
                'name': [''],
                'application_number': [''],
                'email': [''],
                'contact': [''],
                'pro_id': [''],
                'enrollment_id': [''],
                'roll_no': [''],
                'course_id': [''],
                'added_by_business_head_center': [''],
                'added_by': ['']
            });
        } else if (this.user && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.searchForm = this.fb.group({
                'name': [''],
                'application_number': [''],
                'email': [''],
                'contact': [''],
                'pro_id': [''],
                'enrollment_id': [''],
                'roll_no': [''],
                'course_id': [''],
                'added_by': ['']
            });
        } else {
            this.searchForm = this.fb.group({
                'name': [''],
                'application_number': [''],
                'email': [''],
                'contact': [''],
                'pro_id': [''],
                'enrollment_id': [''],
                'roll_no': [''],
                'course_id': ['']
            });
        }
        this.getCounsellorDetail();
        if (this.user && this.user['user_type'] === 'SUPER_ADMIN') {
            this.getAdmins();
            this.getUniversitiesForSearch();
            this.getAdminsForSearch();
            this.getUniversities();
        } else if (this.user && this.user['user_type'] === 'ADMIN') {
            this.getUniversitiesForSearch();
            this.getHeadCounsellors();
            // this.getHeadCounsellorsForSearch();
        } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.getHeadCounsellorDetails(this.user['userId']);
            this.getCounsellors();
        } else if (this.user && this.user['user_type'] === 'COUNSELLOR') {
            this.getCounsellorDetails(this.user['userId']);
        } else if (this.user && this.user['user_type'] === 'BUSINESS_ADMIN') {
            this.getBusinessAdminDetail();
        } else if (this.user && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenterDetail();
        } else if (this.user && this.user['user_type'] === 'BUSINESS_CENTER') {
            this.getBusinessCenterDetail();
        } else if (this.user && this.user['user_type'] === 'UNIVERSITY') {
            this.getStudentSessionData(this.user['userId']);
        }
    }

    get(search?) {
        this.tabs = [];
        this.stageService.get({'page': -1}).subscribe(data => {
            let tabs = this.tabs;
            if (data && data['data'].length > 0) {
                data['data'].forEach(data => {
                    //if (data && (data.id === 5 || data.id === 10 || data.id === 11 || data.id === 12)) {
                    if (data && (data.id === 5 || data.id === 10)) {
                        tabs.push({
                            'id': data.id,
                            'name': data.name,
                            'data': []
                        });
                    }
                });
                this.tabs = tabs;
                this.stageTabs = [];
                if (tabs && tabs.length > 0) {
                    let stageTabs = [];
                    tabs.forEach(tab => {
                        if (tab.id !== 10) {
                            stageTabs.push(tab);
                        }
                    });
                    this.stageTabs = stageTabs;
                    this.addQuickLeadForm.patchValue({
                        'stage_id': 5
                    });
                }
            }
            this.getStudents(this.studentPagination, search);
            /*if (this.activeTab === 1) {
                this.getTabData({
                    'id': 'Unassigned',
                    'name': 'Unassigned',
                    'data': []
                }, 1);
            } else {
                this.getStudents(this.studentPagination, search);
            }*/
        });
    }

    getUniversities() {
        this.universities = [];
        this.universityService.get({'page': -1}).subscribe(data => {
            if (data['data'] && data['data'].length > 0) {
                this.universities = data['data'];
                this.studentSessionForm.patchValue({
                    'university_id': this.universities[this.universities.length - 1].id
                });
                this.getStudentSessionData(this.universities[this.universities.length - 1].id);
            } else {
                this.universities = [];
            }
        });
    }

    getBusinessCenterDetail() {
        this.businessCenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessCenterDetail = data;
            if (this.businessCenterDetail && this.businessCenterDetail['head_center_id'] && (parseInt(this.businessCenterDetail['head_center_id']) === AppConstants.MS_HEAD_CENTER_ID)) {
                this.msHeadCenter = true;
            }
            this.getStudentSession();
        });
    }

    getBusinessHeadCenterDetail() {
        this.businessHeadCenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessHeadCenterDetail = data;
            this.getBusinessCentersForHeadCenter();
        });
    }

    getBusinessCentersForHeadCenter() {
        this.businessCenterService.get(this.businessHeadCenterDetail['business_admin_detail']['user_id'], this.businessHeadCenterDetail['user_id'], {'page': -1}).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.businessCenters = data['data'];
                this.getStudentSession();
            } else {
                this.businessCenters = [];
            }
        }, error => {
            this.businessCenters = [];
        });
    }

    getBusinessAdminDetail() {
        this.businessAdminService.detailById(this.user['userId']).subscribe(data => {
            this.businessAdminDetail = data;
            this.getStudentSession();
        });
    }

    getAdmins(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.admins = [];
        this.adminService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.admins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.admins = [];
            }
        });
    }

    getHeadCounsellorDetails(id) {
        this.headCounsellorService.detail(id).subscribe(data => {
            this.headCounsellorDetailAdmin = data['head_counsellor_detail']['admin'];
            this.headCounsellorUniversities = data['head_counsellor_universities'];
        });
    }

    getCounsellorDetails(id) {
        this.counsellorService.detail(id).subscribe(data => {
            this.counsellorUniversities = data['counsellor_universities'];
            this.counsellorHeadCounsellor = data['counsellor_detail']['head_counsellor']['user'];
            this.counsellorAdmin = data['counsellor_detail']['head_counsellor']['admin'];
        });
    }

    getAdminsForSearch(params: any = {}) {
        this.searchAdmins = [];
        this.adminService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.searchAdmins = data['data'];
            } else {
                this.searchAdmins = [];
            }
        });
    }

    getHeadCounsellorsForSearch(event) {
        if (event && event.target && event.target.value) {
            this.searchHeadCounsellors = [];
            this.adminService.getAdminsHeadCounsellors(event.target.value, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.searchHeadCounsellors = data['data'];
                } else {
                    this.searchHeadCounsellors = [];
                }
            }, error => {
                this.searchHeadCounsellors = [];
            });
        } else {
            this.searchHeadCounsellors = [];
        }
    }

    getCounsellorsForSearch(event) {
        this.searchCounsellors = [];
        this.headCounsellorService.getHeadCounsellorsCounsellors(event.target.value, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.searchCounsellors = data['data'];
            } else {
                this.searchCounsellors = [];
            }
        }, error => {
            this.searchCounsellors = [];
        });
    }

    getUniversitiesForSearch(params: any = {}) {
        this.searchUniversities = [];
        this.universityService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.searchUniversities = data['data'];
            }
            /*if (data && data['data'].length > 0) {
                this.admins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.admins = [];
            }*/
        });
    }

    getHeadCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.headCounsellors = [];
        this.headCounsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.headCounsellors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.headCounsellors = [];
            }
        });
    }

    getCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.counsellors = [];
        this.counsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.counsellors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.counsellors = [];
            }
        });
    }

    getCounsellorDetail() {
        if (this.user && this.user['userId']) {
            this.counsellorService.detail(this.user['userId']).subscribe(data => {
                if (data) {
                    this.counsellorDetail = data;
                    this.universities = (this.counsellorDetail && this.counsellorDetail['counsellor_universities'] && this.counsellorDetail['counsellor_universities'].length > 0) ? this.counsellorDetail['counsellor_universities'] : [];
                    if (this.universities && this.universities.length > 0) {
                        if (this.user && this.user['user_type'] === 'COUNSELLOR') {
                            this.getUniversityCourses(this.universities[0].university_id);
                        }
                    }
                }
            });
        }
    }

    getUniversityCourses(universityId) {
        if (universityId) {
            this.courseService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.courses = data['data'];
                } else {
                    this.courses = [];
                }
            });
        } else {
            this.alertService.showErrors('Please choose university');
        }
    }

    getStages() {
        this.stages = [];
        this.stageService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.stages = data['data'];
            }
        });
    }

    checkForRemark(value) {
        if (value === 'DOCUMENTS_REQUIRED') {
            this.remarkExists = true;
            this.updateStatusForm.patchValue({
                'remarks': ''
            });
        } else {
            this.remarkExists = false;
        }
    }

    getStageWiseStudents(stageId, index, params: any = {}) {
        params = {
            'session_id': (this.selectedSession && this.selectedSession['id']) ? this.selectedSession['id'] : '',
            'page': (this.studentPagination.page) ? this.studentPagination.page : 1,
            'total': (this.studentPagination.total) ? this.studentPagination.total : null,
            'per_page': (this.studentPagination.perpage) ? this.studentPagination.perpage : 15,
            'pages': (this.studentPagination.pages) ? this.studentPagination.pages : null,
            ...this.searchForm.getRawValue()
        };
        this.studentService.getByStage(stageId, params).subscribe(data => {
            this.tabs[index].data = [];
            let allData = [];
            let tabsData = this.tabs;
            if (data && data['data'] && data['data'].length > 0) {
                data['data'].forEach(data => {
                    allData.push({
                        'id': (data['student_id']) ? data['student_id'] : '',
                        'name': (data['student'] && data['student'].name) ? data['student'].name : '',
                        'photograph': (data['photograph']) ? data['photograph'] : '',
                        'signature': (data['signature']) ? data['signature'] : '',
                        'enrollment_id': (data && data['enrollment_id']) ? data['enrollment_id'] : '-',
                        'pro_id': (data && data['pro_id']) ? data['pro_id'] : '-',
                        'roll_no': (data && data['roll_no']) ? data['roll_no'] : '-',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data['student'] && data['student'].email) ? data['student'].email : '',
                        'phone': (data['student'] && data['student'].contact) ? data['student'].contact : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].id) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].id) ? data['student']['course_details']['branch'].id : '',
                        'status': (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['status']) ? ((data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_UPLOADED' ? 'DOCUMENTS UPLOADED' : (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_REQUIRED' ? 'DOCUMENTS REQUIRED' : data['student']['student_detail']['status'].toLowerCase()))) : '-',
                        'remarks': (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['remarks']) ? data['student']['student_detail']['remarks'] : '',
                        'process_status': (data && data['processing_status']) ? data['processing_status'] : '',
                        'status_id': (data && data['admission_type'] && data['admission_type']['id']) ? data['admission_type']['id'] : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student'] && data['student']['student_detail']) && data['student']['student_detail']['student_year'] ? data['student']['student_detail']['student_year'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['added_by_business_head_center'] && data['added_by_business_head_center']) ? data['added_by_business_head_center'] : '',
                        'father_name': (data && data['father_name']) ? data['father_name'] : '',
                        'mother_name': (data && data['mother_name']) ? data['mother_name'] : '',
                        'gender': (data && data['gender']) ? data['gender'] : '',
                        'date_of_birth': (data && data['date_of_birth']) ? data['date_of_birth'] : '',
                        'govt_photo_id_proof_error': (data && data['govt_photo_id_proof_error']) ? data['govt_photo_id_proof_error'] : '',
                        'tenth_certificate_error': (data && data['tenth_certificate_error']) ? data['tenth_certificate_error'] : '',
                        'twelth_certificate_error': (data && data['twelth_certificate_error']) ? data['twelth_certificate_error'] : '',
                        'graduate_certificate_error': (data && data['graduate_certificate_error']) ? data['graduate_certificate_error'] : '',
                        'student_document_download_loader': false
                    });
                });
                //this.tabs[0].data = allData;
                if (tabsData && tabsData.length > 0) {
                    tabsData.forEach(tab => {
                        if (tab) {
                            if (allData && allData.length > 0) {
                                allData.forEach(all => {
                                    if (tab.id === all.status_id) {
                                        let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                                        let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                                        let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                                        let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                                        let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                                        let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                                        let chargeableCourseFee = 0;
                                        let total = 0;
                                        let reRegistrationPercentageActual = 0;
                                        let admissionPercentageActual = 0;
                                        let reRegistrationPercentageDisplay = 0;
                                        let admissionPercentageDisplay = 0;
                                        let prospectusFee = 0;
                                        let serviceCharge = 0;
                                        let examinationFee = 0;
                                        if (tab && tab.id === 10) {
                                            if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0) {
                                                all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                                    if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.selectedSession['id'])) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                                        admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            }
                                            chargeableCourseFee = re_registration_amount * ((reRegistrationPercentageDisplay) / 100);
                                            total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            if (all && all['business_center'] && all['business_center']['business_center_detail']) {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            }
                                        } else {
                                            if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0) {
                                                all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                                    if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.selectedSession['id'])) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                                        admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            }
                                            chargeableCourseFee = course_fee * ((admissionPercentageDisplay) / 100);
                                            total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            if (all && all['business_center'] && all['business_center']['business_center_detail']) {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            }
                                        }
                                        tab['data'].push({
                                            'id': all.id,
                                            'name': all.name,
                                            'photograph': all.photograph,
                                            'signature': all.signature,
                                            'pro_id': all.pro_id,
                                            'enrollment_id': all.enrollment_id,
                                            'roll_no': all.roll_no,
                                            'username': all.username,
                                            'email': all.email,
                                            'phone': all.phone,
                                            'program_id': all.program_id,
                                            'course_id': all.course_id,
                                            'branch_id': all.branch_id,
                                            'program': all.program,
                                            'course': all.course,
                                            'branch': all.branch,
                                            'status': all.status,
                                            'remarks': all.remarks,
                                            'process_status': all.process_status,
                                            'status_id': all.status_id,
                                            'register_course_id': all.register_course_id,
                                            'student_year': all.student_year,
                                            'register_course': all.register_course,
                                            'business_center': all.business_center,
                                            'added_by': all.added_by,
                                            'pcb_mapping': all.pcb_mapping,
                                            'prospectus_fee': (!isNaN(prospectusFee) ? prospectusFee : (all && all['pcb_mapping'] && all['pcb_mapping']['prospectus_fee']) ? all['pcb_mapping']['prospectus_fee'] : '-'),
                                            'service_charge': (!isNaN(serviceCharge) ? serviceCharge : (all && all['pcb_mapping'] && all['pcb_mapping']['service_charge']) ? all['pcb_mapping']['service_charge'] : '-'),
                                            'examination_fee': (!isNaN(examinationFee) ? examinationFee : (all && all['pcb_mapping'] && all['pcb_mapping']['examination_fee']) ? all['pcb_mapping']['examination_fee'] : '-'),
                                            'total': total,
                                            'counsellor': all.counsellor,
                                            'head_counsellor': all.head_counsellor,
                                            'father_name': all.father_name,
                                            'mother_name': all.mother_name,
                                            'gender': all.gender,
                                            'date_of_birth': all.date_of_birth,
                                            'chargeableCourseFee': chargeableCourseFee,
                                            'govt_photo_id_proof_error': all.govt_photo_id_proof_error,
                                            'tenth_certificate_error': all.tenth_certificate_error,
                                            'twelth_certificate_error': all.twelth_certificate_error,
                                            'graduate_certificate_error': all.graduate_certificate_error,
                                            'student_document_download_loader': all.student_document_download_loader
                                        });
                                    }
                                });
                            }
                        }
                    });
                }
                this.studentPagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.studentPagination = {
                    page: null,
                    total: null,
                    perpage: null,
                    pages: null,
                };
            }
        }, error => {
            this.studentPagination = {
                page: null,
                total: null,
                perpage: null,
                pages: null,
            };
        });
    }

    getStudents(params: any = {}, search?) {
        if (search) {
            params = {
                'session_id': (this.selectedSession.id) ? this.selectedSession.id : null,
                'page': 1,
                'per_page': 50,
                'admission_type': this.tabs[this.activeTab].id,
                ...this.searchForm.getRawValue()
            };
        } else {
            params = {
                'session_id': (this.selectedSession.id) ? this.selectedSession.id : null,
                'page': (this.studentPagination.page) ? this.studentPagination.page : 1,
                'total': (this.studentPagination.total) ? this.studentPagination.total : null,
                'per_page': (this.studentPagination.perpage) ? this.studentPagination.perpage : 50,
                'pages': (this.studentPagination.pages) ? this.studentPagination.pages : null,
                //...this.searchForm.getRawValue()
            };
        }
        search = false;
        this.studentService.get(params).subscribe(data => {
            let allData = [];
            let tabsData = this.tabs;
            if (data && data['data'] && data['data'].length > 0) {
                data['data'].forEach(data => {
                    allData.push({
                        'id': (data && data['student_id']) ? data['student_id'] : '',
                        'name': (data && data['student_name']) ? data['student_name'] : '',
                        'photograph': (data['photograph']) ? data['photograph'] : '',
                        'signature': (data['signature']) ? data['signature'] : '',
                        'pro_id': (data && data['pro_id']) ? data['pro_id'] : '-',
                        'enrollment_id': (data && data['enrollment_id']) ? data['enrollment_id'] : '-',
                        'roll_no': (data && data['roll_no']) ? data['roll_no'] : '-',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data && data['email']) ? data['email'] : '',
                        'phone': (data && data['contact']) ? data['contact'] : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].id : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status':
                            (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['status']) ?
                                ((data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_UPLOADED' ? 'DOCUMENTS UPLOADED' :
                                    (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_RE_UPLOADED' ? 'DOCUMENTS RE UPLOADED' :
                                        (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_REQUIRED' ? 'DOCUMENTS REQUIRED' :
                                            data['student']['student_detail']['status'].toLowerCase())))) :
                                '-',
                        'remarks': (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['remarks']) ? data['student']['student_detail']['remarks'] : '',
                        'process_status': (data && data['processing_status']) ? data['processing_status'] : '',
                        'status_id': (data && data['admission_type'] && data['admission_type']['id']) ? data['admission_type']['id'] : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student_year']) ? data['student_year'] : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'transactions': (data && data['transactions']) ? (data && data['transactions']) : [],
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['added_by_business_head_center'] && data['added_by_business_head_center']) ? data['added_by_business_head_center'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : '',
                        'father_name': (data && data['father_name']) ? data['father_name'] : '',
                        'mother_name': (data && data['mother_name']) ? data['mother_name'] : '',
                        'gender': (data && data['gender']) ? data['gender'] : '',
                        'date_of_birth': (data && data['date_of_birth']) ? data['date_of_birth'] : '',
                        'govt_photo_id_proof_error': (data && data['govt_photo_id_proof_error']) ? data['govt_photo_id_proof_error'] : '',
                        'tenth_certificate_error': (data && data['tenth_certificate_error']) ? data['tenth_certificate_error'] : '',
                        'twelth_certificate_error': (data && data['twelth_certificate_error']) ? data['twelth_certificate_error'] : '',
                        'graduate_certificate_error': (data && data['graduate_certificate_error']) ? data['graduate_certificate_error'] : '',
                        'student_document_download_loader': false,
                        'year': (data && data['year']) ? data['year'] : '',
                        're_registration_year': (data && data['re_registration_year']) ? data['re_registration_year'] : '',
                        'current_session_id': (data && data['current_session_id']) ? data['current_session_id'] : '',
                    });
                });
                this.tabs[0].data = [];
                if (tabsData && tabsData.length > 0) {
                    tabsData.forEach(tab => {
                        if (tab) {
                            if (allData && allData.length > 0) {
                                allData.forEach(all => {
                                    if (tab.id === all.status_id) {
                                        let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                                        let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                                        let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                                        let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                                        let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                                        let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                                        let chargeableCourseFee = 0;
                                        let total = 0;
                                        let reRegistrationPercentageActual = 0;
                                        let admissionPercentageActual = 0;
                                        let reRegistrationPercentageDisplay = 0;
                                        let admissionPercentageDisplay = 0;
                                        let prospectusFee = 0;
                                        let serviceCharge = 0;
                                        let examinationFee = 0;
                                        if (tab && tab.id === 10) {
                                            if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                                                all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                                    if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.selectedSession['id'])) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                                        admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                                                all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                                    if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.selectedSession['id'])) && (parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(all['added_by']))) {
                                                        admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            }
                                            chargeableCourseFee = re_registration_amount * ((reRegistrationPercentageDisplay) / 100);
                                            total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            }
                                        } else {
                                            if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                                                all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                                    if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.selectedSession['id'])) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by'].id))) {
                                                        admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                                                all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                                    if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.selectedSession['id'])) && ((parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId'])))) {
                                                        admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            }
                                            chargeableCourseFee = course_fee * ((admissionPercentageDisplay) / 100);
                                            total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            }
                                        }
                                        tab['data'].push({
                                            'id': all.id,
                                            'name': all.name,
                                            'photograph': all.photograph,
                                            'signature': all.signature,
                                            'pro_id': all.pro_id,
                                            'enrollment_id': all.enrollment_id,
                                            'roll_no': all.roll_no,
                                            'username': all.username,
                                            'email': all.email,
                                            'phone': all.phone,
                                            'program_id': all.program_id,
                                            'course_id': all.course_id,
                                            'branch_id': all.branch_id,
                                            'program': all.program,
                                            'course': all.course,
                                            'branch': all.branch,
                                            'status': all.status,
                                            'remarks': all.remarks,
                                            'process_status': all.process_status,
                                            'status_id': all.status_id,
                                            'transactions': all.transaction,
                                            'register_course_id': all.register_course_id,
                                            'register_course': all.register_course,
                                            'student_year': all.student_year,
                                            'added_by': all.added_by,
                                            'prospectus_fee': (!isNaN(prospectusFee) ? prospectusFee : (all && all['pcb_mapping'] && all['pcb_mapping']['prospectus_fee']) ? all['pcb_mapping']['prospectus_fee'] : '-'),
                                            'service_charge': (!isNaN(serviceCharge) ? serviceCharge : (all && all['pcb_mapping'] && all['pcb_mapping']['service_charge']) ? all['pcb_mapping']['service_charge'] : '-'),
                                            'examination_fee': (!isNaN(examinationFee) ? examinationFee : (all && all['pcb_mapping'] && all['pcb_mapping']['examination_fee']) ? all['pcb_mapping']['examination_fee'] : '-'),
                                            'pcb_mapping': all.pcb_mapping,
                                            'total': total,
                                            'counsellor': all.counsellor,
                                            'head_counsellor': all.head_counsellor,
                                            'business_center': all.business_center,
                                            'father_name': all.father_name,
                                            'mother_name': all.mother_name,
                                            'gender': all.gender,
                                            'date_of_birth': all.date_of_birth,
                                            'chargeableCourseFee': chargeableCourseFee,
                                            'govt_photo_id_proof_error': all.govt_photo_id_proof_error,
                                            'tenth_certificate_error': all.tenth_certificate_error,
                                            'twelth_certificate_error': all.twelth_certificate_error,
                                            'graduate_certificate_error': all.graduate_certificate_error,
                                            'student_document_download_loader': all.student_document_download_loader,
                                            'year': all.year,
                                            're_registration_year': all.re_registration_year,
                                            'current_session_id': all.current_session_id,
                                        });
                                    }
                                });
                            }
                        }
                    });
                    this.active = search ? this.activeTab : 0;
                }
                this.studentPagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            }

            const name = (this.searchForm.value && this.searchForm.value.name ? this.searchForm.value.name : '');
            const username = (this.searchForm.value && this.searchForm.value.username ? this.searchForm.value.username : '');
            const email = (this.searchForm.value && this.searchForm.value.email ? this.searchForm.value.email : '');
            const contact = (this.searchForm.value && this.searchForm.value.contact ? this.searchForm.value.contact : '');
            const universityId = (this.searchForm.value && this.searchForm.value.universityId ? this.searchForm.value.universityId : '');
            const programId = (this.searchForm.value && this.searchForm.value.programId ? this.searchForm.value.programId : '');
            const courseId = (this.searchForm.value && this.searchForm.value.courseId ? this.searchForm.value.courseId : '');
            const branchId = (this.searchForm.value && this.searchForm.value.branchId ? this.searchForm.value.branchId : '');
            const adminId = (this.searchForm.value && this.searchForm.value.adminId ? this.searchForm.value.adminId : '');
            const headCounsellorId = (this.searchForm.value && this.searchForm.value.headCounsellorId ? this.searchForm.value.headCounsellorId : '');
            const counsellorId = (this.searchForm.value && this.searchForm.value.counsellorId ? this.searchForm.value.counsellorId : '');
            const businessCenterId = (this.searchForm.value && this.searchForm.value.businessCenterId ? this.searchForm.value.businessCenterId : '');

            if (!name && !username && !email && !contact && !universityId && !programId && !courseId && !branchId && !adminId && !headCounsellorId && !counsellorId && !businessCenterId) {
                this.studentPagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                /*this.studentPagination = {
                    page: 1,
                    total: null,
                    perpage: null,
                    pages: null
                    /!*page: -1,
                    total: undefined,
                    perpage: undefined,
                    pages: undefined,*!/
                };*/
            }
        }, error => {
            this.active = search ? this.activeTab : 0;
        });
    }

    getTabData(tab, index, params: any = {}) {
        this.searchForm.patchValue({
            'name': '',
            'application_number': '',
            'email': '',
            'contact': '',
            'pro_id': '',
            'enrollment_id': '',
            'roll_no': '',
            'course_id': '',
            'added_by_business_head_center': '',
            'added_by': ''
        });
        this.activeTab = index;
        if (tab && tab.id !== 'All') {
            this.getStageWiseStudents(tab.id, index);
        } else {
            this.getStudents(this.studentPagination);
        }
    }

    getUniversityPrograms(event) {
        this.programService.getByAdmin(event.target.value, {'page': -1}).subscribe(data => {
            this.filterPrograms = data['data'];
        }, error => {
            this.filterPrograms = [];
        });
    }

    getProgramCourses(event) {
        this.courseService.getByAdmin(event.target.value, {'page': -1}).subscribe(data => {
            this.filterCourses = data['data'];
        }, error => {
            this.filterCourses = [];
        });
    }

    getCourseBranches(event) {
        if (!this.searchForm.value || !this.searchForm.value.universityId || this.searchForm.value.universityId === '') {
            this.alertService.showErrors('Please choose university');
        } else if (!this.searchForm.value || !this.searchForm.value.programId || this.searchForm.value.programId === '') {
            this.alertService.showErrors('Please choose program');
        } else if (!this.searchForm.value || !this.searchForm.value.courseId || this.searchForm.value.courseId === '') {
            this.alertService.showErrors('Please choose course');
        } else {
            let params = {
                'program_id': (this.searchForm.value && this.searchForm.value.programId ? this.searchForm.value.programId : ''),
                'course_id': (this.searchForm.value && this.searchForm.value.courseId ? this.searchForm.value.courseId : '')
            };
            this.pcbMappingService.getByAdmin(this.searchForm.value.universityId, params).subscribe(data => {
                this.filterBranches = data['data'];
            }, error => {
                this.filterBranches = [];
            });
        }
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('detail-student').toggleOpen();
    }

    detail(data: any) {
        this.studentDetail = data;
        this.toggleSidebar();
    }

    delete(content, data: any) {
        this.selectedstudentDeleteData = data;
        this.openDeleteModal = true;
        this.modalService.open(content, {'size': 'sm'});
    }

    deleteStudent() {
        this.submitting = true;
        this.studentService.delete(this.selectedstudentDeleteData.id).subscribe(data => {
            this.submitting = false;
            this.getStudents({page: 1, total: null, perpage: 50, pages: null});
            this.modalService.dismissAll('');
        }, error => {
            this.submitting = false;
        });
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.getStudents({page: 1, total: null, perpage: 50, pages: null});
        }
        this.toggleSidebar();
    }

    changeStatusModal(content, data) {
        this.openStudentPhotographDownloadModal = false;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.selectedStudentData = data;
        this.quickLeadAdd = false;
        this.assignStudentData = false;
        this.selectedEnrollmentData = false;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        if (data && data['status'] && data['status'] !== '') {
            if (data['status'] === 'DOCUMENTS REQUIRED') {
                this.remarkExists = true;
                this.updateStatusForm.patchValue({
                    'remarks': (data && data['remarks']) ? data['remarks'] : ''
                });
            }
            this.updateStatusForm.patchValue({
                'status': (data['status'] !== 'DOCUMENTS REQUIRED') ? data['status'].toUpperCase() : 'DOCUMENTS_REQUIRED'
            });
        } else {
            this.updateStatusForm.patchValue({
                'status': '',
                'remarks': ''
            });
        }
        this.modalService.open(content, {'size': 'sm'});
    }

    changeProModal(content, data) {
        this.openStudentPhotographDownloadModal = false;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.selectedRollNoData = undefined;
        this.selectedStudentDocumentErrorData = undefined;
        this.selectedEnrollmentData = undefined;
        this.selectedProIdData = data;
        this.quickLeadAdd = false;
        this.assignStudentData = false;
        this.selectedStudentData = false;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        this.updateProIdForm.patchValue({
            'pro_id': (data && data['pro_id'] && data['pro_id'] !== '-') ? data['pro_id'] : ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    changeEnrollmentModal(content, data) {
        this.openStudentPhotographDownloadModal = false;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.selectedProIdData = undefined;
        this.selectedRollNoData = undefined;
        this.selectedStudentDocumentErrorData = undefined;
        this.selectedEnrollmentData = data;
        this.quickLeadAdd = false;
        this.assignStudentData = false;
        this.selectedStudentData = false;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        this.updateEnrollmentForm.patchValue({
            'enrollment_id': (data && data['enrollment_id'] && data['enrollment_id'] !== '-') ? data['enrollment_id'] : ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    changeRollNoModal(content, data) {
        this.openStudentPhotographDownloadModal = false;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.selectedProIdData = undefined;
        this.selectedEnrollmentData = undefined;
        this.selectedRollNoData = data;
        this.selectedStudentDocumentErrorData = undefined;
        this.quickLeadAdd = false;
        this.assignStudentData = false;
        this.selectedStudentData = false;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        this.updateRollNoForm.patchValue({
            'roll_no': (data && data['roll_no'] && data['roll_no'] !== '-') ? data['roll_no'] : ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    showStudentDocumentError(content, data) {
        this.openStudentPhotographDownloadModal = false;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.selectedProIdData = undefined;
        this.selectedEnrollmentData = undefined;
        this.selectedRollNoData = undefined;
        this.selectedStudentDocumentErrorData = data;
        this.quickLeadAdd = false;
        this.assignStudentData = false;
        this.selectedStudentData = false;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        this.modalService.open(content, {'size': 'sm'});
    }

    updateStatus() {
        this.submitted = true;
        if (!this.updateStatusForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'status': (this.updateStatusForm.value && this.updateStatusForm.value.status) ? this.updateStatusForm.value.status : '',
                'remarks': (this.updateStatusForm.value && this.updateStatusForm.value.remarks && this.updateStatusForm.value.status === 'DOCUMENTS_REQUIRED') ? this.updateStatusForm.value.remarks : '',
            };
            this.submitting = true;
            this.studentService.studentUpdateStatusByBusiness(this.selectedStudentData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.modalService.dismissAll('');
                this.getStudents({page: 1, total: null, perpage: 50, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    updateProId() {
        this.submitted = true;
        if (!this.updateProIdForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'pro_id': (this.updateProIdForm.value && this.updateProIdForm.value.pro_id) ? this.updateProIdForm.value.pro_id : ''
            };
            this.submitting = true;
            this.studentService.studentUpdateProIdByBusiness(this.selectedProIdData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.modalService.dismissAll('');
                this.getStudents({page: 1, total: null, perpage: 50, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    updateEnrollment() {
        this.submitted = true;
        if (!this.updateEnrollmentForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'enrollment_id': (this.updateEnrollmentForm.value && this.updateEnrollmentForm.value.enrollment_id) ? this.updateEnrollmentForm.value.enrollment_id : ''
            };
            this.submitting = true;
            this.studentService.studentUpdateEnrollmentByBusiness(this.selectedEnrollmentData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.modalService.dismissAll('');
                this.getStudents({page: 1, total: null, perpage: 50, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    updateRollNo() {
        this.submitted = true;
        if (!this.updateRollNoForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'roll_no': (this.updateRollNoForm.value && this.updateRollNoForm.value.roll_no) ? this.updateRollNoForm.value.roll_no : ''
            };
            this.submitting = true;
            this.studentService.studentUpdateRollNoByBusiness(this.selectedRollNoData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.modalService.dismissAll('');
                this.getStudents({page: 1, total: null, perpage: 50, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    openBulkUploadModal(content) {
        this.openStudentPhotographDownloadModal = false;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.selectedStudentData = undefined;
        this.reRegisterStudent = undefined;
        this.assignStudentData = false;
        this.quickLeadAdd = false;
        this.reRegisterStudent = false;
        this.bulkUploadAdd = true;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        this.bulkStudentData = [];
        this.modalService.open(content, {'size': 'xl'});
    }

    openProIdBulkUploadModal(content) {
        this.openStudentPhotographDownloadModal = false;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.selectedStudentData = undefined;
        this.reRegisterStudent = undefined;
        this.assignStudentData = false;
        this.quickLeadAdd = false;
        this.reRegisterStudent = false;
        this.bulkUploadAdd = false;
        this.bulkUploadProId = true;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        //this.bulkStudentData = [];
        this.bulkProIdData = [];
        this.modalService.open(content, {'size': 'xl'});
    }

    openQuickLeadModal(content) {
        this.openStudentPhotographDownloadModal = false;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.selectedStudentData = undefined;
        this.reRegisterStudent = undefined;
        this.assignStudentData = false;
        this.bulkUploadAdd = false;
        this.reRegisterStudent = false;
        this.quickLeadAdd = true;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        this.modalService.open(content);
    }

    openReRegister(content, data) {
        this.openStudentPhotographDownloadModal = false;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.selectedStudentData = undefined;
        this.quickLeadAdd = false;
        this.bulkUploadAdd = false;
        this.assignStudentData = false;
        this.reRegisterStudent = true;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        this.reRegisterStudentSelect = data;
        this.getReRegisterPCBMapping(this.reRegisterStudentSelect['program_id'], this.reRegisterStudentSelect['course_id'], this.reRegisterStudentSelect['branch_id']);
        this.modalService.open(content);
    }

    openAssignmentModal(content) {
        this.openStudentPhotographDownloadModal = false;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.selectedStudentData = undefined;
        this.reRegisterStudent = undefined;
        this.quickLeadAdd = false;
        this.bulkUploadAdd = false;
        this.reRegisterStudent = false;
        this.assignStudentData = true;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        this.modalService.open(content, {'size': 'xl'});
    }


    submitReRegister() {
        this.submitted = true;
        if (!this.reRegisterForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'student_year': (this.reRegisterForm.value && this.reRegisterForm.value.student_year) ? this.reRegisterForm.value.student_year : '',
                'session_id': (this.reRegisterForm.value && this.reRegisterForm.value.session_id) ? this.reRegisterForm.value.session_id : '',
                'student_id': (this.reRegisterStudentSelect && this.reRegisterStudentSelect && this.reRegisterStudentSelect['id']) ? this.reRegisterStudentSelect['id'] : ''
            };
            this.submitting = true;
            this.studentService.studentReRegisterBusiness(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.reRegisterForm.reset();
                    this.reRegisterForm.patchValue({
                        'student_year': '',
                        'session_id': '',
                        'student_id': ''
                    });
                    this.modalService.dismissAll('');
                    this.getStudents();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].student_year.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].student_year[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].session_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].session_id[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].student_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].student_id[0]);
                        return;
                    }
                }
            });
        }
    }

    submitQuickLead() {
        this.submitted = true;
        if (!this.addQuickLeadForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'session_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.session_id) ? this.addQuickLeadForm.value.session_id : '',
                'stage_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.stage_id) ? this.addQuickLeadForm.value.stage_id : '',
                'name': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.name) ? this.addQuickLeadForm.value.name : '',
                'email': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.email) ? this.addQuickLeadForm.value.email : '',
                'contact': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.contact) ? this.addQuickLeadForm.value.contact : '',
                'program_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.program_id) ? this.addQuickLeadForm.value.program_id : '',
                'course_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.course_id) ? this.addQuickLeadForm.value.course_id : '',
                'branch_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.branch_id) ? this.addQuickLeadForm.value.branch_id : '',
                'student_year': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.student_year) ? this.addQuickLeadForm.value.student_year : '',
                'type': 'ADDED_BY_CENTER'
                //'added_by': this.user['userId']
            };
            /*this.pcbMappingService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.addQuickLeadForm.reset();
                        this.addQuickLeadForm.patchValue({
                            'program_id': '',
                            'course_id': '',
                            'branch_id': '',

                        });
                    }
                },*/
            this.submitting = true;
            this.studentService.studentRegisterBusiness(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.addQuickLeadForm.reset();
                    this.addQuickLeadForm.patchValue({
                        'university_id': '',
                        'stage_id': '',
                        'name': '',
                        'email': '',
                        'contact': '',
                        'program_id': '',
                        'course_id': '',
                        'branch_id': '',
                        'student_year': ''
                    });
                    this.modalService.dismissAll('');
                    // this.getStudents();
                    this.navigateToUrl('admission-form/' + data.id);
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }

    submitBulkUpload() {
        if (this.bulkStudentData && this.bulkStudentData.length > 0) {
            let repeatedEmail = Helper.repeatedObjects(this.bulkStudentData, 'email');
            let repeatedContact = Helper.repeatedObjects(this.bulkStudentData, 'contact');

            this.bulkStudentData.forEach(data => {
                data['errors']['name_message'] = false;
                data['errors']['email_message'] = false;
                data['errors']['contact_message'] = false;
                data['errors']['course_message'] = false;
                data['errors']['email_duplicate_message'] = false;
                data['errors']['contact_duplicate_message'] = false;
            });

            this.bulkStudentData.forEach(data => {
                if (repeatedEmail && repeatedEmail.length > 0) {
                    repeatedEmail.forEach(dt => {
                        if (dt.email === data.email) {
                            data['errors']['email_duplicate_message'] = 'Duplicate email address';
                        }
                    });
                }

                if (repeatedContact && repeatedContact.length > 0) {
                    repeatedContact.forEach(dt => {
                        if (dt.contact === data.contact) {
                            data['errors']['contact_duplicate_message'] = 'Duplicate contact number';
                        }
                    });
                }

                if (data && data.name === '') {
                    data['errors']['name_message'] = 'Name is required';
                }

                if (data && data.email !== '') {
                    if (!Helper.validateEmail(data.email)) {
                        data['errors']['email_duplicate_message'] = false;
                        data['errors']['email_message'] = 'Invalid email Format';
                    }
                } else {
                    data['errors']['email_duplicate_message'] = false;
                    data['errors']['email_message'] = 'Email is required';
                }

                if (data && data.course_id === '') {
                    data['errors']['course_message'] = 'Course is required';
                }

                if (data && data.contact !== '') {
                    if (!Helper.validateContact(data.contact)) {
                        data['errors']['contact_duplicate_message'] = false;
                        data['errors']['contact_message'] = 'Invalid contact Format';
                    }
                } else {
                    data['errors']['contact_duplicate_message'] = false;
                    data['errors']['contact_message'] = 'Contact is required';
                }
            });

            let errorCount = 0;
            this.bulkStudentData.forEach(data => {
                if (data && data['errors'] && (data['errors']['name_message'] || data['errors']['email_message'] || data['errors']['contact_message'] || data['errors']['course_message'] || data['errors']['email_duplicate_message'] || data['errors']['contact_duplicate_message'])) {
                    errorCount++;
                }
            });

            if (!errorCount) {
                this.submitting = true;
                this.submitted = true;
                this.showUpdatingDataLoader = true;
                let universityId = (this.universities && this.universities.length === 1) ? this.universities[0]['university'].id : ((this.bulkUniversityForm && this.bulkUniversityForm.value) ? this.bulkUniversityForm.value.university_id : '');
                this.studentService.studentBulkRegister({
                    'university_id': universityId,
                    'type': 'ADDED_BY_COUNSELLOR',
                    'students': this.bulkStudentData
                }).subscribe(data => {
                    this.submitting = false;
                    this.submitted = false;
                    this.showUpdatingDataLoader = false;
                    this.bulkUniversityForm.reset();
                    this.bulkUniversityForm.patchValue({
                        'university_id': ''
                    });
                    this.getStudents();
                    this.modalService.dismissAll('');
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    this.showUpdatingDataLoader = false;
                });
            }
        } else {
            this.alertService.showErrors('No student data found');
        }
    }

    submitBulkProId() {
        if (this.bulkProIdData && this.bulkProIdData.length > 0) {
            let repeatedApplicationNo = Helper.repeatedObjects(this.bulkProIdData, 'application_no');
            let repeatedProId = Helper.repeatedObjects(this.bulkProIdData, 'pro_id');

            this.bulkProIdData.forEach(data => {
                data['errors']['application_no_message'] = false;
                data['errors']['pro_id_message'] = false;
                data['errors']['application_no_duplicate_message'] = false;
                data['errors']['pro_id_duplicate_message'] = false;
            });

            this.bulkProIdData.forEach(data => {
                if (repeatedApplicationNo && repeatedApplicationNo.length > 0) {
                    repeatedApplicationNo.forEach(dt => {
                        if (dt.application_no === data.application_no) {
                            data['errors']['application_no_duplicate_message'] = 'Duplicate application no';
                        }
                    });
                }

                if (repeatedProId && repeatedProId.length > 0) {
                    repeatedProId.forEach(dt => {
                        if (dt.pro_id === data.pro_id) {
                            data['errors']['pro_id_duplicate_message'] = 'Duplicate pro id';
                        }
                    });
                }
            });

            let errorCount = 0;
            this.bulkProIdData.forEach(data => {
                if (data && data['errors'] && (data['errors']['application_no_duplicate_message'] || data['errors']['pro_id_duplicate_message'])) {
                    errorCount++;
                }
            });
            if (!errorCount) {
                this.submitting = true;
                this.submitted = true;
                this.showUpdatingDataLoader = true;
                this.studentService.studentBulkProId({'students': this.bulkProIdData}).subscribe(data => {
                    this.submitting = false;
                    this.submitted = false;
                    this.showUpdatingDataLoader = false;
                    this.getStudents();
                    this.selectedStudentData = undefined;
                    this.reRegisterStudent = undefined;
                    this.assignStudentData = false;
                    this.quickLeadAdd = false;
                    this.reRegisterStudent = false;
                    this.bulkUploadAdd = false;
                    this.bulkUploadProId = false;
                    this.selectedstudentDeleteData = undefined;
                    this.openDeleteModal = false;
                    //this.bulkStudentData = [];
                    this.bulkProIdData = [];
                    this.modalService.dismissAll('');
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    this.showUpdatingDataLoader = false;
                });
            } else {
                this.alertService.showErrors('Error in uploaded excel, please check and upload again');
            }
        } else {
            this.alertService.showErrors('No student data found');
        }
    }

    navigateToUrl(url) {
        this.router.navigateByUrl(url);
    }

    onAssignPageChange(page) {
        this.pagination.page = page;
        if (this.user && this.user['user_type'] === 'SUPER_ADMIN') {
            this.getAdmins(this.pagination);
        } else if (this.user && this.user['user_type'] === 'ADMIN') {
            this.getHeadCounsellors(this.pagination);
        } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.getCounsellors(this.pagination);
        }
    }

    chooseAssignable(data, type, index, status) {
        if (type === 'ADMIN') {
            if (this.admins && this.admins.length > 0) {
                this.admins.forEach(admin => {
                    admin['assignable'] = false;
                });
                this.admins[index]['assignable'] = true;
                this.selectAssignable = (status) ? data : undefined;
            }
        } else if (type === 'HEAD_COUNSELLOR') {
            if (this.headCounsellors && this.headCounsellors.length > 0) {
                this.headCounsellors.forEach(headCounsellor => {
                    headCounsellor['assignable'] = false;
                });
                this.headCounsellors[index]['assignable'] = true;
                this.selectAssignable = (status) ? data : undefined;
            }
        } else if (type === 'COUNSELLOR') {
            if (this.counsellors && this.counsellors.length > 0) {
                this.counsellors.forEach(counsellor => {
                    counsellor['assignable'] = false;
                });
                this.counsellors[index]['assignable'] = true;
                this.selectAssignable = (status) ? data : undefined;
            }
        }
    }

    checkStudent() {
        if (this.tabs && this.tabs.length > 0) {
            let checkedData = [];
            this.tabs.forEach(tab => {
                if (tab && tab['data'] && tab['data'].length > 0) {
                    tab['data'].forEach(data => {
                        if (data && data['isChecked']) {
                            checkedData.push(data);
                        }
                    });
                }
            });
            this.studentChecked = checkedData && checkedData.length > 0;
        }
    }

    assignToStudent() {
        if (this.tabs && this.tabs.length > 0) {
            let checkedData = [];
            this.tabs.forEach(tab => {
                if (tab && tab['data']) {
                    tab['data'].forEach(data => {
                        if (data && data['isChecked']) {
                            checkedData.push(data);
                        }
                    });
                }
            });
            let counsellorType = 'Admin';
            let selectedAssignableId = (this.selectAssignable) ? this.selectAssignable.id : '';
            if (this.user && this.user['user_type'] === 'ADMIN') {
                counsellorType = 'Head Counsellor';
                selectedAssignableId = (this.selectAssignable && this.selectAssignable['user']) ? this.selectAssignable['user'].id : '';
            } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
                counsellorType = 'Counsellor';
                selectedAssignableId = (this.selectAssignable && this.selectAssignable['counsellor']) ? this.selectAssignable['counsellor'].id : '';
            }
            if (this.selectAssignable) {
                let students = [];
                let studentUniversities = [];
                if (checkedData && checkedData.length > 0) {
                    checkedData.forEach(student => {
                        students.push(student.id);
                        studentUniversities.push(student['university'].id);
                    });
                    if (students && students.length > 0) {
                        const params = {
                            'assign_to': selectedAssignableId,
                            'students': (students && students.length > 0) ? JSON.stringify(students) : ''
                        };
                        if (this.user && this.user['user_type'] && this.user['user_type'] === 'ADMIN') {
                            let universityExists = false;
                            if (this.selectAssignable && this.selectAssignable['head_counsellor_universities'] && this.selectAssignable['head_counsellor_universities'].length > 0) {
                                let assignableUniversities = [];
                                this.selectAssignable['head_counsellor_universities'].forEach(head_counsellor_university => {
                                    if (head_counsellor_university && head_counsellor_university['university']) {
                                        assignableUniversities.push(head_counsellor_university['university'].id);
                                    }
                                });
                                if (studentUniversities && assignableUniversities && assignableUniversities.length > 0 && studentUniversities.length > 0) {
                                    studentUniversities = Helper.removeDuplicates(studentUniversities);
                                    // universityExists = Helper.checkEqualArray(assignableUniversities, studentUniversities);
                                    universityExists = studentUniversities.every(function (val) {
                                        return assignableUniversities.indexOf(val) >= 0;
                                    });
                                }
                            }
                            if (universityExists) {
                                this.studentService.assignStudent(params).subscribe(data => {
                                    if (data) {
                                        this.getStudents();
                                        this.getTabData({
                                            'id': 'Unassigned',
                                            'name': 'Unassigned',
                                            'data': []
                                        }, 1);
                                        this.modalService.dismissAll('');
                                    }
                                });
                            } else {
                                this.alertService.showErrors('Student\'s Univeristy is not assigned to Head Counsellor');
                            }
                        } else {
                            this.studentService.assignStudent(params).subscribe(data => {
                                if (data) {
                                    this.getStudents();
                                    this.getTabData({
                                        'id': 'Unassigned',
                                        'name': 'Unassigned',
                                        'data': []
                                    }, 1);
                                    this.modalService.dismissAll('');
                                }
                            });
                        }
                    } else {
                        this.alertService.showErrors('Please choose students to assign ' + counsellorType);
                        return;
                    }
                } else {
                    this.alertService.showErrors('Please choose students to assign ' + counsellorType);
                    return;
                }
            } else {
                this.alertService.showErrors('Please choose ' + counsellorType + ' to assign students');
                return;
            }
        }
    }

    onStudentPageChange(page) {
        this.studentPagination.page = page;
        this.getStudents();
    }

    onFileChange(e, type) {
        this.onFileChanged = e;
        this.studentService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'STUDENT_BULK_UPLOAD') {
                this.studentBulkUpload(data['data'].url);
            } else if (type === 'STUDENT_BULK_PRO_ID_UPLOAD') {
                this.studentBulkProIdUpload(data['data'].url);
            }
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    studentBulkUpload(url) {
        this.showFetchingDataLoader = true;
        this.studentService.studentBulkUpload({'url': url}).subscribe(data => {
            if (data) {
                this.bulkStudentData = data;
                this.showFetchingDataLoader = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
            this.showFetchingDataLoader = false;
        });
    }

    studentBulkProIdUpload(url) {
        this.showFetchingDataLoader = true;
        this.studentService.studentBulkProIdUpload({'url': url}).subscribe(data => {
            if (data) {
                this.bulkProIdData = data;
                this.showFetchingDataLoader = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
            this.showFetchingDataLoader = false;
        });
    }

    changeTransactionModel(content, data) {
        this.transactionData = data;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        this.modalService.open(content, {'size': 'xl'});
    }

    removeStudent(index) {
        this.bulkStudentData.splice(index, 1);
    }

    removeProId(index) {
        this.bulkProIdData.splice(index, 1);
    }

    newUpload() {
        this.bulkStudentData = [];
    }

    downloadSample() {
        window.open('assets/docs/students.xlsx', '_blank');
    }

    goToTransactionReceipt(transaction_no) {
        this.studentService.getStudentReceiptUrl(this.transactionData['personal_info'].student_id, {'transaction_no': transaction_no}).subscribe(data => {
            window.open(data['url'], '_blank');
        });
    }

    showSearch() {
        this.openSearch = !this.openSearch;
    }

    search() {
        this.get(true);
    }

    getStudentSession() {
        if (this.businessCenterDetail && this.businessCenterDetail['business_admin_detail'] && this.businessCenterDetail['business_admin_detail'].university_id) {
            this.getStudentSessionData(this.businessCenterDetail['business_admin_detail'].university_id);
        } else if (this.businessHeadCenterDetail && this.businessHeadCenterDetail['business_admin_detail'] && this.businessHeadCenterDetail['business_admin_detail'].university_id) {
            this.getStudentSessionData(this.businessHeadCenterDetail['business_admin_detail'].university_id);
        } else if (this.businessAdminDetail && this.businessAdminDetail['university_id']) {
            this.getStudentSessionData(this.businessAdminDetail['university_id']);
        }
    }

    getStudentSessionData(universityId) {
        this.universitySettingService.getByAdmin(universityId, {}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentSessionYears = data['data'];
                if (this.studentSessionYears && this.studentSessionYears.length > 0) {
                    let sessionYear = {};
                    this.studentSessionYears.forEach(session => {
                        if (session && session['session_default'] === 'ACTIVE') {
                            sessionYear = session;
                        }
                    });
                    this.patchFormSession(universityId, sessionYear);
                }
            }
        });
    }

    patchFormSession(universityId, data) {
        this.get(data.id);
        this.selectedSession = data;
        this.studentSessionForm.patchValue({
            'student_session_year': (data && data.id) ? data.id : ''
        });
        this.addQuickLeadForm.patchValue({
            'session_id': (data && data.id) ? data.id : ''
        });
        this.getUniversityCourses(universityId);
        this.getProgram(universityId);
        this.getStudentPrograms(universityId);
        this.reRegistrationStatus();
    }

    reRegistrationStatus() {
        if (this.studentSessionYears && this.studentSessionYears.length > 0) {
            this.showReRegistration = !(this.studentSessionYears && this.studentSessionYears[0] && this.studentSessionYears[0]['session_default'] && this.studentSessionYears[0]['session_default'] === 'ACTIVE');
            if (this.showReRegistration) {
                let reRegistrationSession = [];
                this.studentSessionYears.forEach(session => {
                    if (session && session['re_registration_status'] === 'ACTIVE') {
                        reRegistrationSession.push(session);
                    }
                });
                this.reRegistrationSession = reRegistrationSession;
            }
        }
    }

    changeStudentUniversity(event) {
        if (event && event.target && event.target.value) {
            this.getStudentSessionData(event.target.value);
        }
    }

    changeStudentSession(event) {
        if (event && event.target && event.target.value && this.studentSessionYears && this.studentSessionYears.length > 0) {
            let selectedSession = {};
            this.studentSessionYears.forEach(session => {
                if (parseInt(event.target.value) === session.id) {
                    selectedSession = session;
                }
            });
            this.selectedSession = selectedSession;
            this.get();
        }
    }

    getCourses() {
        this.courses = [];
        this.courseService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.courses = data['data'];
            } else {
                this.courses = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getBusinessHeadCenters() {
        this.businessHeadCenterService.get(this.user['userId'], {'page': -1}).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.headCenters = data['data'];
            } else {
                this.headCenters = [];
            }
        }, error => {
            this.headCenters = [];
        });
    }

    getBusinessCenter(event) {
        let headCenterId = (event && event.target && event.target.value) ? event.target.value : '';
        if (headCenterId) {
            this.businessCenterService.get(this.user['userId'], headCenterId, {'page': -1}).subscribe(data => {
                if (data && data['data'] && data['data'].length > 0) {
                    this.businessCenters = data['data'];
                } else {
                    this.businessCenters = [];
                }
            }, error => {
                this.businessCenters = [];
            });
        } else {
            this.alertService.showErrors('Please choose head center before choosing Business Center');
        }
    }

    getProgram(universityId) {
        this.programs = [];
        this.programService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.programs = data['data'];
            } else {
                this.programs = [];
            }
        });
    }

    getStudentPrograms(universityId) {
        this.programs = [];
        this.programService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentPrograms = data['data'];
            } else {
                this.studentPrograms = [];
            }
        });
    }

    getStudenProgramCourses(event) {
        this.studentCourses = [];
        this.studentBranches = [];
        this.pcbMappingService.getByLanding(event.target.value, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentCourses = Helper.uniqByKeepLast(data['data'], it => it.course_id);
            }
        });
    }

    getStudentCourseBranch(event) {
        if (event) {
            this.studentBranches = [];
            this.pcbMappingService.getBranchByLanding(event.target.value, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    const studentBranches = [];
                    data['data'].forEach(dt => {
                        if (dt && dt.branch_id) {
                            studentBranches.push(dt);
                        }
                    });
                    this.studentBranches = Helper.uniqByKeepLast(studentBranches, it => it.branch_id);
                }
            });
        } else {
            this.alertService.showErrors('Something went wrong, please select course again');
        }
    }

    /*getBranch() {
        this.branches = [];
        this.branchService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.branches = data['data'];
            } else {
                this.branches = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }*/

    /*getPCBMapping(universityId) {
        this.pcbMappingService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.courses = data['data'];
                this.programs = data['data'];
                this.branches = data['data'];
            } else {
                this.courses = data[''];
                this.programs = data[''];
                this.branches = data[''];
            }
        });
    }*/

    getStudentYear(event) {
        if (event && event.target.value && parseInt(this.addQuickLeadForm.value['stage_id']) === 12) {
            this.showStudentYear = (this.addQuickLeadForm.value && this.addQuickLeadForm.value['stage_id'] && parseInt(this.addQuickLeadForm.value['stage_id']) === 12);
            if (this.showStudentYear) {
                this.getPCBMapping();
            }
        }
    }

    getPCBMapping() {
        let params = {
            'program_id': (this.addQuickLeadForm && this.addQuickLeadForm.value && this.addQuickLeadForm.value.program_id) ? this.addQuickLeadForm.value.program_id : '',
            'course_id': (this.addQuickLeadForm && this.addQuickLeadForm.value && this.addQuickLeadForm.value.course_id) ? this.addQuickLeadForm.value.course_id : '',
            'branch_id': (this.addQuickLeadForm && this.addQuickLeadForm.value && this.addQuickLeadForm.value.branch_id) ? this.addQuickLeadForm.value.branch_id : ''
        };
        this.pcbMappingService.detailByPCB(params).subscribe(data => {
            if (data && data.id) {
                this.getCourseCurriculum(data.id);
            }
        });
    }

    getReRegisterPCBMapping(programId, courseId, branchId) {
        let params = {
            'program_id': programId,
            'course_id': courseId,
            'branch_id': branchId
        };
        this.pcbMappingService.detailByPCB(params).subscribe(data => {
            if (data && data.id) {
                this.getCourseCurriculum(data.id);
            }
        });
    }

    getCourseCurriculum(pcbMappingId) {
        this.courseCurriculumService.detailByPCBId(pcbMappingId).subscribe(data => {
            if (data) {
                let durations = (data && data['duration']) ? data['duration'] : 0;
                this.studentYears = Helper.makeArrayFromNumber(durations);
                this.studentReRegisterYears = Helper.makeArrayFromNumber(durations, true);
            }
        });
    }

    downloadExcel(type) {
        this.studentService.download(this.selectedSession.id, {'excel_type': type}).subscribe(data => {
            if (data && data['url']) {
                window.open(data['url'], '_blank');
            }
        });
    }

    downloadStudentPhotographModal(content) {
        this.studentPhotographDownloadForm.patchValue({
            'from': '',
            'to': ''
        });
        this.selectedStudentData = undefined;
        this.reRegisterStudent = undefined;
        this.assignStudentData = false;
        this.quickLeadAdd = false;
        this.reRegisterStudent = false;
        this.bulkUploadAdd = false;
        this.bulkUploadProId = false;
        this.selectedstudentDeleteData = undefined;
        this.openDeleteModal = false;
        this.openStudentPhotographDownloadModal = true;
        this.openStudentPhotographDownloadModalProgress = false;
        this.downloadZipUrl = undefined;
        this.modalService.open(content, {'size': 'sm'});
    }

    goToPrintForm(data) {
        let url = environment.appUrl + 'sgvu-admission-form/' + data.id;
        window.open(url, '_blank');
    }

    downloadPhotograph() {
        this.submitted = true;
        if (!this.studentPhotographDownloadForm.valid) {
            return;
        }
        if (this.submitted) {
            let params = {
                'from': (this.studentPhotographDownloadForm && this.studentPhotographDownloadForm.value) ? this.studentPhotographDownloadForm.value.from : '',
                'to': (this.studentPhotographDownloadForm && this.studentPhotographDownloadForm.value) ? this.studentPhotographDownloadForm.value.to : ''
            };
            this.submitting = true;
            this.openStudentPhotographDownloadModal = false;
            this.openStudentPhotographDownloadModalProgress = true;
            this.studentService.downloadStudentPhotograph(this.selectedSession.id, params).subscribe(data => {
                if (data && data['url']) {
                    this.submitting = false;
                    let url = environment.appUrl + data['url'];
                    this.openStudentPhotographDownloadModalProgress = false;
                    this.downloadZipUrl = url;
                } else {
                    this.openStudentPhotographDownloadModalProgress = false;
                    this.submitting = false;
                }
            }, error => {
                this.submitting = false;
                this.openStudentPhotographDownloadModalProgress = false;
                this.openStudentPhotographDownloadModal = true;
                this.downloadZipUrl = undefined;
                this.alertService.showErrors('Something went wrong, please refresh and try again');
            });
        }
    }

    studentDocumentDownload(data) {
        data['student_document_download_loader'] = true;
        this.studentService.downloadStudentDocument(data.id).subscribe(data => {
            if (data && data['url']) {
                let url = environment.appUrl + data['url'];
                //data['student_document_download_loader'] = false;
                this.getStudents({}, true);
                window.open(url, '_blank');
            }
        }, error => {
            if (error && error['error'] && error['error']['error']) {
                //data['student_document_download_loader'] = false;
                this.alertService.showErrors(error['error']['error']);
                this.getStudents();
            }
        });
    }

    downloadStudentPhotographZip() {
        window.open(this.downloadZipUrl, '_blank');
    }

    goToProcessStudents() {
        this.router.navigateByUrl('students/process-students');
    }

    goToAccounting() {
        this.router.navigateByUrl('accounting');
    }

    goToReports() {
        this.router.navigateByUrl('reports');
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
}
