<div class="content-wrapper">
    <div class="content-body">
        <section id="students">
            <div class="row mb-10px">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <h2 class="mt-5px">Reports</h2>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="['BUSINESS_HEAD_CENTER', 'ACCOUNTING'].indexOf(user['user_type']) !== -1">
                    <button class="btn btn-primary pull-right" (click)="goTo('reports/centers-report')"
                            *ngIf="['BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-chart-bar mr-10px"></i>Centers' Report
                    </button>
                    <button class="btn btn-primary pull-right" (click)="goTo('reports/head-centers-report')"
                            *ngIf="['ACCOUNTING'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-chart-bar mr-10px"></i>Head Centers' Report
                    </button>
                    <button class="btn btn-primary pull-right mr-10px" (click)="goTo('reports/centers-report')"
                            *ngIf="['ACCOUNTING'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-chart-bar mr-10px"></i>MS Centers' Report
                    </button>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" *ngIf="['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" *ngIf="['BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                    <form class="row" novalidate [formGroup]="studentSessionForm">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <select class="form-control" formControlName="student_session_year"
                                    (change)="changeSettings($event)">
                                <option class="text-center" [value]="setting.id"
                                        *ngFor=" let setting of settings">{{setting?.session_name}}
                                    - {{setting?.session_year}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row mb-10px" *ngIf="['BUSINESS_HEAD_CENTER', 'ACCOUNTING'].indexOf(user['user_type']) !== -1">
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <form class="row" novalidate [formGroup]="studentSessionForm">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <select class="form-control" formControlName="student_session_year"
                                    (change)="changeSettings($event)">
                                <option class="text-center" [value]="setting.id"
                                        *ngFor=" let setting of settings">{{setting?.session_name}}
                                    - {{setting?.session_year}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row match-height">
                <div class="col-xl-12 col-md-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header">
                            <h4 class="card-title">Students</h4>
                        </div>
                        <div class="card-body statistics-body">
                            <div class="row">
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(pendingStudents)"></i>
                                                </span>
                                                <span *ngIf="isNumber(pendingStudents)">{{pendingStudents | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Pending Students</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(processableStudents)"></i>
                                                </span>
                                                <span *ngIf="isNumber(processableStudents)">{{processableStudents | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Processable Students</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(processingStudents)"></i>
                                                </span>
                                                <span *ngIf="isNumber(processingStudents)">{{processingStudents | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Processing Students</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(processedStudents)"></i>
                                                </span>
                                                <span *ngIf="isNumber(processedStudents)">{{processedStudents | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Processed Students</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row match-height" *ngIf="user && ['BUSINESS_ADMIN', 'ACCOUNTING', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1">
                <div class="col-xl-12 col-md-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header">
                            <h4 class="card-title">Centers | Amounts</h4>
                        </div>
                        <div class="card-body statistics-body">
                            <div class="row">
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(totalCenter)"></i>
                                                </span>
                                                <span *ngIf="isNumber(totalCenter)">{{totalCenter | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Total Centers</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(centerPendingAmount)"></i>
                                                </span>
                                                <span *ngIf="isNumber(centerPendingAmount)">&#8377;{{centerPendingAmount | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Pending Amount</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(centerProcessableAmount)"></i>
                                                </span>
                                                <span *ngIf="isNumber(centerProcessableAmount)">&#8377;{{centerProcessableAmount | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Processable Amount</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <small *ngIf="!isNumber(centerProcessedAmount) && !centerProcessedAmountLoader"
                                                       class="text-info cursor-pointer"
                                                       (click)="centersProcessedAmount()">
                                                    Click to view processed amount
                                                </small>
                                                <span *ngIf="centerProcessedAmountLoader">
                                                    <i class="fas fa-spinner fa-pulse text-info cursor-pointer"
                                                       *ngIf="!isNumber(centerProcessedAmount)"></i>
                                                </span>
                                                <span class="cursor-pointer" *ngIf="isNumber(centerProcessedAmount)">&#8377;{{centerProcessedAmount | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Processed Amount</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row match-height">
                <div class="col-xl-12 col-md-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header">
                            <h4 class="card-title">Transactions</h4>
                        </div>
                        <div class="card-body statistics-body">
                            <div class="row">
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(totalReceiptsAmount)"></i>
                                                </span>
                                                <span *ngIf="isNumber(totalReceiptsAmount)">&#8377;{{totalReceiptsAmount | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Receipts Total Amount</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(pendingReceiptsAmount)"></i>
                                                </span>
                                                <span *ngIf="isNumber(pendingReceiptsAmount)">&#8377;{{pendingReceiptsAmount | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Receipts Pending Amount</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(acceptedReceiptsAmount)"></i>
                                                </span>
                                                <span *ngIf="isNumber(acceptedReceiptsAmount)">&#8377;{{acceptedReceiptsAmount | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Receipts Accepted Amount</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i class="fas fa-chart-area f_20"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                <span>
                                                    <i class="fas fa-spinner fa-pulse text-info"
                                                       *ngIf="!isNumber(rejectedReceiptsAmount)"></i>
                                                </span>
                                                <span *ngIf="isNumber(rejectedReceiptsAmount)">&#8377;{{rejectedReceiptsAmount | IndianNumber}}</span>
                                            </h4>
                                            <p class="card-text font-small-3 mb-0">Rejected Receipts</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
