import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessCenterService} from '../../../shared/services/business-center.service';
import {BusinessHeadCenterService} from '../../../shared/services/business-head-center.service';
import {UniversityService} from '../../../shared/services/university.service';
import {AlertService} from '../../../shared/services/alert.service';
import {StorageService} from '../../../shared/services/storage.service';
import {UniversitySettingService} from '../../../shared/services/university-setting.service';
import {AppConstants} from '../../../shared/constants/app-constant';

@Component({
    selector: 'app-centers-report',
    templateUrl: './centers-report.component.html',
    styleUrls: ['./centers-report.component.scss']
})
export class CentersReportComponent implements OnInit {
    showSearch = false;
    assignedUniversities: any;
    id: any;
    data: any;
    updatePasswordForm: FormGroup;
    updateWalletForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };
    modalType: any;
    businessCenters: any = [];
    universities: any = [];
    selectedCounsellor: any;
    contentHeader: object;
    user: any;
    headCenterId: any;
    adminId: any;
    businessWalletData: any;
    studentSessionForm: FormGroup;
    settings: any = [];
    businessHeadCenterDetail: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private businessCenterService: BusinessCenterService,
                private businessHeadcenterService: BusinessHeadCenterService,
                private universityService: UniversityService,
                public alertService: AlertService,
                private activatedRoute: ActivatedRoute,
                private universitySettingService: UniversitySettingService) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.updateWalletForm = this.fb.group({
            'amount': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
        });
        this.studentSessionForm = this.fb.group({
            'student_session_year': ['']
        });
        /*if (this.headCenterId) {
            this.getBusinessHeadCenterDetail();
            //this.getBusinessHeadCentersCenters();
            //this.getUserUniversities();
        } else {
            this.get();
            //this.getUniversities();
        }*/
        if (this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenterDetail();
        } else if (this.user['user_type'] === 'ACCOUNTING') {
            this.getSettings();
        } else {
            this.router.navigateByUrl('dashboard');
        }
    }

    getBusinessHeadCenterDetail() {
        this.businessHeadcenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessHeadCenterDetail = data;
            this.getSettings();
        });
    }

    getSettings() {
        let universityId = (this.user && this.user['user_type'] && this.user['user_type'] === 'ACCOUNTING') ? AppConstants.GYAN_VIHAR_UNINVERSITY_ID : this.businessHeadCenterDetail['business_admin_detail']['university_id'];
        this.universitySettingService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.settings = data['data'];
                this.patchSettingForm();
            } else {
                this.settings = [];
            }
        });
    }

    patchSettingForm() {
        if (this.settings && this.settings.length > 0) {
            let selectedSetting: any;
            this.settings.forEach(setting => {
                if (setting && setting['session_default'] && setting['session_default'] === 'ACTIVE') {
                    selectedSetting = setting;
                }
            });
            this.studentSessionForm.patchValue({
                'student_session_year': selectedSetting.id
            });
            this.get();
        }
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            if (this.headCenterId) {
                this.getBusinessHeadCenter({page: 1, total: null, perpage: 50, pages: null});
                this.get({page: 1, total: null, perpage: 50, pages: null});
            }
        }
    }

    updatePasswordModal(content, id, type) {
        this.id = id;
        this.businessWalletData = undefined;
        this.modalType = type;
        this.updatePasswordForm.patchValue({
            'password': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        params.setting_id = (this.studentSessionForm.value && this.studentSessionForm.value.student_session_year) ? this.studentSessionForm.value.student_session_year : '';
        this.submitting = true;
        this.businessCenters = [];
        let user = StorageService.getItem('self');
        if (user && user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.businessCenterService.getBusinessCentersReport(this.user['userId'], params).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.businessCenters = data['data'];
                    if (this.businessCenters && this.businessCenters.length > 0) {
                        this.businessCenters.forEach(businessCenter => {
                            let sessionStudents = [];
                            if (businessCenter && businessCenter['center_students'] && businessCenter['center_students'].length > 0) {
                                businessCenter['center_students'].forEach(center_student => {
                                    if (center_student && this.studentSessionForm.value && this.studentSessionForm.value.student_session_year && center_student['admission_session'] == this.studentSessionForm.value.student_session_year) {
                                        sessionStudents.push(center_student);
                                    }
                                });
                            }
                            businessCenter['student_count'] = sessionStudents.length;
                            if (sessionStudents && sessionStudents.length > 0) {
                                let studentTotal = this.getStudentTotal(sessionStudents);
                                businessCenter['pending_amount'] = studentTotal['student_pending'];
                                businessCenter['processable_amount'] = studentTotal['student_processable'];
                                businessCenter['processed_amount'] = studentTotal['student_processed'];
                            }
                        });
                    }
                    this.pagination = {
                        page: data.current_page,
                        total: data.total,
                        perpage: data.per_page,
                        pages: data.last_page,
                    };
                } else {
                    this.businessCenters = [];
                }
                this.submitting = false;
            }, error => {
                this.submitting = false;
            });
        } else if (user && user['user_type'] === 'ACCOUNTING') {
            this.businessCenterService.getBusinessCentersReport(AppConstants.MS_HEAD_CENTER_ID, params).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.businessCenters = data['data'];
                    if (this.businessCenters && this.businessCenters.length > 0) {
                        this.businessCenters.forEach(businessCenter => {
                            let sessionStudents = [];
                            if (businessCenter && businessCenter['center_students'] && businessCenter['center_students'].length > 0) {
                                businessCenter['center_students'].forEach(center_student => {
                                    if (center_student && this.studentSessionForm.value && this.studentSessionForm.value.student_session_year && center_student['admission_session'] == this.studentSessionForm.value.student_session_year) {
                                        sessionStudents.push(center_student);
                                    }
                                });
                            }
                            businessCenter['student_count'] = sessionStudents.length;
                            if (sessionStudents && sessionStudents.length > 0) {
                                let studentTotal = this.getStudentTotal(sessionStudents);
                                businessCenter['pending_amount'] = studentTotal['student_pending'];
                                businessCenter['processable_amount'] = studentTotal['student_processable'];
                                businessCenter['processed_amount'] = studentTotal['student_processed'];
                            }
                        });
                    }
                    this.pagination = {
                        page: data.current_page,
                        total: data.total,
                        perpage: data.per_page,
                        pages: data.last_page,
                    };
                } else {
                    this.businessCenters = [];
                }
                this.submitting = false;
            }, error => {
                this.submitting = false;
            });
        }
    }

    getBusinessHeadCenter(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.businessCenters = [];
        /*this.businessHeadcenterService.getBusinessHeadCenters(this.headCenterId, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.businessCenters = data['data'];
                if (this.businessCenters && this.businessCenters.length > 0) {
                    this.businessCenters.forEach(counsellor => {
                        counsellor['count'] = counsellor['counsellor_universities'].length;
                    });
                }
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.businessCenters = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });*/
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.businessCenterService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.businessCenterService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    updatewallet(content, data) {
        this.businessWalletData = data;
        this.modalType = undefined;
        this.updateWalletForm.patchValue({
            'amount': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    sharePercentage(data: any) {
        if (!this.adminId) {
            this.router.navigateByUrl('business-head-center/' + this.headCenterId + '/business-center/' + data.id + '/business-center-share-percentage');
        } else {
            this.router.navigateByUrl('business-admin/' + this.adminId + 'business-head-center/' + this.headCenterId + '/business-center/' + data.id + '/business-center-share-percentage');
        }
    }

    addWalletAmount() {
        this.submitted = true;
        if (!this.updateWalletForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'amount': (this.updateWalletForm.value && this.updateWalletForm.value.amount) ? this.updateWalletForm.value.amount : 0
            };
            this.submitting = true;
            this.businessCenterService.updateWallet(this.businessWalletData['business_center_detail']['user_id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.get();
                this.updateWalletForm.patchValue({
                    'amount': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    changeSettings(event) {
        if (event && event.target && event.target.value && this.settings && this.settings.length > 0) {
            this.get();
        }
    }

    getStudentTotal(dt) {
        let allDataPending = [];
        let allDataProcessable = [];
        let allDataProcessed = [];
        if (dt && dt.length > 0) {
            dt.forEach(data => {
                if (data && data['student_details'] && data['student_details']['status'] && data['student_details']['status'] === 'PENDING') {
                    allDataPending.push({
                        'id': (data && data['student_id']) ? data['student_id'] : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].id) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].id) ? data['student']['course_details']['branch'].id : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status':
                            (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['status']) ?
                                ((data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_UPLOADED' ? 'DOCUMENTS UPLOADED' :
                                    (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_RE_UPLOADED' ? 'DOCUMENTS RE UPLOADED' :
                                        (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_REQUIRED' ? 'DOCUMENTS REQUIRED' :
                                            data['student']['student_detail']['status'].toLowerCase())))) :
                                '-',
                        'process_status': (data && data['processing_status']) ? data['processing_status'] : '',
                        'status_id': (data && data['admission_type'] && data['admission_type']['id']) ? data['admission_type']['id'] : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student_year']) ? data['student_year'] : '',
                        'transactions': (data && data['transactions']) ? (data && data['transactions']) : [],
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['added_by_business_head_center'] && data['added_by_business_head_center']) ? data['added_by_business_head_center'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : ''
                    });
                } else if (data && data['student_details'] && data['student_details']['status'] && data['student_details']['status'] !== 'PENDING' && data['processing_status'] && data['processing_status'] === 'PROCESSABLE') {
                    allDataProcessable.push({
                        'id': (data && data['student_id']) ? data['student_id'] : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].id : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status':
                            (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['status']) ?
                                ((data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_UPLOADED' ? 'DOCUMENTS UPLOADED' :
                                    (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_RE_UPLOADED' ? 'DOCUMENTS RE UPLOADED' :
                                        (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_REQUIRED' ? 'DOCUMENTS REQUIRED' :
                                            data['student']['student_detail']['status'].toLowerCase())))) :
                                '-',
                        'process_status': (data && data['processing_status']) ? data['processing_status'] : '',
                        'status_id': (data && data['admission_type'] && data['admission_type']['id']) ? data['admission_type']['id'] : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student_year']) ? data['student_year'] : '',
                        'transactions': (data && data['transactions']) ? (data && data['transactions']) : [],
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['added_by_business_head_center'] && data['added_by_business_head_center']) ? data['added_by_business_head_center'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : ''
                    });
                } else if (data && data['student_details'] && data['student_details']['status'] && data['student_details']['status'] !== 'PENDING' && data['processing_status'] && data['processing_status'] === 'PROCESSED') {
                    allDataProcessed.push({
                        'id': (data && data['student_id']) ? data['student_id'] : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].id : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status':
                            (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['status']) ?
                                ((data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_UPLOADED' ? 'DOCUMENTS UPLOADED' :
                                    (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_RE_UPLOADED' ? 'DOCUMENTS RE UPLOADED' :
                                        (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_REQUIRED' ? 'DOCUMENTS REQUIRED' :
                                            data['student']['student_detail']['status'].toLowerCase())))) :
                                '-',
                        'process_status': (data && data['processing_status']) ? data['processing_status'] : '',
                        'status_id': (data && data['admission_type'] && data['admission_type']['id']) ? data['admission_type']['id'] : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student_year']) ? data['student_year'] : '',
                        'transactions': (data && data['transactions']) ? (data && data['transactions']) : [],
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['added_by_business_head_center'] && data['added_by_business_head_center']) ? data['added_by_business_head_center'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : ''
                    });
                }
            });
        }
        let finalArrPending = [];
        let finalArrProcessable = [];
        let finalArrProcessed = [];
        if (allDataPending && allDataPending.length > 0) {
            allDataPending.forEach(all => {
                let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                let chargeableCourseFee = 0;
                let total = 0;
                let reRegistrationPercentageActual = 0;
                let admissionPercentageActual = 0;
                let reRegistrationPercentageDisplay = 0;
                let admissionPercentageDisplay = 0;
                let prospectusFee = 0;
                let serviceCharge = 0;
                let examinationFee = 0;
                if (all.status_id === 10) {
                    if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                            if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                            }
                        });
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                        all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                            if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(all['added_by']))) {
                                admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                            }
                        });
                    }
                    chargeableCourseFee = re_registration_amount * ((reRegistrationPercentageDisplay) / 100);
                    total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                    if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'ACCOUNTING')) {
                        if (all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail']['user_id'] && all['business_center']['business_center_detail']['business_head_center_detail']['user_id'] == AppConstants.MS_HEAD_CENTER_ID) {
                            all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year))) {
                                    admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                }
                            });
                        } else {
                            all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year))) {
                                    admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                }
                            });
                        }
                    }
                } else {
                    if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                            if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by'].id))) {
                                admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                            }
                        });
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                        all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                            if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && ((parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId'])))) {
                                admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                            }
                        });
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'ACCOUNTING')) {
                        if (all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail']['user_id'] && all['business_center']['business_center_detail']['business_head_center_detail']['user_id'] == AppConstants.MS_HEAD_CENTER_ID) {
                            all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year))) {
                                    admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                }
                            });
                        } else {
                            all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year))) {
                                    admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                }
                            });
                        }
                    }
                    chargeableCourseFee = course_fee * ((admissionPercentageDisplay) / 100);
                    total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                    if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    }
                }
                finalArrPending.push({
                    'id': all.id,
                    'program_id': all.program_id,
                    'course_id': all.course_id,
                    'branch_id': all.branch_id,
                    'program': all.program,
                    'course': all.course,
                    'branch': all.branch,
                    'status': all.status,
                    'process_status': all.process_status,
                    'status_id': all.status_id,
                    'register_course_id': all.register_course_id,
                    'register_course': all.register_course,
                    'student_year': all.student_year,
                    'added_by': all.added_by,
                    'prospectus_fee': (!isNaN(prospectusFee) ? prospectusFee : (all && all['pcb_mapping'] && all['pcb_mapping']['prospectus_fee']) ? all['pcb_mapping']['prospectus_fee'] : '-'),
                    'service_charge': (!isNaN(serviceCharge) ? serviceCharge : (all && all['pcb_mapping'] && all['pcb_mapping']['service_charge']) ? all['pcb_mapping']['service_charge'] : '-'),
                    'examination_fee': (!isNaN(examinationFee) ? examinationFee : (all && all['pcb_mapping'] && all['pcb_mapping']['examination_fee']) ? all['pcb_mapping']['examination_fee'] : '-'),
                    'pcb_mapping': all.pcb_mapping,
                    'total': total,
                    'counsellor': all.counsellor,
                    'head_counsellor': all.head_counsellor,
                    'business_center': all.business_center,
                    'chargeableCourseFee': chargeableCourseFee,
                });
            });
        } else if (allDataProcessable && allDataProcessable.length > 0) {
            allDataProcessable.forEach(all => {
                let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                let chargeableCourseFee = 0;
                let total = 0;
                let reRegistrationPercentageActual = 0;
                let admissionPercentageActual = 0;
                let reRegistrationPercentageDisplay = 0;
                let admissionPercentageDisplay = 0;
                let prospectusFee = 0;
                let serviceCharge = 0;
                let examinationFee = 0;
                if (all.status_id === 10) {
                    if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                            if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                            }
                        });
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                        all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                            if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(all['added_by']))) {
                                admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                            }
                        });
                    }
                    chargeableCourseFee = re_registration_amount * ((reRegistrationPercentageDisplay) / 100);
                    total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                    if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    }
                } else {
                    if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                            if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by'].id))) {
                                admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                            }
                        });
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                        all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                            if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && ((parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId'])))) {
                                admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                            }
                        });
                    }
                    chargeableCourseFee = course_fee * ((admissionPercentageDisplay) / 100);
                    total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                    if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    }
                }
                finalArrProcessable.push({
                    'id': all.id,
                    'program_id': all.program_id,
                    'course_id': all.course_id,
                    'branch_id': all.branch_id,
                    'program': all.program,
                    'course': all.course,
                    'branch': all.branch,
                    'status': all.status,
                    'process_status': all.process_status,
                    'status_id': all.status_id,
                    'register_course_id': all.register_course_id,
                    'register_course': all.register_course,
                    'student_year': all.student_year,
                    'added_by': all.added_by,
                    'prospectus_fee': (!isNaN(prospectusFee) ? prospectusFee : (all && all['pcb_mapping'] && all['pcb_mapping']['prospectus_fee']) ? all['pcb_mapping']['prospectus_fee'] : '-'),
                    'service_charge': (!isNaN(serviceCharge) ? serviceCharge : (all && all['pcb_mapping'] && all['pcb_mapping']['service_charge']) ? all['pcb_mapping']['service_charge'] : '-'),
                    'examination_fee': (!isNaN(examinationFee) ? examinationFee : (all && all['pcb_mapping'] && all['pcb_mapping']['examination_fee']) ? all['pcb_mapping']['examination_fee'] : '-'),
                    'pcb_mapping': all.pcb_mapping,
                    'total': total,
                    'counsellor': all.counsellor,
                    'head_counsellor': all.head_counsellor,
                    'business_center': all.business_center,
                    'chargeableCourseFee': chargeableCourseFee,
                });
            });
        } else if (allDataProcessed && allDataProcessed.length > 0) {
            allDataProcessed.forEach(all => {
                let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                let chargeableCourseFee = 0;
                let total = 0;
                let reRegistrationPercentageActual = 0;
                let admissionPercentageActual = 0;
                let reRegistrationPercentageDisplay = 0;
                let admissionPercentageDisplay = 0;
                let prospectusFee = 0;
                let serviceCharge = 0;
                let examinationFee = 0;
                if (all.status_id === 10) {
                    if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                            if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                            }
                        });
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                        all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                            if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId']))) {
                                admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                            }
                        });
                    }
                    chargeableCourseFee = re_registration_amount * ((reRegistrationPercentageDisplay) / 100);
                    total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                    if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    }
                } else {
                    if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                            if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by'].id))) {
                                admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                            }
                        });
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                        all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                            if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && ((parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId'])))) {
                                admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                            }
                        });
                    }
                    chargeableCourseFee = course_fee * ((admissionPercentageDisplay) / 100);
                    total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                    if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                        all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                        all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                        all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                        all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                        all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                        all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                        all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                    }
                }
                finalArrProcessed.push({
                    'id': all.id,
                    'program_id': all.program_id,
                    'course_id': all.course_id,
                    'branch_id': all.branch_id,
                    'program': all.program,
                    'course': all.course,
                    'branch': all.branch,
                    'status': all.status,
                    'process_status': all.process_status,
                    'status_id': all.status_id,
                    'register_course_id': all.register_course_id,
                    'register_course': all.register_course,
                    'student_year': all.student_year,
                    'added_by': all.added_by,
                    'prospectus_fee': (!isNaN(prospectusFee) ? prospectusFee : (all && all['pcb_mapping'] && all['pcb_mapping']['prospectus_fee']) ? all['pcb_mapping']['prospectus_fee'] : '-'),
                    'service_charge': (!isNaN(serviceCharge) ? serviceCharge : (all && all['pcb_mapping'] && all['pcb_mapping']['service_charge']) ? all['pcb_mapping']['service_charge'] : '-'),
                    'examination_fee': (!isNaN(examinationFee) ? examinationFee : (all && all['pcb_mapping'] && all['pcb_mapping']['examination_fee']) ? all['pcb_mapping']['examination_fee'] : '-'),
                    'pcb_mapping': all.pcb_mapping,
                    'total': total,
                    'counsellor': all.counsellor,
                    'head_counsellor': all.head_counsellor,
                    'business_center': all.business_center,
                    'chargeableCourseFee': chargeableCourseFee,
                });
            });
        }

        let totalArrPending = [];
        if (finalArrPending && finalArrPending.length > 0) {
            finalArrPending.forEach(final => {
                if (final && final.total) {
                    totalArrPending.push(final.total);
                }
            });
        }

        let totalArrProcessable = [];
        if (finalArrProcessable && finalArrProcessable.length > 0) {
            finalArrProcessable.forEach(final => {
                if (final && final.total) {
                    totalArrProcessable.push(final.total);
                }
            });
        }

        let totalArrProcessed = [];
        if (finalArrProcessed && finalArrProcessed.length > 0) {
            finalArrProcessed.forEach(final => {
                if (final && final.total) {
                    totalArrProcessed.push(final.total);
                }
            });
        }

        return {
            'student_pending': (totalArrPending && totalArrPending.length > 0) ? totalArrPending.reduce((a, b) => a + b) : 0,
            'student_processable': (totalArrProcessable && totalArrProcessable.length > 0) ? totalArrProcessable.reduce((a, b) => a + b) : 0,
            'student_processed': (totalArrProcessed && totalArrProcessed.length > 0) ? totalArrProcessed.reduce((a, b) => a + b) : 0,
        };
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    downloadExcel() {
        if (this.user && this.user['user_type'] && this.user['user_type'] === 'ACCOUNTING') {
            this.businessHeadcenterService.downloadCenterReportExcel({
                'setting_id': this.studentSessionForm.value.student_session_year,
                'head_center_id': AppConstants.MS_HEAD_CENTER_ID
            }).subscribe(data => {
                if (data && data['url']) {
                    window.open(data['url'], '_blank');
                }
            });
        } else if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.businessHeadcenterService.downloadCenterReportExcel({
                'setting_id': this.studentSessionForm.value.student_session_year,
                'head_center_id': this.user['userId']
            }).subscribe(data => {
                if (data && data['url']) {
                    window.open(data['url'], '_blank');
                }
            });
        }
    }
}
