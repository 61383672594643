import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppConstants} from '../../../shared/constants/app-constant';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {StageService} from '../../../shared/services/stage.service';
import {StudentService} from '../../../shared/services/student.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SrMappingService} from '../../../shared/services/sr-mapping.service';
import {CourseService} from '../../../shared/services/course.service';
import {CounsellorService} from '../../../shared/services/counsellor.service';
import {AlertService} from '../../../shared/services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../../shared/services/admin.service';
import {HeadCounsellorService} from '../../../shared/services/head-counsellor.service';
import {UniversityService} from '../../../shared/services/university.service';
import {ProgramService} from '../../../shared/services/program.service';
import {BranchService} from '../../../shared/services/branch.service';
import {PcbMappingService} from '../../../shared/services/pcb-mapping.service';
import {CourseCurriculumService} from '../../../shared/services/course-curriculum.service';
import {UniversitySettingService} from '../../../shared/services/university-setting.service';
import {BusinessCenterService} from '../../../shared/services/business-center.service';
import {BusinessHeadCenterService} from '../../../shared/services/business-head-center.service';
import {BusinessAdminService} from '../../../shared/services/business-admin.service';
import {StorageService} from '../../../shared/services/storage.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-head-centers-students',
    templateUrl: './head-centers-students.component.html',
    styleUrls: ['./head-centers-students.component.scss']
})
export class HeadCentersStudentsComponent implements OnInit {
    id: any;
    data: any;
    tabs: any = [];
    studentDetail: any;
    selectedStudentData: any;
    updateStatusForm: FormGroup;
    updateEnrollmentForm: FormGroup;
    updateProIdForm: FormGroup;
    updateRollNoForm: FormGroup;
    addQuickLeadForm: FormGroup;
    reRegisterForm: FormGroup;
    studentPhotographDownloadForm: FormGroup;
    bulkUniversityForm: FormGroup;
    studentSessionForm: FormGroup;
    stages: any = [];
    statuses: any = AppConstants.statuses;
    reasons: any = [];
    submitted: any = false;
    submitting: any = false;
    courses = [];
    universities = [];
    user: any;
    counsellorDetail: any;
    admins: any = [];
    headCounsellors: any = [];
    counsellors: any = [];
    onFileChanged: any;
    bulkStudentData: any = [];
    showFetchingDataLoader: any = false;
    studentPagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };
    pagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };
    openSearch: any = false;
    searchUniversities = [];
    searchAdmins = [];
    searchForm: FormGroup;
    @ViewChild('navFilled') navFilled;
    active: any;
    activeTab: any = 0;
    headCounsellorDetailAdmin: any;
    headCounsellorUniversities: any = [];
    counsellorUniversities: any = [];
    counsellorHeadCounsellor: any = [];
    counsellorAdmin: any = [];
    settings: any = [];
    businessCenterDetail: any;
    businessHeadCenterDetail: any;
    businessAdminDetail: any;
    pcbmapping: any = [];
    programs = [];
    studentPrograms = [];
    branches = [];
    stageTabs: any = [];
    showReRegistration: any = false;
    reRegistrationSession: any = [];
    headCenters: any = [];
    businessCenters: any = [];

    processingStudents: any = [];
    processableStudents: any = [];
    processingStudentsCalc: any = 0;
    processableStudentsCalc: any = 0;
    activeType: any = 'PROCESSABLE';
    processableCredits: any = 0;
    processingCredits: any = 0;
    processedCredits: any = 0;
    processedStudents: any = [];
    accounting: any;
    removeEntryByAdminData: any;
    markProcessableForm: FormGroup;
    selectedErrorRemark: any;
    centerId: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private stageService: StageService,
                private studentService: StudentService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private srMappingService: SrMappingService,
                public courseService: CourseService,
                public counsellorService: CounsellorService,
                public alertService: AlertService,
                private router: Router,
                private adminService: AdminService,
                private headCounsellorService: HeadCounsellorService,
                private universityService: UniversityService,
                private programService: ProgramService,
                private branchService: BranchService,
                private pcbMappingService: PcbMappingService,
                private courseCurriculumService: CourseCurriculumService,
                private universitySettingService: UniversitySettingService,
                private businessCenterService: BusinessCenterService,
                private businessHeadCenterService: BusinessHeadCenterService,
                private businessAdminService: BusinessAdminService,
                private activatedRoute: ActivatedRoute) {
        this.getStages();
    }

    ngOnInit(): void {
        this.centerId = this.activatedRoute.snapshot.params['id'];
        this.markProcessableForm = this.fb.group({
            'processing_remark': ['', Validators.required]
        });
        this.user = StorageService.getItem('self');
        this.accounting = StorageService.getItem('accounting');
        /*if (!this.accounting && this.user && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER' || this.user['user_type'] === 'BUSINESS_CENTER')) {
            this.router.navigateByUrl('accounting');
        } else {
            this.refresh();
        }*/
        this.refresh();
    }

    refresh() {
        this.studentSessionForm = this.fb.group({
            'university_id': [''],
            'student_session_year': ['']
        });
        this.updateStatusForm = this.fb.group({
            'status': ['', Validators.required],
            'remarks': ['']
        });
        this.updateProIdForm = this.fb.group({
            'pro_id': ['', Validators.required]
        });
        this.updateEnrollmentForm = this.fb.group({
            'enrollment_id': ['', Validators.required]
        });
        this.updateRollNoForm = this.fb.group({
            'roll_no': ['', Validators.required]
        });
        this.addQuickLeadForm = this.fb.group({
            'session_id': ['', Validators.required],
            'stage_id': ['', Validators.required],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'program_id': ['', Validators.required],
            'course_id': ['', Validators.required],
            'branch_id': [''],
            'student_year': ['']
        });
        this.reRegisterForm = this.fb.group({
            'student_year': ['', Validators.required],
            'session_id': ['', Validators.required]
        });
        this.studentPhotographDownloadForm = this.fb.group({
            'from': ['', Validators.required],
            'to': ['', Validators.required]
        });
        this.getCourses();
        /*if (this.user && this.user['user_type'] !== 'BUSINESS_CENTER' && this.user['user_type'] !== 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenters();
        }*/
        this.bulkUniversityForm = this.fb.group({
            'university_id': ['', Validators.required]
        });
        if (this.user && this.user['user_type'] === 'BUSINESS_ADMIN') {
            this.searchForm = this.fb.group({
                'name': [''],
                'application_number': [''],
                'email': [''],
                'contact': [''],
                'pro_id': [''],
                'enrollment_id': [''],
                'roll_no': [''],
                'course_id': [''],
                'added_by_business_head_center': [''],
                'added_by': ['']
            });
        } else {
            this.searchForm = this.fb.group({
                'name': [''],
                'application_number': [''],
                'email': [''],
                'contact': [''],
                'pro_id': [''],
                'enrollment_id': [''],
                'roll_no': [''],
                'course_id': ['']
            });
        }
        if (this.user && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.getCounsellorDetail();
            this.getBusinessCenterDetail();
        } else if (this.user && this.user['user_type'] === 'ACCOUNTING') {
            this.businessHeadCenterService.detailById(this.centerId).subscribe(data => {
                this.businessHeadCenterDetail = data;
            });
            this.getStudentSessionData(10);
        }
        /*if (this.user && this.user['user_type'] === 'SUPER_ADMIN') {
            this.getAdmins();
            this.getUniversitiesForSearch();
            this.getAdminsForSearch();
            this.getUniversities();
        } else if (this.user && this.user['user_type'] === 'ADMIN') {
            this.getUniversitiesForSearch();
            this.getHeadCounsellors();
        } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.getHeadCounsellorDetails(this.user['userId']);
            this.getCounsellors();
        } else if (this.user && this.user['user_type'] === 'COUNSELLOR') {
            this.getCounsellorDetails(this.user['userId']);
        } else if (this.user && this.user['user_type'] === 'BUSINESS_ADMIN') {
            this.getBusinessAdminDetail();
        } else if (this.user && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenterDetail();
        } else if (this.user && this.user['user_type'] === 'BUSINESS_CENTER') {
            this.getBusinessCenterDetail();
        } else if (this.user && this.user['user_type'] === 'UNIVERSITY') {
            this.getStudentSessionData(this.user['userId']);
        }*/
    }

    get(search?) {
        this.tabs = [];
        this.stageService.get({'page': -1}).subscribe(data => {
            let tabs = this.tabs;
            if (data && data['data'].length > 0) {
                data['data'].forEach(data => {
                    if (data && (data.id === 5 || data.id === 10 || data.id === 11 || data.id === 12)) {
                        tabs.push({
                            'id': data.id,
                            'name': data.name,
                            'data': []
                        });
                    }
                });
                this.tabs = tabs;
                this.stageTabs = [];
                if (tabs && tabs.length > 0) {
                    let stageTabs = [];
                    tabs.forEach(tab => {
                        if (tab.id !== 10) {
                            stageTabs.push(tab);
                        }
                    });
                    this.stageTabs = stageTabs;
                }
            }
            this.getStudents(this.studentPagination, search);
        });
    }

    getUniversities() {
        this.universities = [];
        this.universityService.get({'page': -1}).subscribe(data => {
            if (data['data'] && data['data'].length > 0) {
                this.universities = data['data'];
                this.studentSessionForm.patchValue({
                    'university_id': this.universities[this.universities.length - 1].id
                });
                this.getStudentSessionData(this.universities[this.universities.length - 1].id);
            } else {
                this.universities = [];
            }
        });
    }

    getBusinessCenterDetail() {
        this.businessCenterService.detailById(this.centerId).subscribe(data => {
            this.businessCenterDetail = data;
            this.processableCredits = (this.businessCenterDetail && this.businessCenterDetail['amount']) ? this.businessCenterDetail['amount'] : 0;
            this.getStudentSession();
        });
    }

    getBusinessHeadCenterDetail() {
        this.businessHeadCenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessHeadCenterDetail = data;
            this.processableCredits = (this.businessHeadCenterDetail && this.businessHeadCenterDetail['amount']) ? this.businessHeadCenterDetail['amount'] : 0;
            this.getStudentSession();
        });
    }

    getBusinessAdminDetail() {
        this.businessAdminService.detailById(this.user['userId']).subscribe(data => {
            this.businessAdminDetail = data;
            this.getStudentSession();
        });
    }

    getAdmins(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.admins = [];
        this.adminService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.admins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.admins = [];
            }
        });
    }

    getHeadCounsellorDetails(id) {
        this.headCounsellorService.detail(id).subscribe(data => {
            this.headCounsellorDetailAdmin = data['head_counsellor_detail']['admin'];
            this.headCounsellorUniversities = data['head_counsellor_universities'];
        });
    }

    getCounsellorDetails(id) {
        this.counsellorService.detail(id).subscribe(data => {
            this.counsellorUniversities = data['counsellor_universities'];
            this.counsellorHeadCounsellor = data['counsellor_detail']['head_counsellor']['user'];
            this.counsellorAdmin = data['counsellor_detail']['head_counsellor']['admin'];
        });
    }

    getAdminsForSearch(params: any = {}) {
        this.searchAdmins = [];
        this.adminService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.searchAdmins = data['data'];
            } else {
                this.searchAdmins = [];
            }
        });
    }

    getUniversitiesForSearch(params: any = {}) {
        this.searchUniversities = [];
        this.universityService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.searchUniversities = data['data'];
            }
        });
    }

    getHeadCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.headCounsellors = [];
        this.headCounsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.headCounsellors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.headCounsellors = [];
            }
        });
    }

    getCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.counsellors = [];
        this.counsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.counsellors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.counsellors = [];
            }
        });
    }

    getCounsellorDetail() {
        if (this.user && this.user['userId']) {
            this.counsellorService.detail(this.user['userId']).subscribe(data => {
                if (data) {
                    this.counsellorDetail = data;
                    this.universities = (this.counsellorDetail && this.counsellorDetail['counsellor_universities'] && this.counsellorDetail['counsellor_universities'].length > 0) ? this.counsellorDetail['counsellor_universities'] : [];
                    if (this.universities && this.universities.length > 0) {
                        if (this.user && this.user['user_type'] === 'COUNSELLOR') {
                            this.getUniversityCourses(this.universities[0].university_id);
                        }
                    }
                }
            });
        }
    }

    getUniversityCourses(universityId) {
        if (universityId) {
            this.courseService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.courses = data['data'];
                } else {
                    this.courses = [];
                }
            });
        } else {
            this.alertService.showErrors('Please choose university');
        }
    }

    getStages() {
        this.stages = [];
        this.stageService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.stages = data['data'];
            }
        });
    }

    getStageWiseStudents(stageId, index, params: any = {}) {
        params = {
            'session_id': (this.studentSessionForm.value && this.studentSessionForm.value.student_session_year) ? this.studentSessionForm.value.student_session_year : '',
            'added_by': (this.centerId) ? this.centerId : '',
            'page': (this.studentPagination.page) ? this.studentPagination.page : 1,
            'total': (this.studentPagination.total) ? this.studentPagination.total : null,
            'per_page': (this.studentPagination.perpage) ? this.studentPagination.perpage : 50,
            'pages': (this.studentPagination.pages) ? this.studentPagination.pages : null,
            'processing_status': this.activeType,
            ...this.searchForm.getRawValue()
        };
        this.studentService.getByStageForProcessReport(stageId, params).subscribe(data => {
            this.tabs[index].data = [];
            let allData = [];
            let tabsData = this.tabs;
            if (data && data['data'] && data['data'].length > 0) {
                data['data'].forEach(data => {
                    allData.push({
                        'id': (data['student_id']) ? data['student_id'] : '',
                        'name': (data['student'] && data['student'].name) ? data['student'].name : '',
                        'photograph': (data['photograph']) ? data['photograph'] : '',
                        'enrollment_id': (data && data['enrollment_id']) ? data['enrollment_id'] : '-',
                        'pro_id': (data && data['pro_id']) ? data['pro_id'] : '-',
                        'roll_no': (data && data['roll_no']) ? data['roll_no'] : '-',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data['student'] && data['student'].email) ? data['student'].email : '',
                        'phone': (data['student'] && data['student'].contact) ? data['student'].contact : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].id) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].id) ? data['student']['course_details']['branch'].id : '',
                        'status': (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['status']) ? ((data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_UPLOADED' ? 'DOCUMENTS UPLOADED' : (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'IN_PROGRESS' ? 'IN PROGRESS' : data['student']['student_detail']['status'].toLowerCase()))) : '-',
                        'remarks': (data && data['remarks']) ? data['remarks'] : '',
                        'status_id': (data && data['admission_type'] && data['admission_type']['id']) ? data['admission_type']['id'] : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student'] && data['student']['student_detail']) && data['student']['student_detail']['student_year'] ? data['student']['student_detail']['student_year'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['added_by_business_head_center'] && data['added_by_business_head_center']) ? data['added_by_business_head_center'] : '',
                        'father_name': (data && data['father_name']) ? data['father_name'] : '',
                        'processing_remark': (data && data['processing_remark']) ? data['processing_remark'] : '',
                        'mother_name': (data && data['mother_name']) ? data['mother_name'] : '',
                        'gender': (data && data['gender']) ? data['gender'] : '',
                        'date_of_birth': (data && data['date_of_birth']) ? data['date_of_birth'] : '',
                        'processing_status': (data && data['processing_status']) ? data['processing_status'] : '',
                    });
                });
                if (tabsData && tabsData.length > 0) {
                    tabsData.forEach(tab => {
                        if (tab) {
                            if (allData && allData.length > 0) {
                                allData.forEach(all => {
                                    if (tab.id === all.status_id) {
                                        let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                                        let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                                        let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                                        let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                                        let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                                        let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                                        let chargeableCourseFee = 0;
                                        let chargeableCourseFeeActual = 0;
                                        let total = 0;
                                        let totalActual = 0;
                                        let reRegistrationPercentageActual = 0;
                                        let admissionPercentageActual = 0;
                                        let reRegistrationPercentageDisplay = 0;
                                        let admissionPercentageDisplay = 0;
                                        let prospectusFee = 0;
                                        let serviceCharge = 0;
                                        let examinationFee = 0;
                                        if (tab && tab.id === 10) {
                                            if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0) {
                                                all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                                    if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                                        admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            }
                                            chargeableCourseFee = re_registration_amount * ((reRegistrationPercentageDisplay) / 100);
                                            total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            chargeableCourseFeeActual = re_registration_amount * ((reRegistrationPercentageActual) / 100);
                                            totalActual = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFeeActual + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            if (all && all['business_center'] && all['business_center']['business_center_detail']) {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            }
                                        } else {
                                            if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0) {
                                                all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                                    if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by'].id))) {
                                                        admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            }
                                            chargeableCourseFee = course_fee * ((admissionPercentageDisplay) / 100);
                                            total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            chargeableCourseFeeActual = course_fee * ((admissionPercentageActual) / 100);
                                            totalActual = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFeeActual + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            if (all && all['business_center'] && all['business_center']['business_center_detail']) {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            }
                                        }
                                        tab['data'].push({
                                            'id': all.id,
                                            'name': all.name,
                                            'photograph': all.photograph,
                                            'pro_id': all.pro_id,
                                            'enrollment_id': all.enrollment_id,
                                            'roll_no': all.roll_no,
                                            'username': all.username,
                                            'email': all.email,
                                            'phone': all.phone,
                                            'program_id': all.program_id,
                                            'course_id': all.course_id,
                                            'branch_id': all.branch_id,
                                            'program': all.program,
                                            'course': all.course,
                                            'branch': all.branch,
                                            'status': all.status,
                                            'remarks': all.remarks,
                                            'status_id': all.status_id,
                                            'register_course_id': all.register_course_id,
                                            'student_year': all.student_year,
                                            'register_course': all.register_course,
                                            'business_center': all.business_center,
                                            'added_by': all.added_by,
                                            'pcb_mapping': all.pcb_mapping,
                                            'prospectus_fee': (!isNaN(prospectusFee) ? prospectusFee : (all && all['pcb_mapping'] && all['pcb_mapping']['prospectus_fee']) ? all['pcb_mapping']['prospectus_fee'] : '-'),
                                            'service_charge': (!isNaN(serviceCharge) ? serviceCharge : (all && all['pcb_mapping'] && all['pcb_mapping']['service_charge']) ? all['pcb_mapping']['service_charge'] : '-'),
                                            'examination_fee': (!isNaN(examinationFee) ? examinationFee : (all && all['pcb_mapping'] && all['pcb_mapping']['examination_fee']) ? all['pcb_mapping']['examination_fee'] : '-'),
                                            'total': total,
                                            'total_actual': totalActual,
                                            'counsellor': all.counsellor,
                                            'head_counsellor': all.head_counsellor,
                                            'father_name': all.father_name,
                                            'processing_remark': all.processing_remark,
                                            'mother_name': all.mother_name,
                                            'gender': all.gender,
                                            'date_of_birth': all.date_of_birth,
                                            'chargeableCourseFee': chargeableCourseFee,
                                            'processing_status': all.processing_status
                                        });
                                    }
                                });
                            }
                        }
                    });
                }
                this.studentPagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.studentPagination = {
                    page: null,
                    total: null,
                    perpage: null,
                    pages: null,
                };
            }
        }, error => {
            this.studentPagination = {
                page: null,
                total: null,
                perpage: null,
                pages: null,
            };
        });
    }

    getStudents(params: any = {}, search?) {
        if (search) {
            params = {
                'session_id': (this.studentSessionForm.value && this.studentSessionForm.value.student_session_year) ? this.studentSessionForm.value.student_session_year : '',
                'center_id': (this.centerId) ? this.centerId : '',
                'page': 1,
                'per_page': 50,
                'admission_type': this.tabs[this.activeTab].id,
                'processing_status': this.activeType,
                ...this.searchForm.getRawValue()
            };
        } else {
            params = {
                'session_id': (this.studentSessionForm.value && this.studentSessionForm.value.student_session_year) ? this.studentSessionForm.value.student_session_year : '',
                'center_id': (this.centerId) ? this.centerId : '',
                'page': (this.studentPagination.page) ? this.studentPagination.page : 1,
                'total': (this.studentPagination.total) ? this.studentPagination.total : null,
                'per_page': (this.studentPagination.perpage) ? this.studentPagination.perpage : 50,
                'pages': (this.studentPagination.pages) ? this.studentPagination.pages : null,
                'processing_status': this.activeType
            };
        }
        search = false;
        this.studentService.getForProcessReportHeadCenter(params).subscribe(data => {
            let allData = [];
            let tabsData = this.tabs;
            if (data && data['data'] && data['data'].length > 0) {
                data['data'].forEach(data => {
                    allData.push({
                        'id': (data && data['student_id']) ? data['student_id'] : '',
                        'name': (data && data['student_name']) ? data['student_name'] : '',
                        'photograph': (data['photograph']) ? data['photograph'] : '',
                        'pro_id': (data && data['pro_id']) ? data['pro_id'] : '-',
                        'enrollment_id': (data && data['enrollment_id']) ? data['enrollment_id'] : '-',
                        'roll_no': (data && data['roll_no']) ? data['roll_no'] : '-',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data && data['email']) ? data['email'] : '',
                        'phone': (data && data['contact']) ? data['contact'] : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].id : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status': (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['status']) ? ((data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_UPLOADED' ? 'DOCUMENTS UPLOADED' : (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'IN_PROGRESS' ? 'IN PROGRESS' : data['student']['student_detail']['status'].toLowerCase()))) : '-',
                        'remarks': (data && data['remarks']) ? data['remarks'] : '',
                        'status_id': (data && data['admission_type'] && data['admission_type']['id']) ? data['admission_type']['id'] : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student_year']) ? data['student_year'] : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'transactions': (data && data['transactions']) ? (data && data['transactions']) : [],
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['added_by_business_head_center'] && data['added_by_business_head_center']) ? data['added_by_business_head_center'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : '',
                        'father_name': (data && data['father_name']) ? data['father_name'] : '',
                        'processing_remark': (data && data['processing_remark']) ? data['processing_remark'] : '',
                        'mother_name': (data && data['mother_name']) ? data['mother_name'] : '',
                        'gender': (data && data['gender']) ? data['gender'] : '',
                        'date_of_birth': (data && data['date_of_birth']) ? data['date_of_birth'] : '',
                        'processing_status': (data && data['processing_status']) ? data['processing_status'] : '',
                    });
                });
                this.tabs[0].data = (this.tabs && this.tabs.length > 0 && this.tabs[0] && this.tabs[0].data) ? [] : '';
                if (tabsData && tabsData.length > 0) {
                    tabsData.forEach(tab => {
                        if (tab) {
                            if (allData && allData.length > 0) {
                                allData.forEach(all => {
                                    if (tab.id === all.status_id) {
                                        let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                                        let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                                        let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                                        let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                                        let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                                        let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                                        let chargeableCourseFee = 0;
                                        let total = 0;
                                        let chargeableCourseFeeActual = 0;
                                        let totalActual = 0;
                                        let reRegistrationPercentageActual = 0;
                                        let admissionPercentageActual = 0;
                                        let reRegistrationPercentageDisplay = 0;
                                        let admissionPercentageDisplay = 0;
                                        let prospectusFee = 0;
                                        let serviceCharge = 0;
                                        let examinationFee = 0;
                                        if (tab && tab.id === 10) {
                                            if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                                                all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                                    if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                                        admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                                                all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                                    if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(all['added_by']))) {
                                                        admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            }
                                            chargeableCourseFee = re_registration_amount * ((reRegistrationPercentageDisplay) / 100);
                                            total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            chargeableCourseFeeActual = re_registration_amount * ((reRegistrationPercentageActual) / 100);
                                            totalActual = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFeeActual + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'ACCOUNTING')) {
                                                all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                                    if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && ((parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId'])))) {
                                                        admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            }
                                        } else {
                                            if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                                                all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                                    if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by'].id))) {
                                                        admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                                                all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                                    if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && ((parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId'])))) {
                                                        admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'ACCOUNTING')) {
                                                all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                                    if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && ((parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId'])))) {
                                                        admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                                        reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                                        admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                                        reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                                        prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                                        serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                                        examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                                    }
                                                });
                                            }
                                            chargeableCourseFee = course_fee * ((admissionPercentageDisplay) / 100);
                                            total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            chargeableCourseFeeActual = course_fee * ((admissionPercentageActual) / 100);
                                            totalActual = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFeeActual + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                                            if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                                                all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                                                all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                                                all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                                                all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                                                all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                                                all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                                                all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                                            }
                                        }
                                        tab['data'].push({
                                            'id': all.id,
                                            'name': all.name,
                                            'photograph': all.photograph,
                                            'pro_id': all.pro_id,
                                            'enrollment_id': all.enrollment_id,
                                            'roll_no': all.roll_no,
                                            'username': all.username,
                                            'email': all.email,
                                            'phone': all.phone,
                                            'program_id': all.program_id,
                                            'course_id': all.course_id,
                                            'branch_id': all.branch_id,
                                            'program': all.program,
                                            'course': all.course,
                                            'branch': all.branch,
                                            'status': all.status,
                                            'remarks': all.remarks,
                                            'status_id': all.status_id,
                                            'transactions': all.transaction,
                                            'register_course_id': all.register_course_id,
                                            'register_course': all.register_course,
                                            'student_year': all.student_year,
                                            'added_by': all.added_by,
                                            'prospectus_fee': (!isNaN(prospectusFee) ? prospectusFee : (all && all['pcb_mapping'] && all['pcb_mapping']['prospectus_fee']) ? all['pcb_mapping']['prospectus_fee'] : '-'),
                                            'service_charge': (!isNaN(serviceCharge) ? serviceCharge : (all && all['pcb_mapping'] && all['pcb_mapping']['service_charge']) ? all['pcb_mapping']['service_charge'] : '-'),
                                            'examination_fee': (!isNaN(examinationFee) ? examinationFee : (all && all['pcb_mapping'] && all['pcb_mapping']['examination_fee']) ? all['pcb_mapping']['examination_fee'] : '-'),
                                            'pcb_mapping': all.pcb_mapping,
                                            'total': total,
                                            'total_actual': totalActual,
                                            'counsellor': all.counsellor,
                                            'head_counsellor': all.head_counsellor,
                                            'business_center': all.business_center,
                                            'father_name': all.father_name,
                                            'processing_remark': all.processing_remark,
                                            'mother_name': all.mother_name,
                                            'gender': all.gender,
                                            'date_of_birth': all.date_of_birth,
                                            'chargeableCourseFee': chargeableCourseFee,
                                            'processing_status': all.processing_status
                                        });
                                    }
                                });
                            }
                        }
                    });
                    this.active = search ? this.activeTab : 0;
                }
                this.studentPagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.tabs[0].data = [];
                this.tabs[1].data = [];
                this.tabs[2].data = [];
                this.tabs[3].data = [];
            }

            const name = (this.searchForm.value && this.searchForm.value.name ? this.searchForm.value.name : '');
            const username = (this.searchForm.value && this.searchForm.value.username ? this.searchForm.value.username : '');
            const email = (this.searchForm.value && this.searchForm.value.email ? this.searchForm.value.email : '');
            const contact = (this.searchForm.value && this.searchForm.value.contact ? this.searchForm.value.contact : '');
            const universityId = (this.searchForm.value && this.searchForm.value.universityId ? this.searchForm.value.universityId : '');
            const programId = (this.searchForm.value && this.searchForm.value.programId ? this.searchForm.value.programId : '');
            const courseId = (this.searchForm.value && this.searchForm.value.courseId ? this.searchForm.value.courseId : '');
            const branchId = (this.searchForm.value && this.searchForm.value.branchId ? this.searchForm.value.branchId : '');
            const adminId = (this.searchForm.value && this.searchForm.value.adminId ? this.searchForm.value.adminId : '');
            const headCounsellorId = (this.searchForm.value && this.searchForm.value.headCounsellorId ? this.searchForm.value.headCounsellorId : '');
            const counsellorId = (this.searchForm.value && this.searchForm.value.counsellorId ? this.searchForm.value.counsellorId : '');
            const businessCenterId = (this.searchForm.value && this.searchForm.value.businessCenterId ? this.searchForm.value.businessCenterId : '');

            if (!name && !username && !email && !contact && !universityId && !programId && !courseId && !branchId && !adminId && !headCounsellorId && !counsellorId && !businessCenterId) {
                this.studentPagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            }
        }, error => {
            this.active = search ? this.activeTab : 0;
        });
    }

    /*getProcessingCredits() {
        this.studentService.getProcessingCredits({}).subscribe(data => {

        });
    }*/

    getTabData(tab, index, params: any = {}) {
        this.searchForm.patchValue({
            'name': '',
            'application_number': '',
            'email': '',
            'contact': '',
            'pro_id': '',
            'enrollment_id': '',
            'roll_no': '',
            'course_id': '',
            'added_by_business_head_center': '',
            'added_by': ''
        });
        this.activeTab = index;
        if (tab && tab.id !== 'All') {
            //this.getStageWiseStudents(tab.id, index);
        } else {
            this.getStudents(this.studentPagination);
        }
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('detail-student').toggleOpen();
    }

    detail(data: any) {
        this.studentDetail = data;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.getStudents({page: 1, total: null, perpage: 50, pages: null});
        }
        this.toggleSidebar();
    }

    updateStatus() {
        this.submitted = true;
        if (!this.updateStatusForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'status': (this.updateStatusForm.value && this.updateStatusForm.value.status) ? this.updateStatusForm.value.status : '',
                'remarks': (this.updateStatusForm.value && this.updateStatusForm.value.remarks && this.updateStatusForm.value.status === 'IN_PROGRESS') ? this.updateStatusForm.value.remarks : '',
            };
            this.submitting = true;
            this.studentService.studentUpdateStatusByBusiness(this.selectedStudentData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.modalService.dismissAll('');
                this.getStudents({page: 1, total: null, perpage: 50, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    navigateToUrl(url) {
        window.open(url, '_blank');
    }

    onStudentPageChange(page) {
        this.studentPagination.page = page;
        this.getStudents();
    }

    onFileChange(e, type) {
        this.onFileChanged = e;
        this.studentService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'STUDENT_BULK_UPLOAD') {
                this.studentBulkUpload(data['data'].url);
            }
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    studentBulkUpload(url) {
        this.showFetchingDataLoader = true;
        this.studentService.studentBulkUpload({'url': url}).subscribe(data => {
            if (data) {
                this.bulkStudentData = data;
                this.showFetchingDataLoader = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
            this.showFetchingDataLoader = false;
        });
    }

    showSearch() {
        this.openSearch = !this.openSearch;
    }

    search() {
        this.get(true);
    }

    getStudentSession() {
        /*if (this.businessCenterDetail && this.businessCenterDetail['business_admin_detail'] && this.businessCenterDetail['business_admin_detail'].university_id) {
            this.getStudentSessionData(this.businessCenterDetail['business_admin_detail'].university_id);
        } else if (this.businessHeadCenterDetail && this.businessHeadCenterDetail['business_admin_detail'] && this.businessHeadCenterDetail['business_admin_detail'].university_id) {
            this.getStudentSessionData(this.businessHeadCenterDetail['business_admin_detail'].university_id);
        } else if (this.businessAdminDetail && this.businessAdminDetail['university_id']) {
            this.getStudentSessionData(this.businessAdminDetail['university_id']);
        }*/
        this.getStudentSessionData(this.businessCenterDetail['business_admin_detail'].university_id);
    }

    getStudentSessionData(universityId) {
        this.universitySettingService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.settings = data['data'];
                this.patchSettingForm(universityId);
            } else {
                this.settings = [];
            }
        });
    }

    patchSettingForm(universityId) {
        if (this.settings && this.settings.length > 0) {
            let selectedSetting: any;
            this.settings.forEach(setting => {
                if (setting && setting['session_default'] && setting['session_default'] === 'ACTIVE') {
                    selectedSetting = setting;
                }
            });
            this.studentSessionForm.patchValue({
                'student_session_year': selectedSetting.id
            });
            this.getUniversityCourses(universityId);
            this.getProgram(universityId);
            this.getStudentPrograms(universityId);
            this.reRegistrationStatus();
            this.get();
        }
    }

    /*patchFormSession(universityId, data) {
        this.get(data.id);
        this.selectedSession = data;
        this.studentSessionForm.patchValue({
            'student_session_year': (data && data.id) ? data.id : ''
        });
        this.addQuickLeadForm.patchValue({
            'session_id': (data && data.id) ? data.id : ''
        });
        this.getUniversityCourses(universityId);
        this.getProgram(universityId);
        this.getStudentPrograms(universityId);
        this.reRegistrationStatus();
    }*/

    reRegistrationStatus() {
        if (this.settings && this.settings.length > 0) {
            this.showReRegistration = !(this.settings && this.settings[0] && this.settings[0]['session_default'] && this.settings[0]['session_default'] === 'ACTIVE');
            if (this.showReRegistration) {
                let reRegistrationSession = [];
                this.settings.forEach(session => {
                    if (session && session['re_registration_status'] === 'ACTIVE') {
                        reRegistrationSession.push(session);
                    }
                });
                this.reRegistrationSession = reRegistrationSession;
            }
        }
    }

    changeStudentUniversity(event) {
        if (event && event.target && event.target.value) {
            this.getStudentSessionData(event.target.value);
        }
    }

    changeStudentSession(event) {
        if (event && event.target && event.target.value && this.settings && this.settings.length > 0) {
            /*let selectedSession = {};
            this.settings.forEach(session => {
                if (parseInt(event.target.value) === session.id) {
                    selectedSession = session;
                }
            });
            this.selectedSession = selectedSession;*/
            this.get();
        }
    }

    getCourses() {
        this.courses = [];
        this.courseService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.courses = data['data'];
            } else {
                this.courses = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getBusinessHeadCenters() {
        this.businessHeadCenterService.get(this.user['userId'], {'page': -1}).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.headCenters = data['data'];
            } else {
                this.headCenters = [];
            }
        }, error => {
            this.headCenters = [];
        });
    }

    getBusinessCenter(event) {
        let headCenterId = (event && event.target && event.target.value) ? event.target.value : '';
        if (headCenterId) {
            this.businessCenterService.get(this.user['userId'], headCenterId, {'page': -1}).subscribe(data => {
                if (data && data['data'] && data['data'].length > 0) {
                    this.businessCenters = data['data'];
                } else {
                    this.businessCenters = [];
                }
            }, error => {
                this.businessCenters = [];
            });
        } else {
            this.alertService.showErrors('Please choose head center before choosing Business Center');
        }
    }

    getProgram(universityId) {
        this.programs = [];
        this.programService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.programs = data['data'];
            } else {
                this.programs = [];
            }
        });
    }

    getStudentPrograms(universityId) {
        this.programs = [];
        this.programService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentPrograms = data['data'];
            } else {
                this.studentPrograms = [];
            }
        });
    }

    goToPrintForm(data) {
        let url = environment.appUrl + 'msedu-admission-form/' + data.id;
        window.open(url, '_blank');
    }

    chooseEntry(student) {
        /*if (!this.processingStudents.includes(student.id)) {
            this.processingStudents.push(student.id);
        } else {
            const index = this.processingStudents.indexOf(student.id);
            if (index > -1) {
                this.processingStudents.splice(index, 1);
            }
        }*/
        if (!this.processingStudents.includes(student.id)) {
            if (parseFloat(student.total_actual) <= this.processableCredits) {
                this.processingStudentsCalc = parseFloat(this.processingStudentsCalc) + parseFloat(student.total_actual);
                this.processableCredits = this.processableCredits - parseFloat(student.total_actual);
                this.processingStudents.push(student.id);
            } else {
                this.alertService.showErrors('You don\'t have enough credits to process this student');
            }
        } else {
            const index = this.processingStudents.indexOf(student.id);
            if (index > -1) {
                this.processingStudents.splice(index, 1);
                this.processableCredits = this.processableCredits + parseFloat(student.total_actual);
            }
        }
    }

    removeEntry(student) {
        if (!this.processableStudents.includes(student.id)) {
            this.processableStudents.push(student.id);
            this.processingCredits = parseFloat(this.processingCredits) + parseFloat(student.total_actual);
        } else {
            const index = this.processableStudents.indexOf(student.id);
            if (index > -1) {
                this.processableStudents.splice(index, 1);
                this.processingCredits = parseFloat(this.processingCredits) - parseFloat(student.total_actual);
            }
        }
    }

    removeEntryByAdmin(content, data) {
        this.selectedErrorRemark = undefined;
        this.removeEntryByAdminData = data;
        this.modalService.open(content, {'size': 'sm'});
    }

    processNow() {
        if (this.processingStudents && this.processingStudents.length > 0) {
            const params = {
                'student_ids': JSON.stringify(this.processingStudents),
                'processing_status': 'PROCESSING',
                'amount': this.processableCredits
            };
            this.submitting = true;
            this.studentService.processStudentByBusiness(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.processingStudents = [];
                this.activeType = 'PROCESSABLE';
                this.getStudents({page: 1, total: null, perpage: 50, pages: null});
                if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                    this.getBusinessHeadCenterDetail();
                } else if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_CENTER') {
                    this.getBusinessCenterDetail();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        } else {
            this.alertService.showErrors('Please choose students to process');
        }
    }

    processableNow() {
        if (this.processableStudents && this.processableStudents.length > 0) {
            const params = {
                'student_ids': JSON.stringify(this.processableStudents),
                'processing_status': 'PROCESSABLE',
                'amount': this.processingCredits
            };
            this.submitting = true;
            this.studentService.processableStudentByBusiness(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.processableStudents = [];
                this.processingCredits = 0;
                this.activeType = 'PROCESSABLE';
                this.getStudents({page: 1, total: null, perpage: 50, pages: null});
                if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                    this.getBusinessHeadCenterDetail();
                } else if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_CENTER') {
                    this.getBusinessCenterDetail();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        } else {
            this.alertService.showErrors('Please choose students to process');
        }
    }

    getProcessStudents(data) {
        this.activeType = data['processing_status'];
        this.processingStudents = [];
        this.processableStudents = [];
        this.getStudents(data);
        // this.getBusinessCenterDetail();
        /*if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenterDetail();
        } else if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_CENTER') {
            /
            this.getBusinessCenterDetail();
        }*/
    }

    processedEntry(student) {
        if (!this.processedStudents.includes(student.id)) {
            this.processedStudents.push(student.id);
        } else {
            const index = this.processedStudents.indexOf(student.id);
            if (index > -1) {
                this.processedStudents.splice(index, 1);
            }
        }
    }

    processedNow() {
        if (this.processedStudents && this.processedStudents.length > 0) {
            const params = {
                'student_ids': JSON.stringify(this.processedStudents),
                'processing_status': 'PROCESSED'
            };
            this.submitting = true;
            this.studentService.processedStudentByBusiness(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.processedStudents = [];
                this.activeType = 'PROCESSING';
                this.getStudents({page: 1, total: null, perpage: 50, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        } else {
            this.alertService.showErrors('Please choose students to process');
        }
    }

    updateRemark() {
        this.submitted = true;
        if (!this.markProcessableForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'processing_status': 'PROCESSABLE',
                'processing_remark': (this.markProcessableForm.value && this.markProcessableForm.value.processing_remark) ? this.markProcessableForm.value.processing_remark : '',
            };
            this.submitting = true;
            this.studentService.markProcessableRemark(this.removeEntryByAdminData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.modalService.dismissAll('');
                this.getStudents({page: 1, total: null, perpage: 50, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    showErrorRemark(content, data) {
        this.removeEntryByAdminData = undefined;
        this.selectedErrorRemark = data;
        this.modalService.open(content, {'size': 'sm'});
    }

    goToStudents() {
        this.router.navigateByUrl('students');
    }

    goToAccounting() {
        this.router.navigateByUrl('accounting');
    }

    downloadExcel(type) {
        this.studentService.download(this.studentSessionForm.value.student_session_year, {'excel_type': type}).subscribe(data => {
            if (data && data['url']) {
                window.open(data['url'], '_blank');
            }
        });
    }
}
