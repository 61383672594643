import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '../../../shared/services/storage.service';
import {CreditTransactionService} from '../../../shared/services/credit-transaction.service';
import {AppConstants} from '../../../shared/constants/app-constant';
import {UniversitySettingService} from '../../../shared/services/university-setting.service';

@Component({
    selector: 'app-credit-transactions',
    templateUrl: './credit-transactions.component.html',
    styleUrls: ['./credit-transactions.component.scss']
})
export class CreditTransactionsComponent implements OnInit {
    showSearch = false;
    creditTransactions = [];
    id: any;
    data: any;
    addReceiptForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    banks = [];
    addReceiptModal: any = false;
    pagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };
    user: any;
    // studentSessionForm: FormGroup;
    settings: any = [];

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private creditTransactionService: CreditTransactionService,
                private universitySettingService: UniversitySettingService) {
    }

    ngOnInit(): void {
        /*this.studentSessionForm = this.fb.group({
            'student_session_year': ['']
        });*/
        this.user = StorageService.getItem('self');
        if (this.user && this.user['user_type'] === 'ACCOUNTING') {
            this.getSettings();
        } else {
            this.router.navigateByUrl('accounting');
        }
    }

    getSettings() {
        let universityId = AppConstants.GYAN_VIHAR_UNINVERSITY_ID;
        this.universitySettingService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.settings = data['data'];
                this.patchSettingForm();
            } else {
                this.settings = [];
            }
        });
    }

    patchSettingForm() {
        if (this.settings && this.settings.length > 0) {
            let selectedSetting: any;
            this.settings.forEach(setting => {
                if (setting && setting['session_default'] && setting['session_default'] === 'ACTIVE') {
                    selectedSetting = setting;
                }
            });
            /*this.studentSessionForm.patchValue({
                'student_session_year': selectedSetting.id
            });*/
            this.refresh();
        }
    }

    refresh() {
        this.addReceiptForm = this.fb.group({
            'type': ['', Validators.required],
            'transaction_id': [''],
            'amount': ['', Validators.required],
            'bank': [''],
            'other_bank': [''],
            'date': ['', Validators.required],
            'receipt_id': [''],
            'remark': ['']
        });
        this.searchForm = this.fb.group({
            'transaction_id': [''],
            'date': [''],
            'center_name': [''],
            'center_code': [''],
            'amount': [''],
        });
        this.get();
    }

    add(content): void {
        this.addReceiptModal = true;
        this.modalService.open(content);
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.creditTransactions = [];
        this.creditTransactionService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.creditTransactions = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.creditTransactions = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'transaction_id': (this.searchForm.value && this.searchForm.value.transaction_id) ? this.searchForm.value.transaction_id : '',
            'date': (this.searchForm.value && this.searchForm.value.date) ? this.searchForm.value.date : '',
            'center_name': (this.searchForm.value && this.searchForm.value.center_name) ? this.searchForm.value.center_name : '',
            'center_code': (this.searchForm.value && this.searchForm.value.center_code) ? this.searchForm.value.center_code : '',
            'amount': (this.searchForm.value && this.searchForm.value.amount) ? this.searchForm.value.amount : ''
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'transaction_id': (this.searchForm.value && this.searchForm.value.transaction_id) ? this.searchForm.value.transaction_id : '',
            'date': (this.searchForm.value && this.searchForm.value.date) ? this.searchForm.value.date : '',
            'center_name': (this.searchForm.value && this.searchForm.value.center_name) ? this.searchForm.value.center_name : '',
            'center_code': (this.searchForm.value && this.searchForm.value.center_code) ? this.searchForm.value.center_code : '',
            'amount': (this.searchForm.value && this.searchForm.value.amount) ? this.searchForm.value.amount : ''
        };
        this.get(params);
    }
}
